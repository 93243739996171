<template>
    <div class="manager-availability">

        <!-- Cabecera -->
        <header class="p-3">
            <div class="row alig-items-center">
                <div class="col-6">
                    <h1 v-cloak>{{ translator.disponibilitat }}</h1>
                </div>
                <div class="col-6 text-right">
                    <span @click="isCalendarWeek = true" class="px-2 py-1 vista" :class="{ active: isCalendarWeek }"><i class="fal fa-list-ul"></i></span>
                    <span @click="isCalendarWeek = false" class="ml-2 px-2 py-1 vista" :class="{ active: !isCalendarWeek }"><i class="fal fa-calendar-alt"></i></span>
                </div>
            </div>
        </header>

        <main class="container">
            
            <!-- Preload -->
            <div v-if="isPreLoad">
                <div class="preload">
                    <div class="preload-wrapper"></div>
                    <div class="preload-dots">
                        <div class="hollow-dots-spinner">
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Butons per afegir reserva, bloqueig i filtres -->
            <div class="d-flex align-items-center">

                <button class="btn btn-outline-secondary btn-sm mr-2" @click="marcado(null,null,null,'booking')">
                    {{ translator.afegirreserva }}
                </button>

                <button class="btn btn-outline-secondary btn-sm mr-2" @click="marcado(null,null,null,'block')">
                    {{ translator.bloqueig }}
                </button>

                <!-- Filtros -->
                <div id="nav-drawer-filter">

                    <input id="nav-input-filter" type="checkbox" class="nav-unshown">
                    <label id="nav-open" for="nav-input-filter" class="m-0">
                        <span class="btn btn-outline-secondary btn-sm">
                            Filtros
                            <span v-if="countFilter > 0" class="badge badge-pill badge-warning badge-num-filter">
                                {{ countFilter }}
                            </span>
                        </span>
                    </label>
                    <label class="nav-unshown" id="nav-close" for="nav-input-filter"></label>

                    <div id="nav-content">
                        <div class="p-3 border-bottom text-left">
                            {{ translator.filtrar }}
                        </div>                         
                        <div v-if="!isPreLoad && filterBookings.capacity" class="p-3">
                            <!-- Fechas -->
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <label for="from" class="form-label">{{ translator.inici}}</label>
                                    <input type="date" id="from" v-model="datefrom" class="form-control form-control-sm date-input mb-1">                                    
                                </div>
                                <div class="form-group col-6">
                                    <label for="to" class="form-label">{{ translator.final }}</label>
                                    <input type="date" id="to" v-model="dateto" class="form-control form-control-sm date-input" :min="datefrom">
                                </div>
                            </div>
                            <!-- Capacidad -->
                            <div v-if="filterBookings.capacity.length > 0" class="d-flex flex-column mb-3">
                                <select id="capacity" v-model="capacity" class="form-control form-control-sm">
                                    <option :value="{id: 0, text: ''}">{{ translator.capacitat }}...</option>
                                    <option v-for="filter in filterBookings.capacity" :key="filter.id" :value="{id: filter.id, text: filter.text}">{{ filter.text }}</option>
                                </select>
                            </div>
                            <!-- Paises -->
                            <div v-if="filterBookings.countries.length > 0" class="d-flex flex-column mb-3">
                                <select id="countries" v-model="countries" class="form-control form-control-sm">
                                    <option :value="{id: '', text: ''}">{{ translator.pais }}...</option>
                                    <option v-for="filter in filterBookings.countries" :key="filter.id" :value="{id: filter.id, text: filter.text}">{{ filter.text }}</option>
                                </select>
                            </div>
                            <!-- Grupos -->
                            <div v-if="filterBookings.groups.length > 0" class="d-flex flex-column mb-3">
                                <select id="groups" v-model="groups" class="form-control form-control-sm">
                                    <option :value="{id: 0, text: ''}">{{ translator.grup }}...</option>
                                    <option v-for="filter in filterBookings.groups" :key="filter.id" :value="{id: filter.id, text: filter.text}">{{ filter.text }}</option>
                                </select>
                            </div>
                            <!-- Manager -->
                            <div v-if="filterBookings.management.length > 0" class="d-flex flex-column mb-3">
                                <select id="management" v-model="management" class="form-control form-control-sm">
                                    <option :value="{id: 0, text: ''}">{{ translator.gestio }}...</option>
                                    <option v-for="filter in filterBookings.management" :key="filter.id" :value="{id: filter.id, text: filter.text}">{{ filter.text }}</option>
                                </select>
                            </div>
                            <!-- Propietarios -->
                            <div v-if="filterBookings.owners.length > 0" class="d-flex flex-column mb-3">
                                <select id="owners" v-model="owners" class="form-control form-control-sm">
                                    <option :value="{id: 0, text: ''}">{{ translator.propietari }}...</option>
                                    <option v-for="filter in filterBookings.owners" :key="filter.id" :value="{id: filter.id, text: filter.text}">{{ filter.text }}</option>
                                </select>
                            </div>
                            <!-- Propiedades -->
                            <div v-if="filterBookings.properties.length > 0" class="d-flex flex-column mb-3">
                                <select id="properties" v-model="properties" class="form-control form-control-sm">
                                    <option :value="{id: 0, text: ''}" selected>{{ translator.propietat }}...</option>
                                    <option v-for="filter in filterBookings.properties" :key="filter.id" :value="{id: filter.id, text: filter.text}">{{ filter.text }}</option>
                                </select>
                            </div>
                            <!-- Regiones -->
                            <div v-if="filterBookings.regions.length > 0" class="d-flex flex-column mb-3">
                                <select id="regions" v-model="regions" class="form-control form-control-sm">
                                    <option :value="{id: 0, text: ''}">{{ translator.regio }}...</option>
                                    <option v-for="filter in filterBookings.regions" :key="filter.id" :value="{id: filter.id, text: filter.text}">{{ filter.text }}</option>
                                </select>
                            </div>
                            <!-- Tipología -->
                            <div v-if="filterBookings.typologies.length > 0" class="d-flex flex-column mb-3">
                                <select id="typologies" v-model="typologies" class="form-control form-control-sm">
                                    <option :value="{id: 0, text: ''}">{{ translator.tipologia }}...</option>
                                    <option v-for="filter in filterBookings.typologies" :key="filter.id" :value="{id: filter.id, text: filter.text}">{{ filter.text }}</option>
                                </select>
                            </div>

                            <!-- Botones buscar y borrar -->
                            <div class="d-flex justify-content-center">
                                <button v-if="
                                ((datefrom == moment(new Date()).format('yyyy-MM-DD') && isCalendarWeek) ||
                                (datefrom == moment(this.datefrom).startOf('month').format('yyyy-MM-DD') && !isCalendarWeek)) &&
                                ((dateto == moment(new Date()).add(6, 'd').format('yyyy-MM-DD') && isCalendarWeek) ||
                                (dateto == moment(this.datefrom).endOf('month').format('yyyy-MM-DD') && !isCalendarWeek)) &&
                                capacity.id == 0 &&
                                countries.id == '' &&
                                groups.id == 0 &&
                                management.id == 0 &&
                                owners.id == 0 &&
                                properties.id == 0 &&
                                regions.id == 0 &&
                                typologies.id == 0
                              "
                                        disabled class="btn btn-secondary">
                                    {{ translator.buscar }}
                                </button>
                                <button v-else @click="filteredSearch" class="btn btn-secondary">
                                    {{ translator.buscar }}
                                </button>

                                <button v-if="
                                ((datefrom == moment(new Date()).format('yyyy-MM-DD') && isCalendarWeek) ||
                                (datefrom == moment(this.datefrom).startOf('month').format('yyyy-MM-DD') && !isCalendarWeek)) &&
                                ((dateto == moment(new Date()).add(6, 'd').format('yyyy-MM-DD') && isCalendarWeek) ||
                                (dateto == moment(this.datefrom).endOf('month').format('yyyy-MM-DD') && !isCalendarWeek)) &&
                                capacity.id == 0 &&
                                countries.id == '' &&
                                groups.id == 0 &&
                                management.id == 0 &&
                                owners.id == 0 &&
                                properties.id == 0 &&
                                regions.id == 0 &&
                                typologies.id == 0
                              "
                                        disabled class="btn btn-danger ml-2">
                                    {{ translator.esborrar }}
                                </button>
                                <button v-else class="btn btn-danger ml-2" @click="deleteFilters">
                                    {{ translator.esborrar }}
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div v-show="propiedadesVisibles.length == 0 && !isPreLoad" class="mt-5">
                <h1 class="text-center">{{ translator.senseresultats }}</h1>
            </div>

            <div v-show="propiedadesVisibles.length > 0 && !isPreLoad">

                <!-- Calendario Semanal -->
                <transition name="fade" mode="out-in">
                    <div key="3" v-show="isCalendarWeek" class="calendario-checkin-manager">

                        <header class="pt-3 mb-3">
                            <div class="row align-items-center">
                                <div class="col pl-0">
                                    <div class="navegacion-semanas">
                                        <a class="p-1" href="#" @click="lastWeek">
                                            <i class="fal fa-angle-left fa-lg"></i>
                                        </a>
                                        <div class="semana mx-3">{{ mostrarFecha }}</div>
                                        <a class="p-1" href="#" @click="nextWeek">
                                            <i class="fal fa-angle-right fa-lg"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </header>

                        <div class="calendario-reservas table-responsive">

                            <table class="table table-bordered">
                                <thead>
                                <th class="week-days">{{ translator.propietat }}</th>
                                <th v-for="(dia, index) in ShowDays" :key="index" @click="reloadDay(dia)" class="week-days">{{ dia.dia }} {{ moment(dia.fecha).isoWeekday() | convertDayWeek(translator.weekdayShort) }}</th>
                                </thead>
                                <tbody class="days-container">
                                    <tr v-for="prop in propiedadesVisibles" :key="prop.rowId">
                                        <td class="name" @click="showPropertyMonthly(prop.nombrePropiedad)"><span class="limit-text">{{ prop.nombrePropiedad }}</span></td>
                                        <td v-for="dia in prop.diasPropiedad"
                                            :key="`${dia.fecha}-${prop.nombrePropiedad}`"
                                            @click="marcado(dia.fecha, prop.nombrePropiedad, prop.lodgingId)"
                                            :class="{
                                                  'entrada-reserva': dia.estado == 'checkin',
                                                  'dia-reservado': dia.estado == 'ocupado',
                                                  'salida-reserva': dia.estado == 'checkout',
                                                  'salida-entrada': dia.estado == 'inout',
                                                  'bloqueado': dia.estado == 'bloqueado',
                                                  'salida-bloqueada': dia.estado == 'salida-bloqueada',
                                                  'entrada-bloqueada': dia.estado == 'entrada-bloqueada',
                                                  'checkout-and-block': dia.estado == 'checkout-and-block',
                                                  'block-and-checkin': dia.estado == 'block-and-checkin',
                                                }
                                              ">

                                            <!-- Checkin -->
                                            <div v-if="dia.estado == 'checkin'" class="entrada-reserva-i">
                                                <div class="reserva-numero">
                                                    <router-link :to="{ name: 'ManagerBooking', params: { bookingId: dia.label }, query: { id: $route.query.id }}">
                                                        <i class="fas fa-info-circle fa-lg"></i>
                                                    </router-link>
                                                </div>
                                            </div>

                                            <!-- Checkout -->
                                            <div v-if="dia.estado == 'checkout'" class="salida-reserva-i"></div>

                                            <!-- Chekin/checkout -->
                                            <div v-if="dia.estado == 'inout'" class="bg">
                                                <div class="salida-entrada-1"></div>
                                                <div class="salida-entrada-2">
                                                    <div class="contenedor-numero">
                                                        <div class="reserva-numero">
                                                            <router-link :to="{ name: 'ManagerBooking', params: { bookingId: dia.label }, query: { id: $route.query.id }}">
                                                                <i class="fas fa-info-circle fa-lg"></i>
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Bloqueo entrada -->
                                            <div v-if="dia.estado == 'entrada-bloqueada'" class="entrada-bloqueada-i"></div>

                                            <!-- Bloqueo salida -->
                                            <div v-if="dia.estado == 'salida-bloqueada'" class="salida-bloqueada-i"></div>

                                            <!-- Checkout/bloqueo -->
                                            <div v-if="dia.estado == 'checkout-and-block'" class="bg">
                                                <div class="checkout-and-block-1"></div>
                                            </div>

                                            <!-- Bloqueo/checkin -->
                                            <div v-if="dia.estado == 'block-and-checkin'" class="bg">
                                                <div class="block-and-checkin-1"></div>
                                                <div class="salida-entrada-2">
                                                    <div class="contenedor-numero">
                                                        <div class="reserva-numero reserva-numero-block-and-checkin">
                                                            <router-link :to="{ name: 'ManagerBooking', params: { bookingId: dia.label }, query: { id: $route.query.id }}">
                                                                <i class="fas fa-info-circle fa-lg"></i>
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </transition>

                <!-- Calendario Mensual -->
                <transition name="fade" mode="out-in">
                    <div key="1" v-show="!isCalendarWeek" class="c-mensual">
                        <div class="container mt-5 calendario-checkin-manager">
                            <div class="col">
                                <!-- Listado propiedades -->
                                <section class="section-select-property">
                                    <select v-model="propertySelected" class="select-property">
                                        <option disabled value="">{{ translator.propietat }}</option>
                                        <option v-for="property in listProperties" :key="property.id" :value="{ id: property.id, text: property.text }">{{ property.text }}</option>
                                    </select>
                                </section>

                                <!-- Calendario -->
                                <section v-if="propiedadesVisibles.length == 1">

                                    <!-- Flexas -->
                                    <div class="d-flex justify-content-between py-2 px-2 selector-monthly">
                                        <div @click="prevMonth" class="arrow-pointer"><i class="fal fa-chevron-left"></i></div>
                                        <div class="name-month"> {{ nombreMes }} {{ moment(datefrom).format('yyyy') }}</div>
                                        <div @click="nextMonth" class="arrow-pointer"><i class="fal fa-chevron-right"></i></div>
                                    </div>

                                    <!-- Días semana -->
                                    <div class="flex days">
                                        <div v-for="(dia, index) in diasSemana" :key="index" class="numMes calendar-header">{{ dia }}</div>
                                    </div>

                                    <!-- Número dias -->
                                    <div class="flex">                            
                                        <div v-for="(d, index) in dias" :key="index" class="numMes" @click="marcado(d.fecha, d.nombre_propiedad, d.id_propiedad)">

                                            <!-- Ocupado -->
                                            <div v-if="d.estado == 'ocupado'" class="cont-dia dia-ocupado">
                                                <div class="num-dia">{{ d.dia }}</div>
                                            </div>

                                            <!-- libre -->
                                            <div v-if="d.estado == ''" class="cont-dia">
                                                <div class="num-dia">{{ d.dia }}</div>
                                            </div>

                                            <!-- checkin -->
                                            <div v-if="d.estado == 'checkin'" class="cont-dia primer-dia-ocupado">
                                                <div class="num-dia">{{ d.dia }}</div>
                                                <span class="enlace">
                                                    <router-link :to="{ name: 'ManagerBooking', params: { bookingId: d.label }, query: { id: $route.query.id }}">
                                                        <i class="fas fa-info-circle fa-lg"></i>
                                                    </router-link>
                                                </span>
                                            </div>

                                            <!-- checkout -->
                                            <div v-if="d.estado == 'checkout'" class="cont-dia ultimo-dia-ocupado">
                                                <div class="num-dia">{{ d.dia }}</div>
                                            </div>

                                            <!-- Bloquado -->
                                            <div v-if="d.estado == 'bloqueado'" class="cont-dia bloqueado">
                                                <div class="num-dia">{{ d.dia }}</div>
                                            </div>

                                            <!-- salida-bloqueada -->
                                            <div v-if="d.estado == 'salida-bloqueada'" class="cont-dia entrada-bloqueada">
                                                <div class="num-dia">{{ d.dia }}</div>
                                            </div>

                                            <!-- entrada-bloqueada -->
                                            <div v-if="d.estado == 'entrada-bloqueada'" class="cont-dia salida-bloqueada">
                                                <div class="num-dia">{{ d.dia }}</div>
                                            </div>

                                            <!-- checkout-and-block -->
                                            <div v-if="d.estado == 'checkout-and-block'" class="cont-dia ultimo-dia-ocupado-bloqueado">
                                                <div class="num-dia">{{ d.dia }}</div>
                                            </div>

                                            <!-- Inout -->
                                            <div v-if="d.estado == 'inout'" class="cont-dia dia-entrada-salida">
                                                <div class="num-dia">{{ d.dia }}</div>
                                                <span class="enlace">
                                                    <router-link :to="{ name: 'ManagerBooking', params: { bookingId: d.label }, query: { id: $route.query.id }}">
                                                        <i class="fas fa-info-circle fa-lg"></i>
                                                    </router-link>
                                                </span>
                                            </div>

                                            <!-- block-and-checkin -->
                                            <div v-if="d.estado == 'block-and-checkin'" class="cont-dia primer-dia-ocupado-bloqueado">
                                                <div class="num-dia">{{ d.dia }}</div>
                                                <span class="enlace">
                                                    <router-link :to="{ name: 'ManagerBooking', params: { bookingId: d.label }, query: { id: $route.query.id }}">
                                                        <i class="fas fa-info-circle fa-lg"></i>
                                                    </router-link>
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>

            <!-- Modal crear/bloquear reserva-->
            <div class="modal fade" id="modalCenter" tabindex="-1" role="dialog" aria-labelledby="modalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalLongTitle" v-if="modalSelectProperty != 'null|null'">{{ modalSelectProperty | modalNombre }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">

                            <!-- Radio buttons -->
                            <div v-if="typeBookingOrBlock == ''" class="d-flex justify-content-center">
                                <div class="form-check">
                                    <input type="radio" v-model="typeBookingOrBlock" class="form-check-input" id="booking" value="booking">
                                    <label class="form-check-label" for="booking">
                                        {{ translator.reserva }}
                                    </label>
                                </div>
                                <div class="form-check ml-4">
                                    <input type="radio" v-model="typeBookingOrBlock" class="form-check-input" id="block" value="block">
                                    <label class="form-check-label" for="block">
                                        {{ translator.bloqueig }}
                                    </label>
                                </div>
                            </div>

                            <!-- Reserva form -->
                            <div v-if="typeBookingOrBlock == 'booking'" class="row">
                                <div class="col-12 mb-3">
                                    <label for="selectProperty" class="form-label">{{ translator.propietat }}</label>
                                    <select v-model="modalSelectProperty" class="form-control" id="selectProperty">
                                        <option v-for="prop in calendars.rows" :key="prop.lodging_id" :value="prop.lodging_id + '|' + prop.label">{{ prop.label }}</option>
                                    </select>
                                </div>
                                <div class="col-7 mb-3">
                                    <label for="selectDate" class="form-label">{{ translator.checkin}}</label>
                                    <input type="date" id="selectDate" v-model="selectDay" class="form-control">
                                </div>
                                <div class="col-5 mb-3">
                                    <label for="numberNights" class="form-label">{{ translator.nnits }}</label>
                                    <input type="number" id="numberNights" v-model="newBooking.numberNights" class="form-control">
                                </div>
                                <div class="col-12 mb-3">
                                    <label for="name" class="form-label">{{ translator.nom }}</label>
                                    <input v-model="newBooking.name" type="text" class="form-control" id="name">
                                </div>
                                <div class="col-12 mb-3">
                                    <label for="surname" class="form-label">{{ translator.cognoms }}</label>
                                    <input v-model="newBooking.surname" type="text" class="form-control" id="surname">
                                </div>
                                <div class="col-12 mb-3">
                                    <label for="email" class="form-label">{{ translator.email }}</label>
                                    <input v-model="newBooking.email" type="email" class="form-control" id="email">
                                </div>
                                <div class="col-12 mb-3">
                                    <label for="phone" class="form-label">{{ translator.telefon }}</label>
                                    <input v-model="newBooking.phone" type="tel" class="form-control" id="phone">
                                </div>
                                <div class="col-4 mb-3">
                                    <label for="adults" class="form-label">{{ translator.adults }}</label>
                                    <input v-model="newBooking.adults" type="number" class="form-control" id="adults">
                                </div>
                                <div class="col-4 mb-3">
                                    <label for="children" class="form-label">{{ translator.nens }}</label>
                                    <input v-model="newBooking.children" type="number" class="form-control" id="children">
                                </div>
                                <div class="col-4 mb-3">
                                    <label for="babies" class="form-label">{{ translator.bebes }}</label>
                                    <input v-model="newBooking.babies" type="number" class="form-control" id="babies">
                                </div>
                                <div class="col-6 mb-3">
                                    <label for="amount" class="form-label">{{ translator.import }}</label>
                                    <div class="input-group">
                                        <input v-model="newBooking.amount" type="number" class="form-control" id="amount">
                                        <span class="input-group-text" style="border-right:1px solid #ced4da">€</span>
                                    </div>
                                </div>
                                <div class="col-12 mb-3">
                                    <label for="remarks" class="form-label">{{ translator.observacions }}</label>
                                    <div class="input-group">
                                        <textarea v-model="newBooking.remarks" class="form-control" id="remarks"></textarea>
                                    </div>
                                </div>
                            </div>

                            <!-- Bloqueo -->
                            <div v-if="typeBookingOrBlock == 'block'" class="row">
                                <div class="col-12 mb-3">
                                    <label for="selectProperty" class="form-label">{{ translator.propietat }}</label>
                                    <select v-model="modalSelectProperty" class="form-control" id="selectProperty">
                                        <option v-for="prop in calendars.rows" :key="prop.lodging_id" :value="prop.lodging_id + '|' + prop.label">{{ prop.label }}</option>
                                    </select>
                                </div>
                                <div class="col-sm-12 col-xs-12 mb-3">
                                    <div class="form-check form-check-inline">
                                        <input v-model="newBlock.block" class="form-check-input" type="radio" id="addblock" value="true">
                                        <label class="form-check-label" for="addblock">{{ translator.bloquejar }}</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-model="newBlock.block" class="form-check-input" type="radio" id="unblock" value="false">
                                        <label class="form-check-label" for="unblock">{{ translator.desbloquejar }}</label>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xs-12 mb-3">
                                    <label for="initialBlock" class="form-label">inicio</label>
                                    <input type="date" id="initialBlock" v-model="selectDay" class="form-control">
                                </div>
                                <div class="col-sm-6 col-xs-12 mb-3">
                                    <label for="numberNights" class="form-label">{{ translator.nnits }}</label>
                                    <input type="number" id="numberNights" v-model="newBlock.numNights" class="form-control">
                                </div>
                            </div>

                            <div v-if="error.length > 0" class="alert alert-danger" role="alert">
                                <p v-for="(err, index) in error" :key="index">{{ err }}</p>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button @click="saveBookingOrBlock" type="button" class="btn btn-primary">{{ translator.guardar }}</button>
                        </div>
                    </div>
                </div>
            </div>

        </main>  
        <NavbarManager :translator="translator" />
    </div>
</template>

<script>
    import NavbarManager from "@/components/NavbarManager.vue";
    import { mapState } from "vuex";
    const $ = require("jquery");


    export default {
        name: "ManagerAvailability",

        components: {
            NavbarManager,
        },

        computed: {
            ...mapState("utils", ["user", "translator"]),
            ...mapState("manager", ["filterBookings", "calendars"]),
        },

        data() {
            return {
                isPreLoad: true,
                isCalendarWeek: true,

                //C.Semanal
                ShowDays: [],
                mostrarFecha: null,
                propiedadesVisibles: [],
                datefromMin: this.moment(new Date()).format('yyyy-MM-DD'),

                //C.Mensual
                propertySelected: '',
                listProperties: [],
                fechaMes: this.moment(new Date()),
                diasSemana: ['lu', 'ma', 'mi', 'ju', 'vi', 'sa', 'do'],
                numeroMes: this.moment(this.fechaMes).month(),
                nombreMes: '',
                dias: [],

                //filtros
                isFilteredSearch: false,
                isRemoveFilter: false,
                countFilter: 0,
                datefrom: this.moment(new Date()).format('yyyy-MM-DD'),
                dateto: this.moment(new Date()).add(6, 'd').format('yyyy-MM-DD'),
                capacity: { id: 0, text: '' },
                countries: { id: '', text: '' },
                groups: { id: 0, text: '' },
                management: { id: 0, text: '' },
                owners: { id: 0, text: '' },
                properties: { id: 0, text: '' },
                regions: { id: 0, text: '' },
                typologies: { id: 0, text: '' },

                //modal
                error: [],
                selectDay: '',
                modalSelectProperty: '',
                typeBookingOrBlock: '',
                propertyId: '',
                newBooking: {
                    numberNights: '',
                    name: '',
                    surname: '',
                    email: '',
                    phone: '',
                    amount: '',
                    adults: '',
                    children: '',
                    babies: '',
                    remarks: '',
                },
                newBlock: {
                    block: '',
                    numNights: '',
                }
            };
        },

        watch: {
            async translator() {
                await this.$store.dispatch("manager/getFilterBookings", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                this.getCalendars();
                //paramId = this.$route.query.id;
            },
            isCalendarWeek(newQ) {
                newQ ? this.datefrom = this.moment(new Date()).format('yyyy-MM-DD') : this.datefrom = this.moment(this.fechaMes).startOf('month').format("yyyy-MM-DD");
                this.getCalendars();
                !newQ
                    ? this.propiedadesVisibles.length == 1 ? this.colocaDias() : null
                    : null;
            },
            calendars(newQ, oldQ) {
                newQ != oldQ ? this.getDays() : null;
                sessionStorage.setItem('listado', 'ManagerAvailability');
                //Si es c.mensual
                this.isPreLoad = false;
            },
            filterBookings() {
                this.listProperties = [...this.filterBookings.properties]
            },
            propertySelected(newQ, oldQ) {
                if (newQ.id != oldQ.id) {
                    this.properties = newQ;
                    this.getCalendars();
                    !this.isCalendarWeek ? this.colocaDias() : null;
                }
            },
            modalSelectProperty(newQ) {
                newQ != null && newQ.label == ' ' ? this.modalSelectProperty = newQ.label.replace(' ', '') : null;
            },
            selectDay() {
                console.log(this.selectDay)
            },
            datefrom() {
                this.dateto = this.moment(this.datefrom).add(6, 'd').format('yyyy-MM-DD')
            }
        },

        async created() {
            try {
                if (this.translator) {
                    await this.$store.dispatch("manager/getFilterBookings", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                    this.getCalendars();
                    //paramId = this.$route.query.id;
                } else {
                    await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, "+"), });
                }
            } catch (error) {
                console.error(error);
            }
        },

        methods: {
            async getCalendars() {
                this.ShowDays = [];
                this.propiedadesVisibles = [];
                this.datefrom = this.isCalendarWeek ? this.moment(this.datefrom).format("yyyy-MM-DD") : this.moment(this.datefrom).startOf('month').format("yyyy-MM-DD");
                this.dateto = this.isCalendarWeek ? this.moment(this.datefrom).add(6, 'd').format('yyyy-MM-DD') : this.moment(this.datefrom).endOf('month').format("yyyy-MM-DD");

                await this.$store.dispatch("manager/getCalendars",
                    {
                        usr: String(this.$route.query.id).replace(/[ ]/g, "+"),
                        datefrom: this.moment(this.datefrom).add(-1, 'd').format("yyyy-MM-DD"), //petición del día antes para saber si es una salida o final de un bloqueo
                        dateto: this.dateto,
                        country: this.countries.id,
                        region: this.regions.id,
                        group: this.groups.id,
                        owner: this.owners.id,
                        properties: this.properties.id,
                        management: this.management.id,
                        typology: this.typologies.id,
                        capacity: this.capacity.id,
                    }
                );
                this.isPreLoad = true;
            },

            //C.Semanal
            getDays() {
                for (let x = 0; x < 7; x++) {
                    let fechaInicial = this.moment(this.datefrom).clone();
                    let sumDay = fechaInicial.add(x, "d");
                    this.ShowDays.push({ fecha: this.moment(sumDay).format("yyyy-MM-DD"), dia: this.moment(sumDay).format("D") })
                }
                this.showDates();
                this.orderWSData();
            },
            showDates() {
                this.mostrarFecha = `${this.moment(new Date(this.ShowDays[0].fecha)).format("DD-MMM")} - ${this.moment(new Date(this.ShowDays[6].fecha)).format("DD-MMM")}`
            },
            reloadDay(day) {
                if (this.moment(new Date(day.fecha)).format('yyyy-MM-DD') != this.moment(this.datefrom).format('yyyy-MM-DD')) {
                    this.datefrom = this.moment(new Date(day.fecha)).format('yyyy-MM-DD');
                    this.dateto = this.moment(new Date(day.fecha)).add(6, 'd').format('yyyy-MM-DD');
                    this.getCalendars();
                }
            },
            lastWeek() {
                this.datefrom = this.moment(this.datefrom).add(-7, 'd').format('yyyy-MM-DD');
                this.dateto = this.moment(this.datefrom).add(6, 'd').format('yyyy-MM-DD');
                this.getCalendars();
            },
            nextWeek() {
                this.datefrom = this.moment(this.datefrom).add(7, 'd').format('yyyy-MM-DD');
                this.dateto = this.moment(this.datefrom).add(6, 'd').format('yyyy-MM-DD');
                this.getCalendars();
            },
            orderWSData() {
                this.propiedadesVisibles = [];
                let isCheckout = false;
                Object.entries(this.calendars.rows).forEach(row => {
                    let isFirstIteration = true;
                    isCheckout = false;
                    let isBlock = false;
                    let nombrePropiedad = row[1].label
                    let rowId = row[1].rowId
                    let lodgingId = row[1].lodging_id
                    let diasPropiedad = []
                    Object.entries(this.calendars.items).forEach(item => {
                        if (rowId === item[1].rowId) {
                            let estado = "";
                            let fecha = item[1].date;
                            let id = item[1].id;
                            let time = item[1].time;
                            let label = parseInt(item[1].label);
                            let rowId = item[1].rowId;

                            if (new Date(fecha) >= new Date(time.start) && new Date(fecha) <= new Date(time.end)) {
                                estado = "ocupado";
                            }

                            if (fecha === time.start) {
                                if (isCheckout) {
                                    estado = "inout";
                                    isCheckout = false;
                                } else {
                                    estado = 'checkin';
                                    isCheckout = false;
                                }
                            } else {
                                if (isCheckout) {
                                    estado = 'checkout';
                                    isCheckout = false;
                                }
                            }

                            if (this.moment(fecha).format('yyyy-MM-DD') === this.moment(time.end).add(-1, 'd').format('yyyy-MM-DD') && !isCheckout) {
                                isCheckout = true;
                            }

                            if (label >= 1 && label <= 9) {
                                if (estado == "checkout") {
                                    estado = 'checkout-and-block';
                                } else {
                                    isBlock ? estado = 'bloqueado' : estado = 'entrada-bloqueada';
                                }

                                isBlock = true;
                            }

                            if (label == 0 || label >= 10) {
                                if (isBlock && estado == 'checkin') {
                                    estado = 'block-and-checkin';
                                } else {
                                    isBlock ? estado = 'salida-bloqueada' : null;
                                }
                                isBlock = false;
                            }

                            // Si es el primer día lo saltamos, solo se usa para info de posibles salidas o bloqueos
                            isFirstIteration ? isFirstIteration = false : diasPropiedad.push({ fecha, id, time, label, rowId, estado });
                        }
                    })
                    this.propiedadesVisibles.push({ nombrePropiedad, rowId, lodgingId, diasPropiedad });
                })

                this.propiedadesVisibles.sort((a, b) => a.nombrePropiedad.trim().localeCompare(b.nombrePropiedad.trim(), undefined, { sensitivity: 'base' }));
            },

            marcado(dia = null, propiedad = null, lodgingId = null, typeBookingOrBlock = '') {
                this.selectDay = dia;
                this.modalSelectProperty = lodgingId + "|" + propiedad;
                this.propertyId = lodgingId;
                this.typeBookingOrBlock = typeBookingOrBlock;
                $('#modalCenter').modal('show');
            },
            async saveBookingOrBlock() {
                //modalSelectProperty = id + '|' + name 
                const arrayStr = this.modalSelectProperty.split('|')
                this.propertyId == '' ? this.propertyId = arrayStr[0] : null
                // Reserva
                if (this.typeBookingOrBlock == 'booking') {

                    this.error = [];
                    this.modalSelectProperty == 'null|null' ? this.error.push(this.translator.faltapropietat) : null;
                    this.newBooking.name == '' ? this.error.push(this.translator.faltanom) : null;
                    this.selectDay == '' ? this.error.push(this.translator.faltadata) : null;
                    this.newBooking.numberNights == '' ? this.error.push(this.translator.faltanumnits) : null;
                    this.newBooking.amount == '' || this.newBooking.amount <= 0 ? this.error.push(this.translator.faltaimport) : null;

                    if (this.error.length == 0) {

                        await this.$store.dispatch("manager/postReservaAfegir", {
                            usr: String(this.$route.query.id).replace(/[ ]/g, '+'), //String
                            nom: this.newBooking.name, //String
                            cognom: this.newBooking.surname, //String
                            telefon: this.newBooking.phone, //String
                            email: this.newBooking.email, //String
                            adults: this.newBooking.adults === '' ? 0 : this.newBooking.adults, //Int
                            joves: this.newBooking.children === '' ? 0 : this.newBooking.children, //Int
                            bebes: this.newBooking.babies === '' ? 0 : this.newBooking.babies, //Int
                            observacions: this.newBooking.remarks, //String
                            lodgingId: arrayStr[0], //Int
                            entrada: this.selectDay, //Date
                            nits: parseInt(this.newBooking.numberNights), //Int
                            preu: this.newBooking.amount == '' ? 0 : this.newBooking.amount //Decimal
                        });

                        this.typeBookingOrBlock = this.modalSelectProperty = this.selectDay = this.newBooking.name = this.newBooking.surname = this.newBooking.email = this.newBooking.phone = this.newBooking.adults = this.newBooking.children = this.newBooking.babies = this.newBooking.remarks = this.newBooking.amount = this.newBooking.numberNights = '';
                        $('#modalCenter').modal('hide');
                        setTimeout(() => {
                            this.getCalendars();
                            this.colocaDias();
                        }, 800)
                    }
                    // Bloqueo
                } else if (this.typeBookingOrBlock == 'block') {
                    this.error = [];
                    this.modalSelectProperty == 'null|null' ? this.error.push(this.translator.faltapropietat) : null;
                    this.newBlock.block == '' ? this.error.push(`${this.translator.bloquejar}/${this.translator.desbloquejar}`) : null;
                    this.selectDay == '' ? this.error.push(this.translator.faltadata) : null;
                    this.newBlock.numNights == '' ? this.error.push(this.translator.faltanumnits) : null;

                    if (this.error.length == 0) {
                        console.log(this.propertyId)
                        await this.$store.dispatch("manager/postBloquejarCalendari", {
                            usr: String(this.$route.query.id).replace(/[ ]/g, '+'),
                            lodgingId: arrayStr[0],
                            data: this.selectDay,
                            nits: this.newBlock.numNights,
                            bloquejar: this.newBlock.block == 'true' ? true : false,

                            //Para el dispatch
                            // datefrom: this.moment(this.datefrom).add(-1, 'd'), //petición del día antes para saber si es una salida o final de un bloqueo
                            // dateto: this.dateto, 
                            // country: this.countries.id,
                            // region: this.regions.id,
                            // group: this.groups.id,
                            // owner: this.owners.id,
                            // properties: this.properties.id,
                            // management: this.management.id,
                            // typology: this.typologies.id,
                            // capacity: this.capacity.id,

                        });
                        this.typeBookingOrBlock = this.newBlock.numNights = this.modalSelectProperty = this.selectDay = this.newBlock.block = '';
                        $('#modalCenter').modal('hide');
                        setTimeout(() => {
                            this.getCalendars();
                            this.colocaDias();
                        }, 200)
                    }
                }
            },
            showPropertyMonthly(property) {
                this.isCalendarWeek = false;
                this.listProperties.forEach(prop => {
                    if (prop.text == property) {
                        this.propertySelected = prop;
                        this.properties = prop;
                    }
                });
            },

            //c.mensual
            async loadProperty() {
                // this.properties = this.propertySelected.id;
                this.getCalendars();
            },
            colocaDias() {
                this.orderWSData();
                setTimeout(() => {
                    const inicioMes = this.moment(this.fechaMes).startOf('month');
                    const primerDiaSemana = this.moment(inicioMes).isoWeekday();
                    const numDiasDelMes = this.moment(this.fechaMes).daysInMonth();
                    this.numeroMes = this.moment(this.fechaMes).month() + 1
                    this.nombreMes = this.translator.monthNames[this.moment(this.fechaMes).month()]
                    this.dias = [];
                    let d = [];
                    for (let x = 1; x < numDiasDelMes; x++) {
                        d.push(x);
                    }

                    let isFirtDays = true;
                    for (let i = 1; i <= 7; i++) {
                        if (i === primerDiaSemana) {
                            isFirtDays = false;
                            this.propiedadesVisibles[0].diasPropiedad.forEach(d => {

                                let dia = {
                                    fecha: d.fecha,
                                    dia: this.moment(d.fecha).format('D'),
                                    estado: d.estado,
                                    nombre_propiedad: this.propiedadesVisibles[0].nombrePropiedad,
                                    id_propiedad: this.propiedadesVisibles[0].lodgingId,
                                    label: d.label
                                }
                                this.dias.push(dia)
                                //console.log(dia.dia, dia.estado)
                            })

                        } else {
                            let dia = {
                                fecha: '',
                                dia: '',
                                estado: '',
                                nombre_propiedad: '',
                                label: ''
                            }
                            // Pinta en cuadros antes del primer día
                            if (isFirtDays) {
                                this.dias.push(dia)
                            }
                        }
                    }
                    // Pinta cuadros después del último día
                    const dia = { fecha: '', dia: '', estado: '', nombre_propiedad: '', label: '' }
                    const finMes = this.moment(this.fechaMes).endOf('month');
                    const ultimoDiaSemana = this.moment(finMes).isoWeekday();
                    const calculaCuadrosFinales = 7 - ultimoDiaSemana;
                    for (let a = 1; a <= calculaCuadrosFinales; a++) {
                        this.dias.push(dia);
                    }
                }, 300)
            },

            nextMonth() {
                this.fechaMes = this.fechaMes.add(1, 'month');
                this.datefrom = this.moment(this.fechaMes).startOf('month').format('yyyy-MM-DD');
                this.dateto = this.moment(this.fechaMes).endOf('month').format('yyyy-MM-DD');
                this.getCalendars();
                this.colocaDias();
            },
            prevMonth() {
                this.fechaMes = this.fechaMes.add(-1, 'month');
                this.datefrom = this.moment(this.fechaMes).startOf('month').format('yyyy-MM-DD');
                this.dateto = this.moment(this.fechaMes).endOf('month').format('yyyy-MM-DD');
                this.getCalendars();
                this.colocaDias();
            },

            //Filtros
            filteredSearch() {
                if (this.datefrom != this.moment(new Date()).format('yyyy-MM-DD') ||
                    this.dateto != this.moment(new Date()).add(6, 'd').format('yyyy-MM-DD') ||
                    this.capacity.id != 0 ||
                    this.countries.id != '' ||
                    this.groups.id != 0 ||
                    this.management.id != 0 ||
                    this.owners.id != 0 ||
                    this.properties != 0 ||
                    this.regions.id != 0 ||
                    this.typologies.id != 0) {
                    this.isFilteredSearch = true;
                    document.getElementById("nav-input-filter").checked = false;

                    //Reseteo Contador
                    this.countFilter = 0;
                    //Contamos filtros utilizados
                    this.capacity.id > 0 ? this.countFilter++ : null;
                    this.countries.id != '' ? this.countFilter++ : null;
                    this.groups.id > 0 ? this.countFilter++ : null;
                    this.management.id > 0 ? this.countFilter++ : null;
                    this.owners.id > 0 ? this.countFilter++ : null;
                    this.properties.id > 0 ? this.countFilter++ : null;
                    this.regions.id > 0 ? this.countFilter++ : null;
                    this.typologies.id > 0 ? this.countFilter++ : null;

                    this.getCalendars();
                }
            },
            deleteFilters() {
                this.isFilteredSearch = false;
                if (this.isCalendarWeek) {
                    this.datefrom = this.moment(new Date()).format('yyy-MM-DD')
                    this.dateto = this.datefromMin = this.moment(new Date()).add(6, 'd').format('yyyy-MM-DD')
                } else {
                    this.datefrom = this.moment(this.datefrom).startOf('month').format("yyyy-MM-DD")
                    this.dateto = this.datefromMin = this.moment(this.datefrom).endOf('month').format("yyyy-MM-DD")
                }
                this.capacity = this.groups = this.management = this.owners = this.properties = this.regions = this.typologies = { id: 0, text: '' };
                this.countries = { id: '', text: '' };
                this.isRemoveFilter = true;
                this.countFilter = 0;
                this.getCalendars();
            },
            convertDate(date) {
                return date.toISOString().slice(0, 10);
            },
            addDays(date, days) {
                let result = new Date(date);
                result.setDate(result.getDate() + days);
                return this.convertDate(result);
            }
        },
        filters: {
            convertDayWeek(date, days) {
                if (!date) return "";
                date = date == 7 ? days[0] : days[parseInt(date)];
                return date;
            },
            modalNombre(str) {
                if (!str) return "";
                const arrayStr = str.split('|');
                return arrayStr[1]
            }
        },
    };
</script>

<style lang="scss">

    .container-nav-top {
        height: 50px;
        margin: 1rem auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a, #nav-drawer-filter {
            color: var(--azul-oscuro);
            text-decoration: none;
            cursor: pointer;

            &:hover {
                color: lighten($color: #0c2c40, $amount: 15);
            }
        }
    }

    .vista {
        color: #fff;
    }

    .vista.active {
        border-radius: 2px;
        background: #fff;
        color: var(--azul-oscuro);
    }
   
    /* C. Semanal */
    .calendario-checkin-manager header {
        background: none;
    }

    .calendario-checkin-manager header h1 {
        color: var(--azul-oscuro);
        font-weight: 700;
        font-size: 1.5rem;
    }

    .calendario-checkin-manager header .semana, .calendario-checkin-manager header a {
        color: var(--azul-oscuro);
    }

    .calendario-checkin-manager thead {
        background-color: #dee2e6;
    }

    .calendario-checkin-manager .name {
        min-width: 110px;
    }

    .calendario-checkin-manager .name span {
        line-height: 20px;
    }

    .calendario-checkin-manager .calendario-reservas .salida-entrada-1 {
        background-color: #f6f7f9;
    }

    .calendario-checkin-manager .reserva-numero a i {
        color: #0066b3;
    }

    /* Entrada */
    .calendario-checkin-manager .calendario-reservas .entrada-reserva-i:before {
        top: 0;
        border-color: #f8f9fa transparent transparent #ffd218;
        border-width: 31px 16px 0 0;
    }

    /* Entrada bloqueada */
    .calendario-checkin-manager .calendario-reservas .entrada-bloqueada {
        position: relative;
        border-left: 1px solid #333;
        border-right: 1px solid #333;
    }

    .calendario-checkin-manager .calendario-reservas .entrada-bloqueada-i {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #333;
    }

    .calendario-checkin-manager .calendario-reservas .entrada-bloqueada-i:before, .calendario-checkin-manager .entrada-bloqueada-i:after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
    }

    .calendario-checkin-manager .calendario-reservas .entrada-bloqueada-i:before {
        top: 0;
        border-color: #333 transparent transparent #333;
        border-width: 31px 16px 0 0;
    }

    .calendario-checkin-manager .calendario-reservas .entrada-bloqueada-i:after {
        top: 0;
        border-color: #f8f9fa transparent transparent #f8f9fa;
        border-width: 30px 15px 0 0;
    }

    /* Salida bloqueada */
    .calendario-checkin-manager .calendario-reservas .salida-bloqueada {
        position: relative;
        border-left: 1px solid #333;
    }

    .calendario-checkin-manager .calendario-reservas .salida-bloqueada-i {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .calendario-checkin-manager .calendario-reservas .salida-bloqueada-i:before, .calendario-checkin-manager .salida-bloqueada-i:after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
    }

    .calendario-checkin-manager .calendario-reservas .salida-bloqueada-i:before {
        top: 0;
        border-color: #333 transparent transparent #333;
        border-width: 31px 16px 0 0;
    }

    .calendario-checkin-manager .calendario-reservas .salida-bloqueada-i:after {
        top: 0;
        border-color: #333 transparent transparent #333;
        border-width: 30px 15px 0 0;
    }

    /*  Día bloqueado */
    .calendario-checkin-manager .calendario-reservas .bloqueado {
        background-color: #333;
        border-left: 1px solid #333;
        border-right: 1px solid #333;
    }

    /* Salida/bloqueo */
    .calendario-checkin-manager .calendario-reservas .checkout-and-block {
        position: relative;
        border-left: 1px solid #333;
        border-right: 1px solid #333;
    }

    .calendario-checkin-manager .calendario-reservas .checkout-and-block .bg {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        background-color: #333;
        right: 0;
        bottom: 0;
    }

    .calendario-checkin-manager .calendario-reservas .checkout-and-block-1:before, .checkout-and-block-1:after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
    }

    .calendario-checkin-manager .calendario-reservas .checkout-and-block-1:before {
        top: 0;
        border-color: #ffd218 transparent transparent #ffd218;
        border-width: 31px 16px 0 0;
    }

    .calendario-checkin-manager .calendario-reservas .checkout-and-block-1:after {
        top: 0;
        border-color: #ffd218 transparent transparent #ffd218;
        border-width: 30px 15px 0 0;
    }

    /* Bloqueo/entrada */
    .calendario-checkin-manager .calendario-reservas .block-and-checkin {
        position: relative;
        border-left: 1px solid #ffd218;
        border-right: 1px solid #ffd218;
    }

    .calendario-checkin-manager .calendario-reservas .block-and-checkin .bg {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        background-color: #ffd218;
        right: 0;
        bottom: 0;
    }

    .calendario-checkin-manager .calendario-reservas .block-and-checkin-1:before, .block-and-checkin-1:after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
    }

    .calendario-checkin-manager .calendario-reservas .block-and-checkin-1:before {
        top: 0;
        border-color: #333 transparent transparent #333;
        border-width: 31px 16px 0 0;
    }

    .calendario-checkin-manager .calendario-reservas .block-and-checkin-1:after {
        top: 0;
        border-color: #333 transparent transparent #333;
        border-width: 30px 15px 0 0;
    }

    .calendario-checkin-manager .reserva-numero-block-and-checkin {
        position: absolute;
        left: 0px;
        top: 6px;
        font-size: 12px;
        letter-spacing: -1px;
        font-weight: 500;
        color: var(--azul-oscuro);
        z-index: 2;
    }

    .calendario-checkin-manager .name, .calendario-checkin-manager .week-days {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: #212121;
        line-height: 18px;
    }

    @media (max-width: 800px) {
        .calendario-checkin-manager .name, .calendario-checkin-manager .week-days {
            font-size: 12px;
        }

        .calendario-checkin-manager table {
            width: 97% !important;
        }

        .limit-text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }

    /* C. Mensual */
    .c-mensual .select-property {
        box-shadow: 0.1rem 0.1rem rgba(0, 0, 0, .05);
    }

    .c-mensual .section-select-property {
        width: 90.9%;
        margin: auto;
    }

    .c-mensual .cont-dia {
        width: 100%;
        height: 100%;
    }

    .c-mensual .name-month {
        min-width: 90px;
        text-align: center;
        margin: 5px;
    }

    .c-mensual .arrow-pointer {
        cursor: pointer;
        padding: 5px;
    }

    .c-mensual .flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .c-mensual .numMes {
        width: 13%;
        height: 48px;
        border-right: 1px solid #E3E3E3;
        border-top: 1px solid #E3E3E3;
        text-align: center;
        background-color: #FFF;
    }

    .c-mensual .selector-monthly {
        background-color: var(--azul-oscuro);
        color: #FFF;
        width: 90.9%;
        margin: auto;
    }

    .c-mensual .calendar-header {
        background-color: #ebeff3;
        color: #212529;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .c-mensual .num-dia {
        color: #a1a1a1;
        text-align: start;
        padding: 0 8px;
    }

    .c-mensual .calendario-movil > tbody > tr:hover {
        background-color: none;
    }

    .c-mensual span.num-dia {
        left: 5px;
        top: 5px;
        line-height: 1;
    }

    .c-mensual .calendario-movil > thead {
        background-color: var(--azul-oscuro);
        color: #FFF;
    }

    .c-mensual .calendario-movil .days {
        background-color: #ebeff3;
    }

    .c-mensual .calendario-movil td.not-currents {
        color: #c0c0c0;
        font-size: 14px;
        background-color: rgb(248, 248, 248);
    }

    .c-mensual .calendario-movil td.normal {
        font-size: 14px;
    }

    .c-mensual .calendario-movil td {
        position: relative;
        height: 48px;
        text-align: center;
        vertical-align: middle;
        border-right: 1px solid #E3E3E3;
        border-top: 1px solid #E3E3E3;
        width: 14.28571429%;
    }

    .c-mensual .ultimo-dia-ocupado {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #FFD700;
        background: -moz-linear-gradient(-45deg, #FFD700 50%, transparent 50%) !important;
        background: -webkit-gradient(linear, left top, right bottom, color-stop(50%,#FFD700), color-stop(50%,transparent)) !important;
        background: -webkit-linear-gradient(-45deg, #FFD700 50%,transparent 50%) !important;
        background: -o-linear-gradient(-45deg, #FFD700 50%,transparent 50%) !important;
        background: -ms-linear-gradient(-45deg, #FFD700 50%,transparent 50%) !important;
        background: linear-gradient(135deg, #FFD700 50%,transparent 50%) !important;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFD700', endColorstr='transparent',GradientType=1 ) !important;
    }

    .c-mensual .ultimo-dia-ocupado-bloqueado {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #FFD700;
        background: -moz-linear-gradient(-45deg, #FFD700 50%, #333 50%) !important;
        background: -webkit-gradient(linear, left top, right bottom, color-stop(50%,#FFD700), color-stop(50%,#333)) !important;
        background: -webkit-linear-gradient(-45deg, #FFD700 50%,#333 50%) !important;
        background: -o-linear-gradient(-45deg, #FFD700 50%,#333 50%) !important;
        background: -ms-linear-gradient(-45deg, #FFD700 50%,#333 50%) !important;
        background: linear-gradient(135deg, #FFD700 50%,#333 50%) !important;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFD700', endColorstr='#333',GradientType=1 ) !important;
    }

    .c-mensual .primer-dia-ocupado {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #FFD700;
        background: -moz-linear-gradient(-45deg, #fff 50%, #FFD700 50%) !important;
        background: -webkit-gradient(linear, left top, right bottom, color-stop(50%,#fff), color-stop(50%,#FFD700)) !important;
        background: -webkit-linear-gradient(-45deg, #fff 50%,#FFD700 50%) !important;
        background: -o-linear-gradient(-45deg, #fff 50%,#FFD700 50%) !important;
        background: -ms-linear-gradient(-45deg, #fff 50%,#FFD700 50%) !important;
        background: linear-gradient(135deg, #fff 50%,#FFD700 50%) !important;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#FFD700',GradientType=1 ) !important;
    }

    .c-mensual .primer-dia-ocupado-bloqueado {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #FFD700;
        background: -moz-linear-gradient(-45deg, #333 50%, #FFD700 50%) !important;
        background: -webkit-gradient(linear, left top, right bottom, color-stop(50%,#333), color-stop(50%,#FFD700)) !important;
        background: -webkit-linear-gradient(-45deg, #333 50%,#FFD700 50%) !important;
        background: -o-linear-gradient(-45deg, #333 50%,#FFD700 50%) !important;
        background: -ms-linear-gradient(-45deg, #333 50%,#FFD700 50%) !important;
        background: linear-gradient(135deg, #333 50%,#FFD700 50%) !important;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333', endColorstr='#FFD700',GradientType=1 ) !important;
    }

    .c-mensual .dia-ocupado {
        color: #FFD700;
        background-color: #FFD700;
    }

    .c-mensual .dia-entrada-salida {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #FFD700;
        background: -webkit-linear-gradient(315deg, #FFD700 42%, #FFF 10%, #FFD700 48%);
        background: linear-gradient(135deg, #FFD700 42%, #FFF 10%, #FFD700 48%);
    }

    .c-mensual .precio > i {
        color: var(--azul-oscuro) !important;
    }

    .c-mensual .bloqueado {
        background-color: #333;
    }

    .c-mensual .entrada-bloqueada {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #333;
        background: -moz-linear-gradient(-45deg, #333 50%, #FFF 50%) !important;
        background: -webkit-gradient(linear, left top, right bottom, color-stop(50%,#333), color-stop(50%,#FFF)) !important;
        background: -webkit-linear-gradient(-45deg, #333 50%,#FFF 50%) !important;
        background: -o-linear-gradient(-45deg, #333 50%,#FFF 50%) !important;
        background: -ms-linear-gradient(-45deg, #333 50%,#FFF 50%) !important;
        background: linear-gradient(135deg, #333 50%,#FFF 50%) !important;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333', endColorstr='#FFF',GradientType=1 ) !important;
    }

    .c-mensual .salida-bloqueada {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #333;
        background: -moz-linear-gradient(-45deg, #FFF 50%, #333 50%) !important;
        background: -webkit-gradient(linear, left top, right bottom, color-stop(50%,#FFF), color-stop(50%,#333)) !important;
        background: -webkit-linear-gradient(-45deg, #FFF 50%,#333 50%) !important;
        background: -o-linear-gradient(-45deg, #FFF 50%,#333 50%) !important;
        background: -ms-linear-gradient(-45deg, #FFF 50%,#333 50%) !important;
        background: linear-gradient(135deg, #FFF 50%,#333 50%) !important;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFF', endColorstr='#333',GradientType=1 ) !important;
    }

    .c-mensual .enlace {
        position: relative;
        bottom: 15px;
    }


    /* Desplegable */
    #nav-drawer-filter {
        position: relative;
    }

    .nav-unshown {
        display: none;
    }

    // #nav-open {
    //     /*display: inline-block;
    //     width: 30px;
    //     height: 22px;
    //     vertical-align: middle;*/
    // }

    #nav-close {
        display: none;
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0;
        transition: .3s ease-in-out;
    }

    #nav-content {
        overflow: auto;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        width: 90%;
        max-width: 330px;
        height: 100%;
        background: #fff;
        transition: .3s ease-in-out;
        -webkit-transform: translateX(-105%);
        transform: translateX(-105%);
    }

    #nav-input-filter:checked ~ #nav-close {
        display: block;
        opacity: .5;
    }

    #nav-input-filter:checked ~ #nav-content {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        box-shadow: 6px 0 25px rgba(0,0,0,.15);
    }

    .badge-num-filter {
        position: relative;
        top: -33px;
        left: 19px;
    }
</style>