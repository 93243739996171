<template>
    <div class="dashboard">
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>

        <!-- Cabecera -->
        <header class="p-3">
            <div class="row align-items-center">
                <div class="col-6">
                    <h1 v-cloak>{{ translator.activitat }}</h1>
                </div>
                <div class="col-6 text-right">
                    <input id="search-box" type="text" v-model="searchBooking" class="search-box" autocomplete="noComplete" />
                    <label class="search-box-label mb-0" for="search-box">
                        <span class="search-icon"><i class="fal fa-search ml-1"></i></span>
                    </label>
                </div>
            </div>
        </header>

        <transition name="fade">
            <main class="container" v-if="dashboard != '' && !isPreLoad">

                <!-- Reservas -->
                <router-link :to="{ name: 'ManagerBookings', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') }, }" class="card shadow-sm mb-3 p-3 router-link">
                    <div class="d-flex align-items-center justify-content-between pb-2">
                        <h4 style="margin-bottom: 0; font-size: 18px;">
                            {{ translator.reserves }}
                        </h4>
                        <i class="fal fa-list-ul fa-lg" style="opacity: 0.4;"></i>
                    </div>

                    <div style="font-size: 14px;">
                        {{ dashboard.arrivals_week }} {{ translator.arribades }} |
                        {{ dashboard.departures_week }} {{ translator.sortides }}
                    </div>
                </router-link>

                <!-- Calendari -->
                <router-link :to="{ name: 'ManagerAvailability', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') }, }" class="card shadow-sm mb-3 p-3 router-link">
                    <div class="d-flex align-items-center justify-content-between pb-2">
                        <h4 style="margin-bottom: 0; font-size: 18px;">
                            {{ translator.calendari }}
                        </h4>
                        <i class="fal fa-calendar-alt fa-lg" style="opacity: 0.4;"></i>
                    </div>

                    <div style="font-size: 14px;">
                        {{ translator.afegirReservesBloquejos }}
                    </div>
                </router-link>

                <!-- Limpieza -->
                <router-link :to="{ name: 'ManagerCleaning', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') }, }" class="card shadow-sm mb-3 p-3 router-link">
                    <div class="d-flex align-items-center justify-content-between pb-2">
                        <h4 style="margin-bottom: 0; font-size: 18px;">
                            {{ translator.neteja }}
                        </h4>
                        <i class="fal fa-broom fa-lg" style="opacity: 0.4;"></i>
                    </div>

                    <div style="font-size: 14px;">
                        {{ dashboard.cleanings_week }} {{ translator.netegesSetmana }}

                        <!--{{ translator.avui }}: {{ dashboard.cleanings_today }}-->
                    </div>
                </router-link>

                <!-- Chat -->
                <router-link :to="{ name: 'ManagerListChats', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') }, }" class="card shadow-sm mb-3 p-3 router-link">
                    <div class="d-flex align-items-center justify-content-between pb-2">
                        <h4 style="margin-bottom: 0; font-size: 18px;">
                            {{ translator.xat }}
                        </h4>
                        <i class="fal fa-comment-alt-dots fa-lg" style="opacity: 0.4;"></i>
                    </div>

                    <div style="font-size: 14px;">
                        {{ dashboard.pending_chats }} {{ translator.missatgesSenseContestar }}
                    </div>
                </router-link>

                <!-- Mantenimiento  -->
                <router-link :to="{ name: 'ManagerService', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') }, }" class="card shadow-sm mb-3 p-3 router-link">
                    <div class="d-flex align-items-center justify-content-between pb-2">
                        <h4 style="margin-bottom: 0; font-size: 18px;">
                            {{ translator.manteniment }}
                        </h4>
                        <i class="fal fa-hammer fa-lg" style="opacity: 0.4;"></i>
                    </div>
                    <div style="font-size: 14px;">
                        {{ dashboard.pending_services }} {{ translator.tasquesPendents }}
                    </div>
                </router-link>

            </main>
        </transition>

        <NavbarManager :translator="translator" />

    </div>
</template>

<script>
    import NavbarManager from "@/components/NavbarManager.vue";
    import { mapState } from "vuex";

    export default {
        name: "Dashboard",

        components: {
            NavbarManager,
        },

        computed: {
            ...mapState("utils", ["user", "translator"]),
            ...mapState("manager", ["dashboard"]),
        },

        watch: {
            translator() {
                this.isPreLoad = false;
            },
            searchBooking(newQ) {
                newQ.length == 6 ? this.getBooking() : null;
            }
        },

        async created() {
            try {
                document.getElementsByTagName('body')[0].style.backgroundColor = '#f6f7f9';
                this.isPreLoad = true;
                await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, "+") });
                await this.$store.dispatch("manager/getDashboard", { usr: String(this.$route.query.id).replace(/[ ]/g, "+") });
            } catch (error) {
                console.error(error);
            }
        },

        data() {
            return {
                isPreLoad: false,
                searchBooking: ''
            };
        },

        methods: {
            getBooking() {
                console.log('Buscamos reserva ' + this.searchBooking)
            }
        },

        filters: {},
    };
</script>

<style lang="scss" scoped>

    header h1 {
        font-size: 1rem;
        font-weight: 400;
        line-height: 25px;
    }

    .container-nav-top {
        height: 50px;
        margin: 1rem auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            color: var(--azul-oscuro);
            text-decoration: none;

            &:hover {
                color: lighten($color: #0c2c40, $amount: 15);
            }
        }
    }

    .router-link {
        background-color: #FFF;
        text-decoration: none;
        color: var(--azul-oscuro);
        transition: box-shadow .25s;

        &:hover {
            transition: box-shadow .2s;
            box-shadow: 0 0.125rem 0.45rem rgba(0,0,0,.2) !important;
        }
    }

    .resumen-checkin {
        border: 1px solid var(--azul-oscuro);
        padding: 1rem;
        background-color: var(--colores-blanco);
    }

    .boxes {
        width: 30%;
    }

    /* Buscador */
    $tl: 0.3s; // transition length

    .search-box {
        transition: width $tl, border-radius $tl, background $tl, box-shadow $tl;
        width: 35px;
        height: 25px;
        border-radius: 2px;
        border: none;
        max-width: 120px;
        cursor: pointer;
        background: transparent;
        color: #fff;

        & + label .search-icon {
            color: white;
        }

        &:hover {
            color: white;
            background: rgb(200, 200, 200);
            box-shadow: 0 0 0 2px var(--azul-oscuro);

            & + label .search-icon {
                color: white;
            }
        }

        &:focus {
            transition: width $tl cubic-bezier(0, 1.22, 0.66, 1.39), border-radius $tl, background $tl;
            border: none;
            outline: none;
            box-shadow: none;
            padding-left: 15px;
            cursor: text;
            width: 300px;
            border-radius: auto;
            background: rgb(235, 235, 235);
            color: black;

            & + label .search-icon {
                color: black;
            }
        }

        &:not(:focus) {
            text-indent: -5000px;
        }
    }

    .search-box-label {
        width: 5px;
    }

    .search-icon {
        position: relative;
        left: -30px;
        color: white;
        cursor: pointer;
    }
</style>