<template>
    <div>
        <!-- Cabecera -->
        <header class="fixed-top px-4 py-3">
            <div class="row align-items-center">
                <div class="col-6">
                    <h1 v-cloak>
                        <routerLink :to="{ name: 'ManagerListChats', query: { id: $route.query.id }}">
                            <i class="fal fa-chevron-left mr-3"></i>
                            {{ translator.xat }}
                        </routerLink>
                    </h1>
                </div>
                <div class="col-6 text-right">
                </div>
            </div>
        </header>

        <main class="chat-conversacion container container-main">
            <!-- Spiner -->
            <div v-if="isPreLoad"
                style="height: 90vh; display:flex; justify-content:center; align-items:center; background-color: #f6f7f9;">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
            <!-- Fin spiner -->
        
            <div id="chat">
                <div class="d-flex" v-for="(m, index) in xatConversa" :key="index"
                    :class="[m.chat_sender === 'host' ? 'justify-content-end' : 'justify-content-start']">
                    <div :class="[m.chat_sender === 'host' ? 'host alert alert-light' : 'guest alert alert-success']">
                        <small class="text-black-50" style="font-size:12px;">{{ m.chat_date }}</small><br />
                        <span class="text-dark">
                            {{ m.chat_text}}
                        </span>
                    </div>
                </div>
            </div>
        </main>

        <div class="chat-escribe border-top input-group fixed-bottom">
            <input type="text" v-model="inputChat" @keyup.enter="sendMessage" class="form-control border-right-0"
                aria-label="message" aria-describedby="send" placeholder="Escribe...">
            <div class="input-group-prepend">
                <button :class="[inputChat === '' ? 'disabled' : null]" class="btn btn-primary" type="button"
                    @click.prevent="sendMessage"><i class="fad fa-paper-plane"></i></button>
            </div>
        </div>

        

    </div>
</template>

<script>
    // import NavbarManager from '@/components/NavbarManager.vue';
    import { mapState } from "vuex";

    export default {
        name: "ManagerChat",
        components: {
            // NavbarManager
        },
        computed: {
            ...mapState("utils", ['user', 'translator']),
            ...mapState("manager", ["xatConversa"]),
        },
        watch: {
            translator() {
                this.$store.dispatch("manager/getChat", { usr: `${this.$route.params.id}` })
            },
            xatConversa() {
                this.isPreLoad = false;
                setTimeout(()=> {
                    this.scrollToEnd();
                }, 10);
            }
        },

        data() {
            return {
                isPreLoad: false,
                inputChat: ''
            };
        },

        async created() {
            this.isPreLoad = true;
            document.getElementsByTagName('body')[0].style.backgroundColor = '#f6f7f9';
            try {
                this.translator
                    ? await this.$store.dispatch("manager/getChat", { usr: `${this.$route.params.id}` })
                    : await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            } catch (error) {
                console.error(error);
            }
        },

        methods: {
            async sendMessage() {
                if (this.inputChat != '') {
                    try {
                        this.isPreLoad = true;
                        await this.$store.dispatch("manager/postSendMessage", { 
                            usr: `${this.$route.params.id}`, 
                            text: this.inputChat, 
                            ip: '' 
                        });
                        this.inputChat = '';
                    } catch (error) {
                        console.error(error);
                    }
                }
            },
            scrollToEnd() {    	
                window.scrollTo(0,document.body.scrollHeight)
            },
        }
    };
</script>

<style lang="scss" scoped>

    .container-nav-top {
        height: 50px;
        margin: 1rem auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            color: var(--azul-oscuro);
            text-decoration: none;
            cursor: pointer;

            &:hover {
                color: lighten($color: #0c2c40, $amount: 15);
            }
        }
    }

    .container-main {
        background-color: #f6f7f9;
        min-height: calc(100vh - 176px);
        width: 100%;
    }

    .contenedor-respuesta {
        max-width: 770px;
    }

    .btn-primary.disabled, .btn-primary:disabled {
        background: #f8d688;
        border-color: #f8d688;
        opacity: 1;
    }

    .host {
        max-width: 70%;
    }

    .guest {
        max-width: 70%;
    }
</style>