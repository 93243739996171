<template>
    <div>

        <!-- Cabecera -->
        <header class="p-3">
            <div class="row align-items-center">
                <div class="col-auto align-self-center">
                    <h1 v-cloak>{{ translator.reserves }}</h1>
                </div>
                <div class="col text-right">
                    <label id="nav-open" for="nav-input-filter" class="mr-3 mb-0">
                        <span class="filtrar"><i class="fal fa-sliders-h"></i> {{ translator.filtrar }}</span>
                    </label>
                    <input id="search-box" type="text" @keyup.enter="filterResults" @click="filterResults"
                        v-model="searchBooking" class="search-box" autocomplete="noComplete" />
                    <label class="search-box-label mb-0" for="search-box">
                        <span class="search-icon"><i class="fal fa-search"></i></span>
                    </label>
                </div>
            </div>
        </header>

        <main class="container">

            <div id="nav-drawer-filter">

                <input id="nav-input-filter" type="checkbox" class="nav-unshown">
                <label class="nav-unshown" id="nav-close" for="nav-input-filter"></label>

                <!-- Desplegable lateral dels filtres -->
                <div id="nav-content">
                    <div class="p-3 border-bottom text-left">
                        {{ translator.filtrar_resultats }}
                    </div>
                    <div v-if="!isPreLoad && filterBookings.capacity" class="p-3">

                        <!-- Fechas -->
                        <div class="mb-3">
                            <label class="form-label">
                                {{ translator.dates }} Fechas
                            </label>
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <input type="date" v-model="datefrom"
                                        class="form-control form-control-sm date-input mb-1" :min="datefromMin">
                                </div>
                                <div class="form-group col-6">
                                    <input type="date" v-model="dateto" class="form-control form-control-sm date-input"
                                        :min="dateto">
                                </div>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input class="custom-control-input" type="radio" value="entrada" id="radioCheckin"
                                    v-model="datetype">
                                <label class="custom-control-label" for="radioCheckin">{{ translator.checkin }}</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input class="custom-control-input" type="radio" value="sortida" id="radioBooking"
                                    v-model="datetype">
                                <label class="custom-control-label" for="radioBooking">{{ translator.checkout }}</label>
                            </div>
                        </div>

                        <!-- Capacidad -->
                        <div v-if="filterBookings.capacity.length > 0" class="mb-2">
                            <label for="capacity" class="form-label">
                                {{ translator.capacitat }}
                            </label>
                            <select id="capacity" v-model="capacity" class="form-control form-control-sm">
                                <option :value="{ id: 0, text: '' }">Todo</option>
                                <option v-for="filter in filterBookings.capacity" :key="filter.id"
                                    :value="{ id: filter.id, text: filter.text }">{{ filter.text }} personas</option>
                            </select>
                        </div>

                        <!-- Canales -->
                        <div v-if="filterBookings.channels.length > 0" class="mb-2">
                            <label for="capacity" class="form-label">
                                {{ translator.agencia }}
                            </label>
                            <select id="channels" v-model="channels" class="form-control form-control-sm">
                                <option :value="{ id: 0, text: '' }">Todas</option>
                                <option v-for="filter in filterBookings.channels" :key="filter.id"
                                    :value="{ id: filter.id, text: filter.text }">{{ filter.text }}</option>
                            </select>
                        </div>

                        <!-- Paises -->
                        <div v-if="filterBookings.countries.length > 0" class="mb-2">
                            <label for="capacity" class="form-label">
                                {{ translator.pais }}
                            </label>
                            <select id="countries" v-model="countries" class="form-control form-control-sm">
                                <option :value="{ id: '', text: '' }">Todos</option>
                                <option v-for="filter in filterBookings.countries" :key="filter.id"
                                    :value="{ id: filter.id, text: filter.text }">{{ filter.text }}</option>
                            </select>
                        </div>

                        <!-- Grupos -->
                        <div v-if="filterBookings.groups.length > 0" class="mb-2">
                            <label for="capacity" class="form-label">
                                {{ translator.grup }}
                            </label>
                            <select id="groups" v-model="groups" class="form-control form-control-sm">
                                <option :value="{ id: 0, text: '' }">Todos</option>
                                <option v-for="filter in filterBookings.groups" :key="filter.id"
                                    :value="{ id: filter.id, text: filter.text }">{{ filter.text }}</option>
                            </select>
                        </div>

                        <!-- Manager -->
                        <div v-if="filterBookings.management.length > 0" class="mb-2">
                            <label for="capacity" class="form-label">
                                {{ translator.gestio }}
                            </label>
                            <select id="management" v-model="management" class="form-control form-control-sm">
                                <option :value="{ id: 0, text: '' }">Todos</option>
                                <option v-for="filter in filterBookings.management" :key="filter.id"
                                    :value="{ id: filter.id, text: filter.text }">{{ filter.text }}</option>
                            </select>
                        </div>

                        <!-- Propietarios -->
                        <div v-if="filterBookings.owners.length > 0" class="mb-2">
                            <label for="capacity" class="form-label">
                                {{ translator.propietari }}
                            </label>
                            <select id="owners" v-model="owners" class="form-control form-control-sm">
                                <option :value="{ id: 0, text: '' }">Todos</option>
                                <option v-for="filter in filterBookings.owners" :key="filter.id"
                                    :value="{ id: filter.id, text: filter.text }">{{ filter.text }}</option>
                            </select>
                        </div>

                        <!-- Propiedades -->
                        <div v-if="filterBookings.properties.length > 0" class="mb-2">
                            <label for="capacity" class="form-label">
                                {{ translator.propietat }}
                            </label>
                            <select id="properties" v-model="properties" class="form-control form-control-sm">
                                <option :value="{ id: 0, text: '' }" selected>Todas</option>
                                <option v-for="filter in filterBookings.properties" :key="filter.id"
                                    :value="{ id: filter.id, text: filter.text }">{{ filter.text }}</option>
                            </select>
                        </div>

                        <!-- Regiones -->
                        <div v-if="filterBookings.regions.length > 0" class="mb-2">
                            <label for="capacity" class="form-label">
                                {{ translator.regio }}
                            </label>
                            <select id="regions" v-model="regions" class="form-control form-control-sm">
                                <option :value="{ id: 0, text: '' }">Todas</option>
                                <option v-for="filter in filterBookings.regions" :key="filter.id"
                                    :value="{ id: filter.id, text: filter.text }">{{ filter.text }}</option>
                            </select>
                        </div>

                        <!-- Tipología -->
                        <div v-if="filterBookings.typologies.length > 0" class="mb-2">
                            <label for="capacity" class="form-label">
                                {{ translator.tipologia }}
                            </label>
                            <select id="typologies" v-model="typologies" class="form-control form-control-sm">
                                <option :value="{ id: 0, text: '' }">Todas</option>
                                <option v-for="filter in filterBookings.typologies" :key="filter.id"
                                    :value="{ id: filter.id, text: filter.text }">{{ filter.text }}</option>
                            </select>
                        </div>

                        <!-- Tipo -->
                        <div v-if="filterBookings.type.length > 0" class="mb-2">
                            <label for="capacity" class="form-label">
                                {{ translator.estat }}
                            </label>
                            <select id="bookingType" v-model="type" class="form-control form-control-sm">
                                <option :value="{ id: 0, text: '' }">Todos</option>
                                <option v-for="filter in filterBookings.type" :key="filter.id"
                                    :value="{ id: filter.id, text: filter.text }">{{ filter.text }}</option>
                            </select>
                        </div>

                        <button v-if="
                            datefrom == moment(new Date()).format('yyyy-MM-DD') &&
                            dateto == moment(new Date()).add(6, 'd').format('yyyy-MM-DD') &&
                            datetype == 'entrada' &&
                            isFilteredSearch == false &&
                            capacity.id == 0 &&
                            channels.id == 0 &&
                            countries.id == '' &&
                            groups.id == 0 &&
                            management.id == 0 &&
                            owners.id == 0 &&
                            properties.id == 0 &&
                            regions.id == 0 &&
                            typologies.id == 0 &&
                            type.id == 0
                        " disabled class="btn btn-secondary">
                            {{ translator.buscar }}
                        </button>
                        <button v-else @click="filteredSearch" class="btn btn-secondary">{{ translator.buscar
                            }}</button>

                    </div>
                </div>
            </div>

            <!-- Spiner -->
            <div v-if="isPreLoad"
                style="height: 90vh; display:flex; justify-content:center; align-items:center; background-color: #f6f7f9;">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
            <!-- Fin spiner -->

            <div v-else>

                <!-- Badges dels filtres -->
                <div v-if="isFilteredSearch" class="badges-filtres mb-3">
                    <span v-if="dateto != datefrom" class="badge badge-secondary mr-1 mb-1">{{ translator.data }} <span
                            class="badge badge-light">{{ datefrom | formatDate }} {{ dateto | formatDate
                            }}</span></span>
                    <span v-if="capacity.id != 0" class="badge badge-secondary mr-1 mb-1">{{ translator.capacitat }}
                        <span class="badge badge-light">{{ capacity.text }}</span></span>
                    <span v-if="channels.id != 0" class="badge badge-secondary mr-1 mb-1">{{ translator.agencia }} <span
                            class="badge badge-light">{{ channels.text }}</span></span>
                    <span v-if="countries.id != ''" class="badge badge-secondary mr-1 mb-1">{{ translator.pais }} <span
                            class="badge badge-light">{{ countries.text }}</span></span>
                    <span v-if="groups.id != 0" class="badge badge-secondary mr-1 mb-1">{{ translator.grup }} <span
                            class="badge badge-light">{{ groups.text }}</span></span>
                    <span v-if="management.id != 0" class="badge badge-secondary mr-1 mb-1">{{ translator.gestio }}
                        <span class="badge badge-light">{{ management.text }}</span></span>
                    <span v-if="owners.id != 0" class="badge badge-secondary mr-1 mb-1">{{ translator.propietari }}
                        <span class="badge badge-light">{{ owners.text }}</span></span>
                    <span v-if="properties.id != 0" class="badge badge-secondary mr-1 mb-1">{{ translator.propietat }}
                        <span class="badge badge-light">{{ properties.text }}</span></span>
                    <span v-if="regions.id != 0" class="badge badge-secondary mr-1 mb-1">{{ translator.regio }} <span
                            class="badge badge-light">{{ regions.text }}</span></span>
                    <span v-if="typologies.id != 0" class="badge badge-secondary mr-1 mb-1">{{ translator.tipologia }}
                        <span class="badge badge-light">{{ typologies.text }}</span></span>
                    <span v-if="type.id != 0" class="badge badge-secondary mr-1 mb-1">{{ type.text }}</span>
                    <span @click="deleteFilters" class="badge badge-light text-danger mr-1"
                        style="cursor:pointer;line-height: 1.5;"><i class="far fa-redo"></i> {{ translator.esborrar
                        }}</span>
                </div>

                <!-- Llistat de reserves -->
                <div v-if="visibleBookings.length > 0" class="listado-reservas">
                    <div v-for="(booking, index1) in visibleBookings" :key="index1" class="collapse multi-collapse show"
                        :id="'booking' + index1">
                        <router-link class="card rounded shadow-sm mb-2 router-link"
                            :to="{ name: 'ManagerBooking', params: { bookingId: booking.reservation }, query: { id: $route.query.id } }"
                            v-cloak>

                            <div class="row no-gutters">
                                <div class="col-3">
                                    <img :src="booking.lodging_image" class="img-fluid rounded" />
                                </div>
                                <div class="col-9 p-2">
                                    <div class="card-body p-0">
                                        <h5 class="card-title" v-cloak>
                                            {{ booking.customer_name }}
                                            <small class="ml-auto"></small>
                                        </h5>
                                        <div class="lodging-name" v-cloak>
                                            {{ booking.lodging_name }} - <span>{{ booking.reservation }}</span>
                                        </div>
                                        <div class="fecha" v-cloak>
                                            <span><i class="fal fa-clock mr-1"></i>{{ booking.arrival_date | formatDate
                                                }}</span>
                                            <span class="ml-1" v-if="booking.arrival_time.length > 0" v-cloak>{{
                                                booking.arrival_time }} h</span>
                                            <span class="ml-1">({{ booking.nights }}<span v-if="booking.nights == 1"
                                                    v-cloak>{{ translator.nit | toLower }}</span><span v-else v-cloak>{{
                                                        translator.nits | toLower }}</span>)</span>

                                            <!-- <span><i class="fal fa-clock mr-1"></i>{{ booking.arrival_date | formatDate }}</span>
                                            <span class="ml-1" v-if="booking.arrival_time.length > 0" v-cloak>{{ booking.arrival_time }} h</span> -->
                                            <!--<span>{{ booking.departure_date | formatDate }}</span>
                                            <span v-if="booking.arrival_time.length > 0" v-cloak>{{ booking.departure_time }} h</span>-->
                                        </div>
                                        <!-- <div class="mt-1" v-cloak>
                                            <div class="d-flex justify-content-between">
                                                <div class="info-reserva">                                                    
                                                    <span class="mr-1">{{ booking.guests }}</span>
                                                    <span class="mr-3" v-if="booking.guests == 1" v-cloak>{{ translator.hoste | toLower }}</span>
                                                    <span class="mr-3" v-else v-cloak>{{ translator.hostes | toLower }}</span>
                                                </div>
                                            </div>
                                        </div>                                         -->
                                    </div>
                                </div>
                            </div>

                        </router-link>
                    </div>
                </div>

                <!-- Missatge de que no hi ha reserves -->
                <div v-else class="no-reserves">
                    0 {{ translator.reserves | toLower }}
                </div>
            </div>
        </main>
        <NavbarManager :translator="translator" />
    </div>
</template>

<script>
import NavbarManager from '@/components/NavbarManager.vue';
import { mapState } from "vuex";

export default {
    name: "ManagerBookings",
    components: {
        NavbarManager,
    },
    computed: {
        ...mapState("utils", ['user', 'translator']),
        ...mapState("manager", ['filterBookings', 'bookings']),
    },
    watch: {
        translator() {
            this.$store.dispatch("manager/getFilterBookings", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            this.getBookings();
        },
        bookings() {
            this.isPreLoad = false
            this.visibleBookings = this.bookings;
        },
        datefrom(newQ) {
            newQ == '' ? this.datefrom = this.moment(new Date()).format('yyyy-MM-DD') : null;
            this.moment(this.dateto).format('yyyy-MM-DD') < this.moment(this.datefrom).format('yyyy-MM-DD') ? this.dateto = this.moment(this.datefrom).add(1, 'd').format('yyyy-MM-DD') : null;
        },
        dateto(newQ) {
            newQ == '' ? this.dateto = this.moment(this.datefrom).add(1, 'd').format('yyyy-MM-DD') : null;
        },
    },
    async created() {
        try {
            document.getElementsByTagName('body')[0].style.backgroundColor = '#f6f7f9';
            this.isPreLoad = true;
            sessionStorage.setItem('listado', 'ManagerBookings');
            if (this.translator) {
                this.$store.dispatch("manager/getFilterBookings", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                this.getBookings();
            } else {
                await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            }

        } catch (error) {
            console.error(error);
        }
    },
    data() {
        return {
            isPreLoad: false,
            visibleBookings: [],
            searchBooking: '',
            isFilteredSearch: false,
            isRemoveFilter: false,
            datefromMin: this.moment(new Date()).format('yyyy-MM-DD'),
            datefrom: this.moment(new Date()).format('yyyy-MM-DD'),
            dateto: this.moment(new Date()).add(6, 'd').format('yyyy-MM-DD'),
            datetype: 'entrada',
            capacity: { id: 0, text: '' },
            channels: { id: 0, text: '' },
            countries: { id: '', text: '' },
            groups: { id: 0, text: '' },
            management: { id: 0, text: '' },
            owners: { id: 0, text: '' },
            properties: { id: 0, text: '' },
            regions: { id: 0, text: '' },
            typologies: { id: 0, text: '' },
            type: { id: 0, text: '' },
        };
    },
    methods: {
        filterResults() {
            if (this.searchBooking.length > 0) {
                let result = this.visibleBookings.filter((booking) => {
                    let display = false;

                    if (booking.reservation.indexOf(this.searchBooking) > -1) {
                        display = true;
                    }
                    if (booking.customer_name.toLowerCase().indexOf(this.searchBooking.toLowerCase()) > -1) {
                        display = true;
                    }

                    return display;
                });
                this.visibleBookings = result;
            } else {
                this.getBookings();
            }
        },

        deleteFilters() {
            this.isFilteredSearch = false;
            this.datefromMin = this.datefrom = this.dateto = this.convertDate(new Date());
            this.capacity = this.channels = this.groups = this.management = this.owners = this.properties = this.regions = this.typologies = this.type = { id: 0, text: '' };
            this.countries = { id: '', text: '' };
            this.datetype = 'entrada';
            this.isRemoveFilter = true;
            this.getBookings();
        },
        async getBookings() {
            let parameters = {
                usr: String(this.$route.query.id).replace(/[ ]/g, '+'),
                datefrom: this.datefrom, //Date
                dateto: this.dateto, //Date
                datetype: this.datetype, //Boolean 0 = reserva 1 = checkin
                type: this.type.id, //Integer
                country: this.countries.id, //String
                region: this.regions.id, //Integer
                channel: this.channels.id, //Integer
                group: this.groups.id, //Integer
                owner: this.owners.id, //Integer
                properties: this.properties.id, //Integer
                management: this.management.id, //Integer
                typology: this.typologies.id, //Integer
                capacity: this.capacity.id, //Integer
            }
            await this.$store.dispatch("manager/getBookings", parameters);
        },
        filteredSearch() {
            if (this.datefrom != this.convertDate(new Date()) ||
                this.dateto != this.convertDate(new Date()) ||
                this.datetype != 'entrada' ||
                this.capacity.id != 0 ||
                this.channels.id != 0 ||
                this.countries.id != '' ||
                this.groups.id != 0 ||
                this.management.id != 0 ||
                this.owners.id != 0 ||
                this.properties != 0 ||
                this.regions.id != 0 ||
                this.typologies.id != 0 ||
                this.type.id != 0) {
                this.isFilteredSearch = true;
                document.getElementById("nav-input-filter").checked = false;
                this.getBookings();
            }

        },
        convertDate(date) {
            return date.toISOString().slice(0, 10);
        },

        addDays(date, days) {
            let result = new Date(date);
            result.setDate(result.getDate() + days);
            return this.convertDate(result);
        }
    },
    filters: {
        toLower(value) {
            if (!value) return "";
            value = value.toString();
            return value.toLowerCase();
        },
        formatDate(date) {
            return new Intl.DateTimeFormat('es-ES').format(new Date(date));
        }
    }
};
</script>

<style lang="scss" scoped>
.container-nav-top {
    height: 50px;
    margin: 1rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        color: var(--azul-oscuro);
        text-decoration: none;

        &:hover {
            color: lighten($color: #0c2c40, $amount: 15);
        }
    }
}

// .date-input {
//     width: 172px;
// }

.router-link {
    background-color: #FFF;
    text-decoration: none;
    color: var(--azul-oscuro);
    transition: box-shadow .25s;

    &:hover {
        transition: box-shadow .2s;
        box-shadow: 0 0.125rem 0.45rem rgba(0, 0, 0, .2) !important;
    }
}

/* Desplegable */
#nav-drawer-filter {
    position: relative;
}

.nav-unshown {
    display: none;
}

// #nav-open {
//     display: inline-block;
//     width: 30px;
//     height: 22px;
//     vertical-align: middle;
// }

#nav-close {
    display: none;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0;
    transition: .3s ease-in-out;
}

#nav-content {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 90%;
    max-width: 330px;
    height: 100%;
    background: #fff;
    transition: .3s ease-in-out;
    -webkit-transform: translateX(-105%);
    transform: translateX(-105%);
}

#nav-input-filter:checked~#nav-close {
    display: block;
    opacity: .5;
}

#nav-input-filter:checked~#nav-content {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    box-shadow: 6px 0 25px rgba(0, 0, 0, .15);
}

.form-label {
    font-size: 12px;
    margin-bottom: 3px;
    opacity: 0.6;
}

/* Buscador */
$tl: 0.3s; // transition length

.search-box {
    transition: width $tl, border-radius $tl, background $tl, box-shadow $tl;
    width: 35px;
    border-radius: 2px;
    border: none;
    max-width: 120px;
    cursor: pointer;
    background: transparent;
    color: #fff;

    &+label .search-icon {
        color: white;
    }

    &:hover {
        color: white;
        background: rgb(200, 200, 200);
        box-shadow: 0 0 0 2px var(--azul-oscuro);

        &+label .search-icon {
            color: white;
        }
    }

    &:focus {
        transition: width $tl cubic-bezier(0, 1.22, 0.66, 1.39), border-radius $tl, background $tl;
        border: none;
        outline: none;
        box-shadow: none;
        padding-left: 15px;
        cursor: text;
        width: 300px;
        border-radius: auto;
        background: rgb(235, 235, 235);
        color: black;

        &+label .search-icon {
            color: black;
        }
    }

    &:not(:focus) {
        text-indent: -5000px;
    }
}

.search-box-label {
    width: 0;
}

.search-icon {
    position: relative;
    left: -30px;
    color: white;
    cursor: pointer;
}

/* Resultats */

.card-title {
    font-size: 14px;
}

.card-title small {
    font-size: 100%;
}

.card-body p {
    font-size: 13px;
    margin-bottom: 2px;
}
</style>