<template>
    <div class="manager-serv-cal-info">

        <!-- Cabecera -->
        <header class="p-3">
            <h1 class="m-0">
                <router-link :to="{ name: 'ManagerServiceCalendar', query: { id: $route.query.id }}">
                    <i class="fal fa-chevron-left"></i>
                </router-link>                
                {{ translator.dadesreserva }}
            </h1>            
        </header>        

        <div class="container mt-5">

            <!-- Spiner -->
            <div v-if="isPreLoad" style="height: 100vh; display: flex; justify-content: center; align-items: center;">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
            <!-- Fin spiner -->

            <!-- <header v-if="!isPreLoad" class="pt-3 mb-3">
                <div class="flex-titular">
                    <div>
                        <h1 v-cloak>{{ translator.dadesreserva }}</h1>
                    </div>
                    <div class="text-right">
                        <a class="link-atras" @click="$router.go(-1)">
                            <i class="fal fa-undo-alt"></i>
                        </a>
                    </div>
                </div>
            </header> -->

            <div class="py-3" v-if="booking.registered_guests">
                <div class="row" v-if="booking.arrival_date">
                    <div class="col bg-white py-2">
                        <table class="table table-sm">
                            <tbody>
                                <tr class="line-head-info" v-if="booking.lodging_name != ''">
                                    <td class="">
                                        {{ translator.propietat }}
                                    </td>
                                    <td class="text-right">
                                        {{ booking.lodging_name }}
                                    </td>
                                </tr>
                                <tr v-if="booking.reservation != ''">
                                    <td>
                                        {{ translator.reserva }}
                                    </td>
                                    <td class="text-right">
                                        {{ booking.reservation }}
                                    </td>
                                </tr>
                                <tr v-if="booking.arrival_date != ''">
                                    <td>
                                        {{ translator.arribada }}
                                    </td>
                                    <td class="text-right">
                                        {{ booking.arrival_date | transformDate }} <span v-if="booking.arrival_time != ''">{{ booking.arrival_time }}h</span>
                                    </td>
                                </tr>
                                <tr v-if="booking.departure_date != ''">
                                    <td>
                                        {{ translator.sortida }}
                                    </td>
                                    <td class="text-right">
                                        {{ booking.departure_date | transformDate }} <span v-if="booking.departure_time != ''">{{ booking.departure_time }}h</span>
                                    </td>
                                </tr>
                                <tr v-if="booking.adults != ''">
                                    <td>
                                        {{ translator.persones }}
                                    </td>
                                    <td class="text-right">
                                        {{ parseInt(booking.adults) + parseInt(booking.children) }}
                                    </td>
                                </tr>
                                <tr v-if="booking.nights != ''">
                                    <td>
                                        {{ translator.nits }}
                                    </td>
                                    <td class="text-right">
                                        {{ booking.nights }}
                                    </td>
                                </tr>
                                <tr v-if="booking.arrival_remarks != ''">
                                    <td>
                                        {{ translator.observacionsentrada }}
                                    </td>
                                    <td class="text-right">
                                        {{ booking.arrival_remarks }}
                                    </td>
                                </tr>
                                <tr v-if="booking.departure_remarks != ''">
                                    <td>
                                        {{ translator.observacionssortida }}
                                    </td>
                                    <td class="text-right">
                                        {{ booking.departure_remarks }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <NavbarManager :translator="translator" />
    </div>
</template>

<script>
    import NavbarManager from "@/components/NavbarManager.vue";
    import { mapState } from "vuex";

    export default {
        name: "ManagerServiceCalendarInfo",
        components: {
            NavbarManager,
        },
        computed: {
            ...mapState("utils", ["user", "translator"]),
            ...mapState("manager", ["booking"]),
        },
        watch: {
            translator() {
                this.$store.dispatch("manager/getBooking", { usr: this.$route.query.id, reserva: this.$route.params.bookingId });
            }
        },
        data() {
            return {
                isPreLoad: false
            };
        },
        async created() {
            try {
                this.isPreLoad = true;
                this.translator
                    ? await this.$store.dispatch("manager/getBooking", { usr: this.$route.query.id, reserva: this.$route.params.bookingId })
                    : await this.$store.dispatch("utils/getUser", { usr: this.$route.query.id });
            } catch (error) {
                console.error(error);
            } finally {
                this.isPreLoad = false;
            }
        },
        filters: {
            transformDate(value) {
                if (!value) return '';
                const backendDate = value.split('-');
                return `${backendDate[2]}-${backendDate[1]}-${backendDate[0]}`;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .manager-serv-cal-info .container-nav-top {
        margin: 1rem auto;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            text-decoration: none;
            font-size: 1.5rem;
            align-self: flex-start;
            &:hover {
                color: lighten($color: #0c2c40, $amount: 15);
            }
        }
        .semana, a {
            color: var(--azul-oscuro);
        }
    }

    .manager-serv-cal-info header {
        background: none;
    }
    .manager-serv-cal-info header h1, .manager-serv-cal-info header .link-atras {
        color: var(--azul-oscuro);
        font-weight: 700;
        font-size: 1.5rem;
    }
    .manager-serv-cal-info header .flex-titular {
        display: flex;
        justify-content: space-between;
        margin: auto;
    }
    .manager-serv-cal-info header .link-atras {
        padding: 12px;
    }
    .manager-serv-cal-info header .link-atras:hover {
        color: #b5b5b5;
    }
    .manager-serv-cal-info .container-service {
        max-width: 752px;
    }
    .manager-serv-cal-info .bg-white {
        border-radius: 4px;
    }
    .manager-serv-cal-info .service .table .line-head-info {
        height: 40px;
        color: var(--colores-gris-medio);
        border-bottom: 1.5px solid #F6F7F9;
    }
    .manager-serv-cal-info .service .table .line-head-info > td {
        border-top: none;
        vertical-align: middle;
    }
    .manager-serv-cal-info .service .table th, .table td {
        border-top: 1px solid #F6F7F9;
    }
</style>