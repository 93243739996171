<template>
    <div>
        <!-- Cabecera -->
        <header class="p-3">
            <div class="row align-items-center">
                <div class="col-6">
                    <h1 v-cloak>{{ translator.xat }}</h1>
                </div>
                <div class="col-6 text-right">                    
                </div>
            </div>
        </header>
        

        <main class="chat container container-main">
        
            <!-- Spiner -->
            <div v-if="isPreLoad"
                style="height: 90vh; display:flex; justify-content:center; align-items:center; background-color: #f6f7f9;">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
            <!-- Fin spiner -->
            <div v-if="xatPendents.length === 0 && !isPreLoad">
                {{ translator.pendent }} 0
            </div>
            <div v-else>
                <router-link v-for="(xat, index) in xatPendents" :key="index"
                    :to="{ name: 'ManagerChat', query: { id: $route.query.id }, params: { id: xat.id }}"
                    class="card rounded shadow-sm mb-2 p-2">
                    <!-- <span class="mr-3 badge badge-primary"> {{ xat.num_messages }} </span> -->
                    <div class="customer-name d-flex justify-content-between mb-1">
                        <div><i data-v-53ce5206="" class="fal fa-comment-alt-dots"></i> {{ xat.customer_name }}</div>
                        <div class="reservation">{{ xat.reservation }}</div>
                    </div>
                    <div>{{ xat.lodging_name }}</div>
                </router-link>
            </div>
        
        </main>

        <NavbarManager :translator="translator" />

    </div>
</template>

<script>
    import NavbarManager from '@/components/NavbarManager.vue';
    import { mapState } from "vuex";

    export default {
        name: "ManagerListChats",
        components: {
            NavbarManager
        },
        computed: {
            ...mapState("utils", ['user', 'translator']),
            ...mapState("manager", ["xatPendents"]),
        },

        watch: {
            translator() {
                this.$store.dispatch("manager/getChatPendents", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') })
            },
            xatPendents() {
                this.isPreLoad = false;
            }

        },

        data() {
            return {
                isPreLoad: false,
            };
        },

        async created() {
            this.isPreLoad = true;
            document.getElementsByTagName('body')[0].style.backgroundColor = '#f6f7f9';
            try {
                this.translator
                    ? await this.$store.dispatch("manager/getChatPendents", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') })
                    : await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            } catch (error) {
                console.error(error);
            }
        },

        methods: {
        }
    };
</script>

<style scoped>

    .container-nav-top {
        height: 50px;
        margin: 1rem auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    a {
        color: var(--azul-oscuro);
        text-decoration: none;
        cursor: pointer;
    }

        a:hover {
            color: #185880
        }

    .container-main {
        background-color: #f6f7f9;
        min-height: calc(100vh - 176px);
        width: 100%;
    }

        .container-main .shadow-sm {
            transition: .3s box-shadow;
            box-shadow: 0rem 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
        }

            .container-main .shadow-sm:hover {
                transition: .3s box-shadow;
                box-shadow: 0.1rem 0.325rem 0.25rem rgb(0 0 0 / 8%) !important;
            }
</style>