<template>
    <div class="limpieza-detalle">

        <!-- Cabecera -->
        <header class="p-3">
            <h1 class="titular-principal" @click="routerLink">
                <i  class="fal fa-chevron-left mr-3"></i>
                {{ translator.neteja }}
            </h1>
        </header>
        
        <main class="limpieza container">

            <div>
                <!-- Spiner -->
                <div v-if="isPreLoad" style="height: 90vh; display:flex; justify-content:center; align-items:center; background-color: #f6f7f9;">
                    <div class="hollow-dots-spinner">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                </div>
                <!-- Fin spiner -->
                

                <div v-if="!isPreLoad"  class="">

                    <!-- Detalles de la limpieza -->
                    <div class="row align-items-center pb-4" 
                        v-if="cleaningDetail.cleaning.cleaning_person != '' ||
                            cleaningDetail.cleaning.cleaning_remarks != '' ||
                            cleaningDetail.cleaning.cleaning_status != '' ||
                            cleaningDetail.cleaning.cleaning_time != ''
                    ">

                        <!-- titulo sección -->
                        <div class="col-7 col-md-9">
                            <h5>
                                <i class="fal fa-file-alt pr-1"></i>
                                <span>{{ translator.detalls }}</span>
                            </h5>                
                        </div>

                        <!-- Botón estado limpieza -->
                        <div class="col-5 col-md-3">
                            <button v-if="cleaningDetail.cleaning.cleaning_status === 'pending'" @click="cambiaEstado(false)" class="btn btn-primary">
                                <span v-if="user.user_language == '2'">Start</span>
                                <span v-else>{{ translator.inici }}</span>
                            </button>
                            <button v-else-if="cleaningDetail.cleaning.cleaning_status === 'in_progress'" @click="cambiaEstado(true)" class="btn btn-primary bg-verde">
                                {{ translator.finalitzar }} 
                            </button>
                        </div>    

                        <div class="col mt-2">
                            <article class="card p-3">

                                <div v-if="cleaningDetail.property.lodging_name != ''" class="titular-card">
                                    <span>{{ translator.propietat }}:</span>
                                    <span>{{ cleaningDetail.property.lodging_name }}</span>
                                </div>
                                <div v-if="cleaningDetail.cleaning.cleaning_person != ''">
                                    <span>{{ translator.persona }}:</span>
                                    <span>{{ cleaningDetail.cleaning.cleaning_person }}</span>
                                </div>
                                <div v-if="cleaningDetail.cleaning.cleaning_status != ''">
                                    <span>{{ translator.estat }}:</span>
                                    <span v-if="cleaningDetail.cleaning.cleaning_status == 'pending'">{{ translator.pendent }}</span>
                                    <span v-else-if="cleaningDetail.cleaning.cleaning_status == 'in_progress'">{{ translator.enproces }}</span>
                                    <span v-else>{{ translator.acabat }}</span>
                                </div>
                                <div v-if="cleaningDetail.cleaning.cleaning_time != ''">
                                    <span>{{ translator.hora }}:</span>
                                    <span>{{ cleaningDetail.cleaning.cleaning_time }}h.</span>
                                </div>
                                <div v-if="cleaningDetail.cleaning.cleaning_remarks != ''">
                                    <span>{{ translator.observacions }}:</span>
                                    <span>{{ cleaningDetail.cleaning.cleaning_remarks }}</span>
                                </div>

                            </article>
                        </div>
                    </div>  

                    
                    <!-- Detalles de la check-out -->
                    <div class="row pb-4" 
                        v-if="cleaningDetail.departure.departure_reservation != '' ||
                            cleaningDetail.departure.departure_remarks != '' ||
                            cleaningDetail.departure.departure_cleaning_remarks != '' ||
                            cleaningDetail.departure.departure_time != ''
                    ">
                
                        <div class="col-12">
                            <h5>
                                <i class="fal fa-sign-out pr-1"></i>
                                <span v-cloak> {{ translator.checkout }}</span>
                            </h5>                
                        </div>
                    
                        <div class="col mt-2">
                            <article class="card p-3">

                                <div v-if="cleaningDetail.departure.departure_reservation != ''" class="titular-card">
                                    <span>{{ translator.reserva }}:</span>
                                    <span>{{ cleaningDetail.departure.departure_reservation }}</span>
                                </div>
                                <div v-if="cleaningDetail.departure.departure_guests != '0,0,0'">
                                    <span>{{ translator.persones }}:</span>
                                    <span v-html="showPersons(cleaningDetail.departure.departure_guests)"></span>
                                </div>
                                <div v-if="cleaningDetail.departure.departure_nights != '0'">
                                    <span v-if="cleaningDetail.departure.departure_nights == '1'">{{ translator.nit }}</span>
                                    <span v-else>{{ translator.nits }}</span>
                                    <span>{{ cleaningDetail.departure.departure_nights }}</span>
                                </div>
                                <div v-if="cleaningDetail.departure.departure_time != ''">
                                    <span>{{ translator.horasortida }}:</span>
                                    <span>{{ cleaningDetail.departure.departure_time }}h.</span>
                                </div>
                                <div v-if="cleaningDetail.departure.departure_remarks != ''">
                                    <span>{{ translator.observacions }}:</span>
                                    <span>{{ cleaningDetail.departure.departure_remarks }}</span>
                                </div>
                                <div v-if="cleaningDetail.departure.departure_cleaning_remarks != ''">
                                    <span>{{ translator.observacionsneteja }}:</span>
                                    <span>{{ cleaningDetail.departure.departure_cleaning_remarks }}</span>
                                </div>

                            </article>
                        </div>
                    </div>  
                    
                    <!-- Detalles de la check-in -->
                    <div class="row pb-4" 
                        v-if="cleaningDetail.arrival.arrival_cleaning_remarks != '' ||
                            cleaningDetail.arrival.arrival_guests != '0' ||
                            cleaningDetail.arrival.arrival_nights != '0' ||
                            cleaningDetail.arrival.arrival_remarks != '' ||
                            cleaningDetail.arrival.arrival_reservation != '' ||
                            cleaningDetail.arrival.arrival_time != ''
                    ">
                
                        <div class="col-12">
                            <h5>
                                <i class="fal fa-sign-in pr-1"></i>
                                <span v-cloak> {{ translator.checkin }}</span>
                            </h5>                
                        </div>
                    
                        <div class="col mt-2">
                            <article class="card p-3">

                                <div v-if="cleaningDetail.arrival.arrival_reservation != ''" class="titular-card">
                                    <span>{{ translator.reserva }}:</span>
                                    <span>{{ cleaningDetail.arrival.arrival_reservation }}</span>
                                </div>
                                <div v-if="cleaningDetail.arrival.arrival_guests != '0,0,0'">
                                    <span >{{ translator.persones }}:</span>
                                    <span v-html="showPersons(cleaningDetail.arrival.arrival_guests)"></span>
                                </div>
                                <div v-if="cleaningDetail.arrival.arrival_nights != '0'">
                                    <span v-if="cleaningDetail.arrival.arrival_nights == '1'">{{ translator.nit }}</span>
                                    <span v-else>{{ translator.nits }}</span>
                                    <span>{{ cleaningDetail.arrival.arrival_nights }}</span>
                                </div>
                                <div v-if="cleaningDetail.arrival.arrival_time != ''">
                                    <span>{{ translator.horaentrada }}:</span>
                                    <span>{{ cleaningDetail.arrival.arrival_time }}h.</span>
                                </div>
                                <div v-if="cleaningDetail.arrival.arrival_remarks != ''">
                                    <span>{{ translator.observacions }}:</span>
                                    <span>{{ cleaningDetail.arrival.arrival_remarks }}</span>
                                </div>
                                <div v-if="cleaningDetail.arrival.arrival_cleaning_remarks != ''">
                                    <span>{{ translator.observacionsneteja }}:</span>
                                    <span>{{ cleaningDetail.arrival.arrival_cleaning_remarks }}</span>
                                </div>

                            </article>
                        </div>
                    </div>  
                    
                    
                    <!-- Añadir incidencia -->
                    <div class="row pb-4">
                        <div class="col-12">
                            <h5>
                                <i class="fal fa-tools pr-1"></i>
                                <span v-cloak>{{ translator.afegirincidencia }}</span>
                            </h5>                
                        </div>
                    
                        <div class="col mt-2">
                            <div class="card p-3">
                                
                                <form>
                                    <div class="form-group">
                                        <label for="titular" class="mb-2">{{ translator.titular }}</label>
                                        <input v-model="formIncidencias.titular" type="text" id="titular" class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <label for="descripcion" class="mb-2">{{ translator.descripcio }}</label>
                                        <textarea v-model="formIncidencias.descripcion" type="text" id="descripcion" class="form-control"></textarea>
                                    </div>
                                    <div class="content-inputs">
                                        
                                        <div class="form-check">
                                            <input v-model="formIncidencias.causa" class="form-check-input" type="radio" name="radio-causa" id="desgaste"
                                                value="1">
                                            <label class="form-check-label" for="desgaste">{{ translator.desgast }}</label>
                                        </div>
                                        <div class="form-check">
                                            <input v-model="formIncidencias.causa" class="form-check-input" type="radio" name="radio-causa" id="malUso"
                                                value="2">
                                            <label class="form-check-label" for="malUso">{{ translator.malus }}</label>
                                        </div>
                                        

                                        <!-- Bóton guardar fotos -->
                                        <div class="row my-3">
                                            <div class="col mb-3">
                                                <div v-if="!hasImage1" class="empty-photo"></div>
                                                <image-uploader
                                                    id="image1"
                                                    :maxWidth="512"
                                                    :quality="0.9"
                                                    :autoRotate="false"
                                                    :className="['fileinput']"
                                                    class="text-center"
                                                    outputFormat="verbose"
                                                    :preview="true"
                                                    :capture="false"
                                                    accept="image/*"
                                                    doNotResize="['gif', 'svg']"
                                                    @onUpload="startImageResize(1)"
                                                    @input="savePhotoService"
                                                    @onComplete="endImageResize('image1')"
                                                >
                                                    <label for="image1" slot="upload-label">
                                                        <span class="btn btn-outline-secondary mt-1 btn-upload-photo" style="width: initial;">
                                                            {{ translator.pujarimatge }}
                                                        </span>
                                                    </label>
                                                </image-uploader>

                                            </div>
                                            <div class="col mb-3">
                                                <div v-if="!hasImage2" class="empty-photo"></div>
                                                <image-uploader
                                                    id="image2"
                                                    :maxWidth="512"
                                                    :quality="0.9"
                                                    :autoRotate="false"
                                                    :className="['fileinput']"
                                                    class="text-center"
                                                    outputFormat="verbose"
                                                    :preview="true"
                                                    :capture="false"
                                                    accept="image/*"
                                                    doNotResize="['gif', 'svg']"
                                                    @onUpload="startImageResize(2)"
                                                    @input="savePhotoService"
                                                    @onComplete="endImageResize('image2')"
                                                >
                                                    <label for="image2" slot="upload-label">
                                                        <span class="btn btn-outline-secondary mt-1 btn-upload-photo" style="width: initial;">
                                                            {{ translator.pujarimatge }}
                                                        </span>
                                                    </label>
                                                </image-uploader>

                                            </div>
                                            <div class="col">
                                                <div v-if="!hasImage3" class="empty-photo"></div>
                                                <image-uploader
                                                    id="image3"
                                                    :maxWidth="512"
                                                    :quality="0.9"
                                                    :autoRotate="false"
                                                    :className="['fileinput']"
                                                    class="text-center"
                                                    outputFormat="verbose"
                                                    :preview="true"
                                                    :capture="false"
                                                    accept="image/*"
                                                    doNotResize="['gif', 'svg']"
                                                    @onUpload="startImageResize(3)"
                                                    @input="savePhotoService"
                                                    @onComplete="endImageResize('image3')"
                                                >
                                                    <label for="image3" slot="upload-label">
                                                        <span class="btn btn-outline-secondary mt-1 btn-upload-photo" style="width: initial;">
                                                            {{ translator.pujarimatge }}
                                                        </span>
                                                    </label>
                                                </image-uploader>

                                            </div>
                                        </div>
                                    </div>


                                    <div v-if="formError.length > 0" class="py-3 form-error">
                                        <p v-for="(error, index) in formError" :key="index">{{ error }}</p>
                                    </div>

                                    <button class="btn btn-primary" @click.prevent.self="enviaform">
                                        {{ translator.guardar }}
                                    </button>

                                </form>
                            </div>
                        </div>
                    </div>  

                </div>
            </div>
        </main>

        <NavbarManager :translator="translator" />

    </div>
</template>

<script>
    import NavbarManager from '@/components/NavbarManager.vue';
    import { mapState } from "vuex";

    export default {
        name: "ManagerCleaningDetails",
        components: {
            NavbarManager,
        },
        computed: {
            ...mapState("utils", ['user', 'translator']),
            ...mapState("manager", ['cleaningDetail', 'numIncidencia']),
        },
        watch: {
            translator() {
                this.$store.dispatch("manager/getCleaningDetail", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), id: this.$route.params.bookingId, data: this.$route.params.cleaningDate });
            },
            cleaningDetail() {
                this.isPreLoad = false;
            },
            numIncidencia() {
                this.numIncidencia != '' ? this.serveiEnviarFotos() : '';
            }
        },
        async created() {
            try {
                this.isPreLoad = true;
                document.getElementsByTagName('body')[0].style.backgroundColor = '#f6f7f9';
                this.translator
                    ? await this.$store.dispatch("manager/getCleaningDetail", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), id: this.$route.params.bookingId, data: this.$route.params.cleaningDate })
                    : await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            } catch (error) {
                console.error(error);
            }
        },
        data() {
            return {
                isPreLoad: false,
                routeList: sessionStorage.getItem('listado'),
                formIncidencias: {
                    titular: '',
                    descripcion: '',
                    causa: ''
                },
                formError: [],
                servicePhotos: [],
                numPhoto: 1,
                hasImage1: false,
                hasImage2: false,
                hasImage3: false
            };
        },
        methods: {
            async cambiaEstado(estado) {
                this.isPreLoad = true;
                await this.$store.dispatch("manager/postCleaningStatus", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), id: this.$route.params.bookingId, final: estado, fecha: this.$route.params.cleaningDate, lat: 0, lng: 0 });
            },
            async enviaform() {
                try {
                    this.formError = [];
                    this.formIncidencias.titular == '' ? this.formError.push(`${this.translator.titular}`) : '';
                    this.formIncidencias.descripcion == '' ? this.formError.push(`${this.translator.descripcio}`) : '';
                    this.formIncidencias.causa == '' ? this.formError.push(`${this.translator.motiuincidencia}`) : '';

                    if (this.formError.length === 0) {
                        await this.$store.dispatch("manager/postIncidencia", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), lodging_id: this.cleaningDetail.property.lodging_id, titular: this.formIncidencias.titular, texte: this.formIncidencias.descripcion, motiu: parseInt(this.formIncidencias.causa) });
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            savePhotoService(photo) {
                this.image = photo;
                this.servicePhotos.push({
                        'img': this.numPhoto,
                        'foto': photo.dataUrl
                });
            },
            startImageResize(numPhoto) {
                this.numPhoto = numPhoto;
            },
            endImageResize(photo) {
                photo === 'image1' ? this.hasImage1 = true: '';
                photo === 'image2' ? this.hasImage2 = true: '';
                photo === 'image3' ? this.hasImage3 = true: '';
            },
            serveiEnviarFotos() {
                try {
                    this.isPreLoad = true;
                    let image1 = "", image2 = "", image3 = "";
                    
                    // Asignación botón con su número de foto
                    for (const value of this.servicePhotos) {
                        if (value.img === 1) {
                            image1 = value.foto;
                        } else if (value.img === 2) {
                            image2 = value.foto;
                        } else if (value.img === 3) {
                            image3 = value.foto;
                        }
                    }

                    // Envia info a la store para hacer el post
                    this.$store.dispatch( "manager/postNetejaEnviarFotos", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), id: this.numIncidencia, data: this.$route.params.cleaningDate, image1, image2, image3, idUrl: this.$route.params.bookingId });

                    // Reset y feedback
                    this.servicePhotos = [];
                    this.$toast.success(this.translator.guardat);
                    this.hasImage1 = this.hasImage2 = this.hasImage3 = false;
                    this.formIncidencias.titular = this.formIncidencias.descripcion = this.formIncidencias.causa = '';

                } catch (error) {
                    console.error(error);
                } finally {
                    setTimeout(()=>{
                        this.isPreLoad = false;
                    }, 7000);
                }
            },
            showPersons(data) {
                const persons = data.split(',');
                let adults = '', children = '', babies = '';

                if (persons[0] != '0') {
                    adults = persons[0] == '1' ?  `${persons[0]} ${this.translator.adult}` : `${persons[0]} ${this.translator.adults}`;
                }
                if (persons[1] != '0') {
                    children = persons[1] == '1' ?  `, ${persons[1]} ${this.translator.nen}` : `, ${persons[1]} ${this.translator.nens}`;
                }
                if (persons[2] != '0') {
                    babies = persons[2] == '1' ?  `, ${persons[2]} ${this.translator.nado}` : `, ${persons[2]} ${this.translator.bebes}`;
                }
                return `${adults}${children}${babies}`
            },
            TransformElegantDate(date) {
                if (!date) return ''
                
                const allMonths = JSON.parse(sessionStorage.getItem("diccionario")).monthShort;
                const allWeekdays = JSON.parse(sessionStorage.getItem("diccionario")).weekdayShort;
                const months = [
                    allMonths[0],
                    allMonths[1],
                    allMonths[2],
                    allMonths[3],
                    allMonths[4],
                    allMonths[5],
                    allMonths[6],
                    allMonths[7],
                    allMonths[8],
                    allMonths[9],
                    allMonths[10],
                    allMonths[11],
                ];
                const week = [
                    allWeekdays[0],
                    allWeekdays[1],
                    allWeekdays[2],
                    allWeekdays[3],
                    allWeekdays[4],
                    allWeekdays[5],
                    allWeekdays[6],
                ];

                let long_date = "";
                let day = date.slice(8, 10);
                let month = date.slice(5, 7);
                let year = date.slice(0, 4);

                let new_date = new Date(date);
                let day_week = new_date.getUTCDay();
                long_date = week[day_week] + ", " + day + " " + months[month - 1] + " " + year;

                return long_date;
            },
            routerLink() {
                this.$router.push({ name: this.routeList, query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') }})
            }
        }
    };
</script>


<style lang="scss" scoped>
    $color-verde-check: #70cb63;

    .container-nav-top {
        margin: 1rem auto;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .button-link {
            color: var(--azul-oscuro);
            text-decoration: none;
            font-size: 1.5rem;
            align-self: flex-start;
            &:hover {
                color: lighten($color: #0c2c40, $amount: 15);
            }
        }
    }

    .limpieza-detalle .titular-principal {
        cursor: pointer;
        &:hover {
            color: lighten($color: #0c2c40, $amount: 15);
            text-decoration: underline;
        }
    }

    .container {
        background-color: #f6f7f9;

        .bg-verde {
            border-color: var(--colores-verde-check);
            background-color: var(--colores-verde-check);
            transition: background-color .3s;
            &:hover {
                background-color: darken($color: $color-verde-check, $amount: 10%);
                transition: background-color .3s;
            }
        }

        article {
            div {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                
                span {
                    padding: 4px 0;
                }
            }
            .titular-card {
                border-bottom: 1px solid var(--colores-gris-medio);
                color: var(--colores-gris-medio);
            }
        }

        form {

            .content-inputs {

                .empty-photo {
                    width: 150px;
                    height: 100px;
                    margin: 0 auto .2rem auto;
                    border: 1px solid var(--colores-gris-medio);
                    border-radius: 5px;
                }
                label {
                    font-size: 16px;
                    color: var(--azul-oscuro);

                    .btn-upload-photo {
                        width: 150px !important;
                    }
                }

                .form-error {

                    p {
                        color: var(--red);
                    }
                }
            }
        }   
    }
</style>