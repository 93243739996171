<template>
    <div class="manager-booking-payment">

        <!-- Cabecera -->
        <header class="p-3">
            <h1 v-cloak>
                <routerLink
                    :to="{ name: 'ManagerBooking', params: { bookingId: booking.reservation }, query: { id: $route.query.id }}">
                    <span>
                        <i class="fal fa-chevron-left pr-1"></i>
                    </span>
                    {{ translator.pagament }}
                </routerLink>
            </h1>
        </header>
        
        <main class="container pc reserva">
            <div v-if="isPreLoad" class="preload">
                <div class="preload-wrapper"></div>
                <div class="preload-dots">
                    <div class="hollow-dots-spinner">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                </div>
            </div>

            <div v-if="booking.reservation" class="container-payment">

                <div v-if="bookingPayments.paid">
                    <div class="row pb-3">
                        <div class="col-6" style="padding-right: 5px;">
                            <div class="pagado">
                                <label v-cloak>{{ translator.pagat }}</label>
                                <span v-cloak>{{ bookingPayments.paid }} {{bookingPayments.currency}}</span>
                            </div>
                        </div>
                        <div class="col-6" style="padding-left: 5px;">
                            <div class="por-pagar">
                                <label v-cloak>{{ translator.perpagar }}</label>
                                <span :class="{'paiment-complete': (bookingPayments.pending_payment == 0) ? true : false}" v-cloak>{{ bookingPayments.pending_payment }} {{bookingPayments.currency}}</span>
                            </div>
                        </div>
                    </div>

                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th v-cloak>{{ translator.concepte }}</th>
                                <th class="text-right" v-cloak>{{ translator.import }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(extra, index) in bookingPayments.detail" :key="index">
                                <td class="pl-0" v-cloak>{{ extra.text }}</td>
                                <td class="quantitat pr-0">{{ extra.import }}</td>
                            </tr>
                            <tr>
                                <td class="pl-0" v-cloak>{{ translator.totalestada }}</td>
                                <td class="quantitat pr-0">{{ bookingPayments.stay_amount }}</td>
                            </tr>
                            <tr v-if="bookingPayments.cleaning_fee != 0" v-cloak>
                                <td class="pl-0">{{ translator.carrecneteja }}</td>
                                <td class="quantitat pr-0">{{ bookingPayments.cleaning_fee }}</td>
                            </tr>
                            <tr v-if="bookingPayments.booking_fee != 0" v-cloak>
                                <td class="pl-0">{{ translator.carrecgestio }}</td>
                                <td class="quantitat pr-0">{{ bookingPayments.booking_fee }}</td>
                            </tr>
                            <tr v-if="bookingPayments.checkin_fee != 0" v-cloak>
                                <td class="pl-0">{{ translator.carrecentrada }}</td>
                                <td class="quantitat pr-0">{{ bookingPayments.checkin_fee }}</td>
                            </tr>
                            <tr v-if="bookingPayments.shortstay_fee != 0" v-cloak>
                                <td class="pl-0">{{ translator.carreccurtaestada }}</td>
                                <td class="quantitat pr-0">{{ bookingPayments.shortstay_fee }}</td>
                            </tr>
                            <tr v-if="bookingPayments.earlybooking_discount != 0" v-cloak>
                                <td class="pl-0">{{ translator.descompteanticipat }}</td>
                                <td class="quantitat pr-0">{{ bookingPayments.earlybooking_discount }}</td>
                            </tr>
                            <tr v-if="bookingPayments.longstay_discount != 0" v-cloak>
                                <td class="pl-0">{{ translator.descomptellargaestada }}</td>
                                <td class="quantitat pr-0">{{ bookingPayments.longstay_discount }}</td>
                            </tr>
                            <tr v-if="bookingPayments.lastminute_discount != 0" v-cloak>
                                <td class="pl-0">{{ translator.descomptelastminute }}</td>
                                <td class="quantitat pr-0">{{ bookingPayments.lastminute_discount }}</td>
                            </tr>
                            <tr v-if="bookingPayments.standard_discount != 0" v-cloak>
                                <td class="pl-0">{{ translator.descompte }}</td>
                                <td class="quantitat pr-0">{{ bookingPayments.standard_discount }}</td>
                            </tr>
                            <tr v-if="bookingPayments.promotion_discount != 0" v-cloak>
                                <td class="pl-0">{{ translator.descomptepromocio }}</td>
                                <td class="quantitat pr-0">{{ bookingPayments.promotion_discount }}</td>
                            </tr>
                            <tr v-if="bookingPayments.tourist_tax != 0">
                                <td class="pl-0" v-cloak>{{ translator.taxaturistica }}</td>
                                <td class="quantitat pr-0" v-cloak>{{ bookingPayments.tourist_tax }}</td>
                            </tr>
                            <tr v-if="bookingPayments.total_amount != 0">
                                <td class="total pl-0" :class="{'correcto': (bookingPayments.pending_payment == 0) ? true : false}" v-cloak>{{ translator.total }}<i v-if="bookingPayments.pending_payment == 0" class="pl-1 fas fa-check-circle"></i></td>
                                <td class="total quantitat pr-0" :class="{'correcto': (bookingPayments.pending_payment == 0) ? true : false}" v-cloak>{{ bookingPayments.total_amount }}</td>
                            </tr>
                            <tr v-if="bookingPayments.security_deposit != 0" v-cloak>
                                <td class="total deposit pl-0" :class="{'correcto': (bookingPayments.deposit_paid == 'true') ? true : false}">{{ translator.diposit }} <i v-if="bookingPayments.deposit_paid == 'true'" class="pl-1 fas fa-check-circle"></i></td>
                                <td class="total quantitat pr-0" :class="{'correcto': (bookingPayments.deposit_paid == 'true') ? true : false}">{{ bookingPayments.security_deposit }}</td>
                            </tr>
                        </tbody>
                    </table>


                    <!-- Pago reserva -->
                    <section v-if="bookingPayments.pending_payment != 0">
                        <h4 class="mt-5 border-bottom pb-2 mb-2" style="font-size: 16px;">{{ translator.pagarreserva }}:</h4>
                        <div v-if="bookingPayments.credit_card_payment === 'true' && bookingPayments.pending_payment != 0">
                            <button class="btn" style="padding-left: 0; font-size: 14px;" @click.prevent.self="cardPayment(bookingPayments.pending_payment, false, false)" v-cloak>{{ translator.pertargeta }} <i class="fal fa-chevron-right fa-sm pl-1"></i></button>
                        </div>
                        <div v-if="bookingPayments.paypal_payment === 'true' && bookingPayments.pending_payment != 0">
                            <button class="btn" style="padding-left: 0; font-size: 14px;" @click.prevent.self="cardPayment(bookingPayments.pending_payment, false, true)" v-cloak>{{ translator.paypal }} <i class="fal fa-chevron-right fa-sm pl-1"></i></button>
                        </div>
                        <div v-if="bookingPayments.pending_payment != 0">
                            <a class="btn" style="padding-left: 0; font-size: 14px;" @click="openNav('reserva')" v-cloak>{{ translator.enefectiu }} / {{ translator.datafono }} <i class="fal fa-chevron-right fa-sm pl-1"></i></a>
                        </div>
                    </section>
                    <!-- Fin pago reserva -->

                    <!-- Pago depósito -->
                    <section v-if="bookingPayments.deposit_paid == 'false' && bookingPayments.security_deposit != 0">
                        <h4 class="mt-5 border-bottom pb-2 mb-2" style="font-size: 16px;">{{ translator.pagardiposit }}:</h4>
                        <div>
                            <button v-if="bookingPayments.credit_card_payment === 'true'" class="btn" style="padding-left: 0; font-size: 14px;"  @click.prevent.self="cardPayment(bookingPayments.security_deposit, true, false)" v-cloak>{{ translator.pertargeta }} <i class="fal fa-chevron-right fa-sm pl-1"></i></button>
                        </div>
                        <div v-if="bookingPayments.deposit_paid == 'false'">
                            <a class="btn" @click="openNav('deposito')" style="padding-left: 0; font-size: 14px;" v-cloak>{{ translator.enefectiu }} / {{ translator.datafono }} <i class="fal fa-chevron-right fa-sm pl-1"></i></a>
                        </div>
                    </section>
                    <!-- Fin pago depósito -->

                    <!-- Cargo entrada -->
                    <section v-if="!isDisabled">
                        <h4 class="mt-5 border-bottom pb-2 mb-2" style="font-size: 16px;">{{ translator.carrecentrada }}:</h4>
                        <div class="input-group mb-3">
                            <input type="number" v-model="arrivalFees" class="form-control" :placeholder="translator.carrecentrada" aria-describedby="cargo-entrada">
                            <div class="input-group-append">
                                <button :disabled="!arrivalFees" @click="postArrivalFees" class="btn btn-secondary" type="button" id="cargo-entrada">{{ translator.guardar }}</button>
                            </div>
                        </div>
                    </section>
                    <!-- Fin cargo entrada -->
                </div>

                <div id="cash" class="sidenav">
                    <header>
                        <h1 @click="closeNav" style="cursor: pointer;" v-cloak>
                            <i class="fal fa-chevron-left pr-1"></i>
                            {{ translator.enefectiu }}
                        </h1>
                    </header>
                    <div class="container">
                        <label for="date" class="pb-1" v-cloak>{{ translator.data }}</label>
                        <input id="date" type="date" v-model="formCash.date" class="form-control">

                        <div class="mt-4">
                            <div v-if="seccionPago == 'reserva'" class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" v-model="formCash.paymentRadioButton" id="reservaEfectivo" value="1">
                                <label class="form-check-label" for="reservaEfectivo">{{ translator.enefectiu }}</label>
                            </div>
                            <div v-if="seccionPago == 'reserva'" class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" v-model="formCash.paymentRadioButton" id="reservaDatafono" value="3">
                                <label class="form-check-label" for="reservaDatafono">{{ translator.datafono }}</label>
                            </div>
                            <div v-if="seccionPago == 'deposito'" class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" v-model="formCash.paymentRadioButton" id="depositoEfectivo" value="11">
                                <label class="form-check-label" for="depositoEfectivo">{{ translator.enefectiu }}</label>
                            </div>
                            <div v-if="seccionPago == 'deposito'" class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" v-model="formCash.paymentRadioButton" id="despositoDatafono" value="13">
                                <label class="form-check-label" for="despositoDatafono">{{ translator.datafono }}</label>
                            </div>
                        </div>

                        <label for="import" class="pt-3 pb-1" v-cloak>{{ translator.import }}</label>
                        <input v-if="seccionPago == 'reserva'" id="import" type="number" v-model="formCash.import" :placeholder="translator.perpagar + ' ' + bookingPayments.pending_payment" class="form-control">
                        <input v-else id="import" type="number" v-model="formCash.import" :placeholder="translator.perpagar + ' ' + bookingPayments.security_deposit" class="form-control">
                        <label class="pt-3 pb-1" for="remark">{{ translator.observacions }}</label>
                        <textarea id="remark" v-model="formCash.remark" rows="3" class="form-control"></textarea>
                        <button class="btn btn-secondary mt-3" v-on:click.prevent.self="cashPayment()" v-cloak>
                            {{ translator.afegirpagament }}
                        </button>
                    </div>
                </div>
            </div>
        </main>
        <NavbarManager :translator="translator" />
    </div>
</template>

<script>
    import NavbarManager from "@/components/NavbarManager.vue";
    import { mapState } from "vuex";

    export default {
        name: "ManagerBookingPayment",
        components: {
            NavbarManager,
        },
        computed: {
            ...mapState("utils", ["user", "translator"]),
            ...mapState("manager", ["booking", "bookingPayments"]),
        },

        data() {
            return {
                isPreLoad: false,
                isDisabled: false,
                cardPaymentHref: undefined,
                arrowContract: false,
                seccionPago: null,
                arrivalFees: null,
                formCash: {
                    date: this.moment(new Date()).format('yyyy-MM-DD'),
                    import: '',
                    paymentRadioButton: 1,
                    remark: ''
                },
            };
        },

        async created() {
            this.isPreLoad = true;
            try {
                if ( this.translator ) {
                    await this.$store.dispatch("manager/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId });
                    await this.$store.dispatch("manager/getBookingPayments", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId });
                } else {
                    await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                }
            } catch (error) {
                console.error(error);
            }
        },

        watch: {
            translator() {
                this.$store.dispatch("manager/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId });
                this.$store.dispatch("manager/getBookingPayments", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId });
            },
            booking() {
                this.isPreLoad = false;
                this.verifyCheckinCheckout();
            }
        },

        methods: {
            async cardPayment(pending_payment, diposit, paypal) {
                this.isPreLoad = true;
                try {
                    await this.$store.dispatch("manager/postCardPayment", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId, cobrar: parseFloat(pending_payment).toFixed(2), diposit, paypal });
                } catch (error) {
                    console.error(error);
                }
            },
            async cashPayment() {
                this.isPreLoad = true;
                try {
                    if (this.formCash.import.length > 0) {
                        await this.$store.dispatch("manager/postCashPayment", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId, importPagar: this.formCash.import, tipus: this.formCash.paymentRadioButton, data: this.formCash.date, observacions: this.formCash.remark });
                        this.$toast.success(this.translator.pagat);
                        this.closeNav();
                        this.formCash.import = this.formCash.remark = '';
                    } else {
                        this.$toast.error(this.translator.importobligatori);
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    this.isPreLoad = false;
                }
            },
            async postArrivalFees() {
                try {
                    await this.$store.dispatch("manager/postCheckinCarrec", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId, carrec: this.arrivalFees, ip: '' });
                    this.arrivalFees = null;
                } catch (error) {
                    console.error(error);
                }
            },
            verifyCheckinCheckout() {
                const formatYmd = (date) => date.toISOString().slice(0, 10);
                const compare = (a, b) => a.getTime() < b.getTime();

                parseInt(this.booking.status) >= 7 || compare(new Date(formatYmd(new Date(this.booking.departure_date))), new Date(formatYmd(new Date()))) 
                    ? this.isDisabled = true
                    : this.isDisabled = false;
                
            },
            openNav(seccion) {
                if (seccion === 'reserva') {
                    this.seccionPago = 'reserva'
                    this.formCash.paymentRadioButton = 1;
                } else {
                    this.seccionPago = 'deposito';
                    this.formCash.paymentRadioButton = 11;
                }
                document.getElementById("cash").style.width = "100%";
            },
            closeNav() {
                document.getElementById("cash").style.width = "0";
            }
        }
    }
</script>

<style lang="scss">
    .manager-booking-payment .container-nav-top {
        height: 50px;
        margin: 1rem auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a, h1 {
            color: var(--azul-oscuro);
            text-decoration: none;
            cursor: pointer;

            &:hover {
                color: lighten($color: #0c2c40, $amount: 15);
            }
        }
    }

    .manager-booking-payment {
        background-color: transparent;
    }
    .reserva .container-payment header {
        background: none;
    }
    .correcto {
        color: var(--colores-verde-check) !important;
    }

    .paiment-complete {
        color: var(--colores-gris-medio);
    }

    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 2;
        top: 0;
        right: 0;
        background-color: var(--white);
        overflow-x: hidden;
        transition: 0.5s;
    }

        .sidenav a {
            display: block;
        }

            .sidenav a:hover {
                color: #f1f1f1;
            }

    .paiment-complete {
        color: var(--colores-gris-medio);
    }

    @media screen and (max-height: 450px) {
        .sidenav {
            padding-top: 15px;
        }

            .sidenav a {
                font-size: 18px;
            }
    }
</style>