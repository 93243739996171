<template>
    <div class="calendario-service-manager">

        <!-- Cabecera -->
        <header class="p-3">
            <div class="row align-items-center">
                <div class="col">
                    <h1>
                <router-link :to="{ name: 'ManagerService', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') }, }">
                    <i class="fal fa-chevron-left"></i>
                </router-link>
                {{ translator.calendari }}
            </h1>
                </div>
                <div class="col text-right">
                    <span class="px-2 py-1 vista" :class="{ active: isCalendarWeek }">
                        <router-link class="btn-white" :to="{ name: 'ManagerService', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') }, }">
                            <i class="fal fa-list-ul"></i>
                        </router-link>
                    </span>
                    <span class="ml-2 px-2 py-1 vista" :class="{ active: !isCalendarWeek }"><i class="fal fa-calendar-alt"></i></span>                    
                </div>
            </div>
        </header>
        <main class="container">
            <div class="navegacion-semanas">
                <a class="p-1" href="#" @click="semanaAnterior">
                    <i class="fal fa-chevron-left"></i>
                </a>
                <div class="semana mx-3">{{ mostrarFecha }}</div>
                <a class="p-1" href="#" @click="semanaSiguiente">
                    <i class="fal fa-chevron-right"></i>
                </a>
            </div>
            <div>
                <!-- Spiner -->
                <div v-if="isPreLoad" style="height: 90vh; display:flex; justify-content:center; align-items:center;">
                    <div class="hollow-dots-spinner">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                </div>
                <!-- Fin spiner -->

                
                <div class="calendario-reservas table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="week-days"></tr>
                        </thead>
                        <tbody class="days-container"></tbody>
                    </table>
                </div>
                
            </div>
        </main>

        <NavbarManager :translator="translator" />

    </div>
</template>

<script>
    import NavbarManager from "@/components/NavbarManager.vue";
    import { mapState } from "vuex";

    export default {
        name: "ManagerServiceCalendar",

        components: {
            NavbarManager,
        },

        watch: {
            translator() {
                this.traductorDias = this.translator.weekdayShort;
                this.$store.dispatch("manager/getCalendar", { usr: this.$route.query.id, data: String(this.moment(new Date()).format("YYYY/MM/DD")) });
            },
            calendar() {
                this.calendarRows = this.calendar.rows;
                this.calendarItems = this.calendar.items;
                this.start();
                this.isPreLoad = false;
            }
        },

        data() {
            return {
                isPreLoad: false,
                calendarRows: {},
                calendarItems: {},
                traductorDias: '',
                rowsApartamentos: [],
                reservasApartamentos: [],
                numSemana: null,
                inicioSemana: null,
                finSemana: null,
                mostrarFecha: null,
                entrada: null,
                salida: null,
            };
        },

        computed: {
            ...mapState("utils", ["user", "translator"]),
            ...mapState("manager", ["calendar"]),
        },

        async created() {
            try {
                this.isPreLoad = true;
                if (this.translator) {
                    this.traductorDias = this.translator.weekdayShort;
                    await this.$store.dispatch("manager/getCalendar", { usr: this.$route.query.id, data: String(this.moment(new Date()).format("YYYY/MM/DD")) });
                } else {
                    await this.$store.dispatch("utils/getUser", { usr: this.$route.query.id });
                }
            } catch (error) {
                console.error(error);
            }
        },

        methods: {
            start() {
                // Recorro objeto y creo array con id y nombre
                Object.entries(this.calendarRows).forEach(([, value]) => {
                    let row = {
                        id: value.rowId,
                        label: value.label,
                    };

                    this.rowsApartamentos.push(row);
                });

                Object.entries(this.calendarItems).forEach(([, value]) => {
                    let reserva = {
                        diaEntrada: value.time.start,
                        diaSalida: value.time.end,
                        numReserva: value.label,
                        rowId: value.rowId,
                    };

                    this.reservasApartamentos.push(reserva);
                });

                console.log("reservas", this.reservasApartamentos);
                this.numSemana = this.moment();
                this.inicioSemana = this.moment(this.numSemana).startOf("isoWeek");
                this.finSemana = this.moment(this.numSemana).endOf("isoWeek");
                this.mostrarFecha = `${this.inicioSemana.format("DD")} ${this.translator.monthShort[this.inicioSemana.format("M") - 1]} - ${this.finSemana.format(
                    "DD")} ${this.translator.monthShort[this.finSemana.format("M") - 1]}`;

                this.reparteDias();
            },

            //Pinto los días
            reparteDias() {
                // Cojo selectores
                let selectorDias = document.querySelector(".week-days");
                // Borro TDs
                document.querySelectorAll(".week-days td").forEach((node) => {
                    node.parentNode.removeChild(node);
                });
                // Muestro 7 días
                for (let i = 1; i <= 8; i++) {
                    //const th = document.createElement("th");
                    const td = document.createElement("td");

                    if (i === 1) {
                        // Relleno con un td vacio
                        td.className = "td-vacio";
                        selectorDias.appendChild(td);
                    } else {
                        if (i === 8) {
                            //Domingo
                            td.innerHTML = `<div>${this.inicioSemana.format("DD")}</div> ${this.traductorDias[0]
                                }`;
                        } else {
                            td.innerHTML = `<div>${this.inicioSemana.format("DD")}</div> ${this.traductorDias[i - 1]
                                }`;
                        }
                        td.className = "dia";
                        selectorDias.appendChild(td);
                        this.inicioSemana = this.inicioSemana.add(1, "days");
                    }
                }
                this.reparteReservas();
            },

            // Pinto las reservas
            reparteReservas() {
                let selectorResrvas = document.querySelector(".days-container");
                // Borro TRs
                document.querySelectorAll(".days-container tr").forEach((node) => {
                    node.parentNode.removeChild(node);
                });

                // Creo una fila por apartamento
                for (let i = 0; i < this.rowsApartamentos.length; i++) {
                    let pasaDia = this.moment(this.numSemana).startOf("isoWeek");
                    const tr = document.createElement("tr");

                    let reservaArray = [];

                    // Creo array para pintar las reservas
                    for (let y = 1; y <= 7; y++) {
                        let flag;
                        this.reservasApartamentos.forEach((reserva) => {
                            if (
                                !reservaArray[y - 1] ||
                                reservaArray[y - 1] == "dia-libre" ||
                                reservaArray[y - 1] == "salida"
                            ) {
                                if (reserva.rowId === this.rowsApartamentos[i].id) {
                                    if (reservaArray[y - 1] == "dia-libre") flag = true;

                                    if (
                                        reservaArray[y - 1] == "salida" &&
                                        this.moment(pasaDia).isSame(this.moment(reserva.diaEntrada))
                                    ) {
                                        reservaArray[y - 1] = "inclinacion," + reserva.numReserva;
                                    } else if (
                                        this.moment(pasaDia).isSame(this.moment(reserva.diaEntrada))
                                    ) {
                                        flag
                                            ? (reservaArray[y - 1] = "entrada," + reserva.numReserva)
                                            : reservaArray.push("entrada," + reserva.numReserva);
                                    } else if (
                                        this.moment(pasaDia).isSame(this.moment(reserva.diaSalida))
                                    ) {
                                        flag
                                            ? (reservaArray[y - 1] = "salida")
                                            : reservaArray.push("salida");
                                    } else if (
                                        parseInt(this.moment(pasaDia).format("x")) <
                                        parseInt(this.moment(reserva.diaSalida).format("x")) &&
                                        parseInt(this.moment(pasaDia).format("x")) >
                                        parseInt(this.moment(reserva.diaEntrada).format("x"))
                                    ) {
                                        flag
                                            ? (reservaArray[y - 1] = "dia-reservado")
                                            : reservaArray.push("dia-reservado");
                                    } else if (
                                        parseInt(this.moment(pasaDia).format("x")) >
                                        parseInt(this.moment(reserva.diaSalida).format("x")) ||
                                        parseInt(this.moment(pasaDia).format("x")) <
                                        parseInt(this.moment(reserva.diaEntrada).format("x"))
                                    ) {
                                        flag
                                            ? (reservaArray[y - 1] = "dia-libre")
                                            : reservaArray.push("dia-libre");
                                    }
                                }
                            }
                        });

                        pasaDia.add(1, "d");
                    }

                    pasaDia.add(-7, "d");

                    //creo 8 columnas nombre + días
                    for (let x = 1; x <= 8; x++) {
                        // Pinto los nombres
                        if (x === 1) {
                            const th = document.createElement("th");
                            const div = document.createElement("div");
                            const contenidoDiv = document.createTextNode(
                                this.rowsApartamentos[i].label
                            );
                            div.appendChild(contenidoDiv);
                            th.appendChild(div);
                            div.className = "table-cell-inner";
                            th.setAttribute("scope", "row");
                            tr.appendChild(th);
                        } else {
                            //Pintara las reservas
                            const td = document.createElement("td");
                            const divReservaI = document.createElement("div");
                            const divReservaNumero = document.createElement("div");
                            const divBg = document.createElement("div");
                            const divSalidaEntrada1 = document.createElement("div");
                            const divSalidaEntrada2 = document.createElement("div");
                            const divContenedorNumeros = document.createElement("div");
                            const a = document.createElement("a");
                            const i = document.createElement("i");

                            const enlace = `/manager-service-calendar-info/${reservaArray[x - 2].split(",")[1]}?id=${this.$route.query.id}`;

                            switch (reservaArray[x - 2].split(",")[0]) {
                                case "entrada":
                                    td.className = "entrada-reserva";
                                    divReservaI.className = "entrada-reserva-i";
                                    divReservaNumero.className = "reserva-numero";
                                    a.setAttribute("href", enlace);
                                    i.className = "fas fa-sign-in-alt";
                                    a.appendChild(i);
                                    divReservaNumero.appendChild(a);
                                    divReservaI.appendChild(divReservaNumero);
                                    td.appendChild(divReservaI);
                                    tr.appendChild(td);
                                    break;

                                case "salida":
                                    td.className = "salida-reserva";
                                    divReservaI.className = "salida-reserva-i";
                                    td.appendChild(divReservaI);
                                    tr.appendChild(td);
                                    break;

                                case "dia-reservado":
                                    td.className = "dia-reservado";
                                    tr.appendChild(td);
                                    break;

                                case "inclinacion":
                                    td.className = "salida-entrada";
                                    divBg.className = "bg";
                                    divSalidaEntrada1.className = "salida-entrada-1";
                                    divBg.appendChild(divSalidaEntrada1);
                                    divSalidaEntrada2.className = "salida-entrada-2";
                                    divContenedorNumeros.className = "contenedor-numero";
                                    divReservaNumero.className = "reserva-numero";
                                    a.setAttribute("href", enlace);
                                    i.className = "fas fa-sign-in-alt";
                                    a.appendChild(i);
                                    divReservaNumero.appendChild(a);
                                    divContenedorNumeros.appendChild(divReservaNumero);
                                    divSalidaEntrada2.appendChild(divContenedorNumeros);
                                    divBg.appendChild(divSalidaEntrada2);

                                    td.appendChild(divBg);
                                    tr.appendChild(td);
                                    break;

                                default:
                                    td.className = "dia-libre";
                                    tr.appendChild(td);
                                    break;
                            }
                        }
                    }

                    selectorResrvas.appendChild(tr);
                }
            },

            semanaAnterior() {
                this.numSemana = this.moment(this.numSemana).add(-1, "w");
                this.inicioSemana = this.moment(this.numSemana).startOf("isoWeek");
                this.finSemana = this.moment(this.numSemana).endOf("isoWeek");
                this.mostrarFecha = `${this.inicioSemana.format("DD")} ${this.translator.monthShort[this.inicioSemana.format("M") - 1]} - ${this.finSemana.format("DD")} ${this.translator.monthShort[this.finSemana.format("M") - 1]}`;

                this.reparteDias();

            },

            semanaSiguiente() {
                this.numSemana = this.moment(this.numSemana).add(1, "w");
                this.inicioSemana = this.moment(this.numSemana).startOf("isoWeek");
                this.finSemana = this.moment(this.numSemana).endOf("isoWeek");
                this.mostrarFecha = `${this.inicioSemana.format("DD")} ${this.translator.monthShort[this.inicioSemana.format("M") - 1]} - ${this.finSemana.format("DD")} ${this.translator.monthShort[this.finSemana.format("M") - 1]}`;

                this.reparteDias();
            }
        }
    };

</script>

<style lang="scss"> 
    .calendario-service-manager .container-nav-top {
        margin: 1rem auto;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            text-decoration: none;
            font-size: 1.5rem;
            align-self: flex-start;
            &:hover {
                color: lighten($color: #0c2c40, $amount: 15);
            }
        }
        .semana, a {
            color: var(--azul-oscuro);
        }
    }

    header .flex-titular {
        display: flex;
        justify-content: space-between;
        margin: auto;
    }

    .calendario-service-manager table {
        width: 99.5%;
    }
    .calendario-service-manager img {
        width: 20px;
        height: 20px;
    }
    .calendario-service-manager .salida-entrada-1:after {
        content: none;
    }
    .calendario-service-manager .btn-white {
        color: #FFF;
    }
</style>