<template>
    <div class="manager-booking-guests">
        <!-- Cabecera -->
        <header class="p-3">
            <div class="row align-items-center">
                <div class="col">
                    <h1>
                    <router-link :to="{ name: 'ManagerBooking', params: { bookingId: booking.reservation }, query: { id: $route.query.id }}">
                        <span><i class="fal fa-chevron-left pr-1"></i></span>
                        <span v-cloak> {{ translator.hostes }}</span>
                    </router-link>
                </h1>
                </div>
                <div class="col">
                    <div>
                    {{ booking.registered_guests }}/{{ booking.sumAdultsChildren }}
                    <span v-cloak>{{ translator.registrats }}</span>
                    </div>                    
                </div>
            </div>            
        </header>
        <main class="container pc reserva booking-guests">

            <div v-if="isPreLoad" class="preload">
                <div class="preload-wrapper"></div>
                <div class="preload-dots">
                    <div class="hollow-dots-spinner">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                </div>
            </div>

            <div v-if="booking.registered_guests">
                <div class="p-3 border mb-3 rounded" v-for="(guest, index) in bookingGuests" :key="index" v-cloak>
                    <div class="nombre-huesped row pb-2" :id="'label' + guest.guest_id">
                        <div class="col-8">
                            {{ guest.guest_first_name }} {{ guest.guest_last_name_one }}
                            {{ guest.guest_last_name_two }}
                        </div>
                        <div class="col-4">
                            <span v-if="guest._input_empty"
                                class="rounded-circle bg-warning px-2 mr-2">!</span>
                            <i v-if="!isDisabled" class="fal fa-user-edit" @click="openAddEditGuest(guest)" style="cursor: pointer; margin-right: 1rem;"></i>
                            <i v-if="!isDisabled" class="fal fa-trash-alt" @click="deleteGuest(guest)" style="cursor:pointer;"></i>
                        </div>
                    </div>
                    <div :id="guest.guest_id">
                        <div v-if="format != 'PT'" class="row">
                            <div class="col-8">
                                <label>{{ translator.genere }}</label>
                            </div>
                            <div class="col-4 info">
                                <span v-if="guest.guest_sex === 'F'">{{ translator.femeni }}</span>
                                <span v-if="guest.guest_sex === 'M'">{{ translator.masculi }}</span>
                            </div>
                        </div>
                        <div v-if="format === 'IT'" class="row">
                            <div class="col-8">
                                <label>{{ translator.tipus }}</label>
                            </div>
                            <div class="col-4">
                                <span v-if="guest.guest_type === '1'">OSPITE SINGOLO</span>
                                <span v-if="guest.guest_type === '2'">CAPOFAMIGLIA</span>
                                <span v-if="guest.guest_type === '3'">CAPOGRUPPO</span>
                                <span v-if="guest.guest_type === '4'">FAMILIARE</span>
                                <span v-if="guest.guest_type === '5'">MEMBRO GRUPPO</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <label>{{ translator.datanaixement }}</label>
                            </div>
                            <div class="col-4 info">
                                {{ guest.guest_birthday_date | transformDate }}
                            </div>
                        </div>
                        <div v-if="format === 'PT' || format === 'IT'" class="row">
                            <div class="col-8">
                                <label>{{ translator.paisnaixement }}</label>
                            </div>
                            <div class="col-4">
                                {{ guest.guest_birthday_country }}
                            </div>
                        </div>
                        <div v-if="format === 'IT' && formAddGuest.selectedCountryBirth == 'IT'" class="row">
                            <div class="col-8">
                                <label>{{ translator.poblenaixement }}</label>
                            </div>
                            <div class="col-4">
                                {{ guest.guest_birthday_place }}
                            </div>
                        </div>
                        <div v-if="format === 'IT' && formAddGuest.selectedCountryBirth == 'IT'" class="row">
                            <div class="col-8">
                                <label>{{ translator.regio }}</label>
                            </div>
                            <div class="col-4">
                                {{ guest.guest_birthday_region }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <label>{{ translator.dnipassaport }}</label>
                            </div>
                            <div class="col-4 info">
                                {{ guest.document_number }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <label>{{ translator.tipusdocument }}</label>
                            </div>
                            <div class="col-4 info">
                                {{ guest.document_type }}
                            </div>
                        </div>
                        <div v-if="format === 'ES' || format === 'AD' || format === 'AE'" class="row">
                            <div class="col-8">
                                <label>{{ translator.dataexpedicio }}</label>
                            </div>
                            <div class="col-4 info">
                                {{ guest.document_date | transformDate }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <label>{{ translator.nacionalitat }}</label>
                            </div>
                            <div class="col-4 info">
                                {{ guest.document_country }}
                            </div>
                        </div>
                        <div v-if="format === 'IT'" class="row">
                            <div class="col-8">
                                <label>{{ translator.poble }}</label>
                            </div>
                            <div class="col-4">
                                {{ guest.document_place }}
                            </div>
                        </div>
                        <div v-if="format === 'PT' || format === 'AD' || format === 'AE'" class="row">
                            <div class="col-8">
                                <label>{{ translator.paisresidencia }}</label>
                            </div>
                            <div class="col-4">
                                {{ guest.address_country }}
                            </div>
                        </div>
                        <div v-if="format === 'AD'" class="row">
                            <div class="col-8">
                                <label>{{ translator.pais }}</label>
                            </div>
                            <div class="col-4">
                                {{ guest.address_place }}
                            </div>
                        </div>
                        <div v-if="format === 'AD'" class="row">
                            <div class="col-8">
                                <label>{{ translator.dip }}</label>
                            </div>
                            <div class="col-4">
                                {{ guest.address_zip }}
                            </div>
                        </div>
                        <!-- <div v-if="guest.guest_signature != ''" class="row">
                        <div class="col-8">
                            <label>{{ translator.signaturaclient }}</label>
                        </div>
                        <div class="col-4">
                            <img width="100%" :src="guest.guest_signature" />
                        </div>
                        </div> -->

                        <table class="table table-sm mt-4 mb-0">
                            <tr>
                                <!-- Botón Documento -->
                                <td v-if="booking.visible_passport == 'true'" style="width: 50%;">
                                    <span v-if="guest.document_country === 'ES'">
                                    {{ translator.revers }}:
                                </span>
                                <span v-else>
                                    {{ translator.document }}:
                                </span>
                                    <label v-if="!isDisabled" for="fileDocument" slot="upload-label" @click="saveUserId(guest, 1)" class="btn btn-outline-secondary" style="padding: 0; width: 24px; font-size: 12px; float: right;">
                                        <i class="fal fa-plus"></i>
                                    </label>
                                </td>
                                <!-- Botón anverso -->
                                <td v-if="booking.visible_selfie == 'true' && guest.document_country == 'ES'" style="width: 50%;">
                                    {{ translator.anvers }}:
                                    <label v-if="!isDisabled" for="fileAnvers" slot="upload-label" @click="saveUserId(guest, 2)" class="btn btn-outline-secondary" style="padding: 0; width: 24px; font-size: 12px; float: right;">
                                        <i class="fal fa-plus"></i>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <!-- Foto documento -->
                                <td v-if="booking.visible_passport == 'true'">
                                    <image-uploader
                                        id="fileDocument"
                                        :debug="1"
                                        :maxWidth="1024"
                                        :quality="0.9"
                                        :autoRotate="false"
                                        :className="['fileinput']"
                                        outputFormat="verbose"
                                        :preview="false"
                                        :capture="false"
                                        accept="image/*"
                                        doNotResize="['gif', 'svg']"
                                        @input="savePhotoFrontPassport"
                                    >
                                        <!-- <span class="upload-caption">{{ hasImageDocument ? 'Replace' : 'Upload' }}</span> -->
                                    </image-uploader>
                                    <img v-if="!hasImageDocument" class="img-fluid" :src="guest.document_image">
                                </td>
                                <!-- Foto Anverso -->
                                <td v-if="booking.visible_selfie == 'true' && guest.document_country == 'ES'">
                                    <image-uploader
                                        id="fileAnvers"
                                        :debug="1"
                                        :maxWidth="1024"
                                        :quality="0.9"
                                        :autoRotate="false"
                                        :className="['fileinput', { 'fileinput--loaded' : hasImage }]"
                                        outputFormat="verbose"
                                        :preview="false"
                                        :capture="false"
                                        accept="image/*"
                                        doNotResize="['gif', 'svg']"
                                        @input="savePhotoFrontPassport"
                                    >
                                        <!-- <span class="upload-caption">{{ hasImage ? 'Replace' : 'Upload' }}</span> -->
                                    </image-uploader>
                                    <img v-if="!hasImage" class="img-fluid" :src="guest.document_photo">
                                </td>
                            </tr>
                            <tr>
                                <!-- Botón selfie -->
                                <td v-if="booking.visible_selfie == 'true'">
                                    {{ translator.selfie }}:
                                    <label v-if="!isDisabled" for="fileSelfie" slot="upload-label" @click="saveUserId(guest)" class="btn btn-outline-secondary" style="padding: 0; width: 24px; font-size: 12px; float: right;">
                                        <i class="fal fa-plus"></i>
                                    </label>
                                </td>

                                <!-- Botón firma -->
                                <td>
                                    {{ translator.signatura }}:
                                    <button v-if="!isDisabled" @click="signModal(guest)" class="btn btn-outline-secondary btn-sm" style="padding: 0; width: 24px; font-size: 12px; float: right;">
                                        <i class="fal fa-plus"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td v-if="booking.visible_selfie == 'true'">
                                    <image-uploader 
                                        id="fileSelfie"
                                        :debug="1"
                                        :maxWidth="1024"
                                        :quality="0.9"
                                        :autoRotate="false"
                                        :className="['fileinput']"
                                        outputFormat="verbose"
                                        :preview="false"
                                        :capture="false"
                                        accept="image/*"
                                        doNotResize="['gif', 'svg']"
                                        @input="saveSelfie"
                                    >
                                        <!-- <span class="upload-caption">{{ hasImageSelfie ? 'Replace' : 'Upload' }}</span> -->
                                    </image-uploader>
                                    <img v-if="!hasImageSelfie" class="img-fluid" :src="guest.guest_photo">
                                </td>
                                <td>
                                    <img v-if="guest.guest_signature" class="img-fluid" :src="guest.guest_signature" />
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <!-- Modal Firma -->
                <div class="modal fade"
                    id="Modal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="ModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="ModalLabel" v-cloak>
                                    {{ translator.signar }}
                                </h5>
                                <button type="button"
                                        class="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        @click="clearCanvas()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body text-center">
                                <canvas id="sketchpad"
                                        height="200"
                                        width="300"
                                        style="border: 1px solid #ccc"></canvas>
                            </div>
                            <div class="modal-footer">
                                <button type="button"
                                        class="btn btn-outline-secondary"
                                        @click="clearCanvas()"
                                        v-cloak>
                                    {{ translator.esborrar }}
                                </button>
                                <button type="button"
                                        class="btn btn-secondary"
                                        :class="{'disabled': isCanvasClear}"
                                        @click="saveCanvasGuest()"
                                        v-cloak>
                                    {{ translator.guardar }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin modal Firma -->

                <div v-if="booking.registered_guests < booking.sumAdultsChildren" v-cloak>

                    <!-- Ajuda + botó per escanejar document -->
                    <div v-if="booking.scanner_passport == 'true'" class="d-lg-none" style="border: solid 1px var(--colores-azul-claro); border-radius: 4px; padding: 10px;">
                        <div class="row mb-2">
                            <div class="col-6">
                                <img src="./../../../../public/img/dni.jpg" class="img-fluid" style="padding-top: 5px;">
                            </div>
                            <div class="col-6">
                                <small>{{ translator.instruccionsfoto }}</small>
                            </div>
                        </div>

                        <!-- Lectura DNI -->
                        <!-- Anyline SDK -->
                        <button v-if="isReadAnylineSDK" class="btn btn-secondary mt-1 d-lg-none" @click="openAnyline">{{ translator.escanejardocument }} <i class="fal fa-camera fa-sm ml-2"></i></button>
                        <!-- <pre id="display1" style="padding-bottom:30vh;" >{{ display1 }}</pre>
                        <pre id="display" style="padding-bottom:30vh;" >{{ display }}</pre> -->
                        <div v-if="isReadAnylineSDK" :class="show_sidebar ? 'side-navbar-custom open' : 'side-navbar-custom'">
                            <div @click="closeAnyline" class="anyline-close">&#10006;</div>
                            <div id="anyline"></div>
                        </div>
                        <!-- Fin Anyline SDK -->

                        <!-- Anyline webservice -->
                        <image-uploader v-show="!isReadAnylineSDK"
                                        :debug="1"
                                        :maxWidth="1024"
                                        :quality="0.9"
                                        :autoRotate="false"
                                        :className="['fileinput', { 'fileinput--loaded' : hasImage }]"
                                        outputFormat="verbose"
                                        :preview="false"
                                        :capture="false"
                                        accept="image/*"
                                        doNotResize="['gif', 'svg']"
                                        @input="savePhoto">
                            <label for="fileInput" slot="upload-label">
                                <span class="btn btn-secondary mt-1 d-lg-none">
                                    {{ translator.escanejardocument }} <i class="fal fa-camera fa-sm ml-2"></i>
                                </span>
                            </label>
                        </image-uploader>
                        <!-- Fin Lectura DNI -->
                    </div>

                    <!-- Botó per afegir manualment hoste-->
                    <a class="btn btn-secondary mt-3"
                    @click.prevent.self="openAddEditGuest()"
                    v-cloak>{{ translator.afegirhoste }} <i class="fal fa-edit fa-sm ml-2"></i></a>
                </div>
            </div>


            <!-- Ventana añadir huésped -->
            <div id="newGuest" class="sidenav">
                <header class="p-3 border-bottom">
                    <h1 @click="cleanFormGuest" v-cloak>
                        <i class="fal fa-chevron-left"></i>
                        {{ translator.afegirhoste }}
                    </h1>
                </header>
                <div class="container">
                    <div>
                        <label for="name" class="pb-1" v-cloak>{{ translator.nom }}</label>
                        <input id="name"
                            type="text"
                            v-model="formAddGuest.name"
                            autocomplete="off"
                            class="form-control" />
                    </div>
                    <div>
                        <label for="surname" class="pt-3 pb-1" v-cloak>
                            {{ translator.cognoms }}
                        </label>
                        <input id="surname"
                            type="text"
                            v-model="formAddGuest.surname"
                            autocomplete="off"
                            class="form-control" />
                    </div>

                    <!-- <div v-if="format === 'ES'">
                        <label for="secondSurname" class="pt-3 pb-1" v-cloak>
                            {{ translator.segoncognom }}
                        </label>
                        <input id="secondSurname"
                            type="text"
                            v-model="formAddGuest.secondSurname"
                            autocomplete="off"
                            class="form-control" />
                    </div> -->

                    <div v-if="format != 'PT'">
                        <label for="gender" class="pt-3 pb-1" v-cloak>
                            {{ translator.genere }}
                        </label>
                        <div style="display: flex; justify-content: space-around">
                            <div style="display: flex; align-items: center">
                                <input type="radio"
                                    id="male"
                                    value="M"
                                    v-model="formAddGuest.gender" />
                                <label for="male" class="ml-1" v-cloak>
                                    {{ translator.masculi }}
                                </label>
                            </div>
                            <div style="display: flex; align-items: center">
                                <input type="radio"
                                    id="female"
                                    value="F"
                                    v-model="formAddGuest.gender" />
                                <label for="female" class="ml-1" v-cloak>
                                    {{ translator.femeni }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <!-- <div v-if="format === 'IT'">
                        <label for="typePerson" class="pt-3 pb-1" v-cloak>
                            {{ translator.tipus }}
                        </label>
                        <select v-model="formAddGuest.typePerson" class="form-control">
                            <option value="1">OSPITE SINGOLO</option>
                            <option value="2">CAPOFAMIGLIA</option>
                            <option value="3">CAPOGRUPPO</option>
                            <option value="4">FAMILIARE</option>
                            <option value="5">MEMBRO GRUPPO</option>
                        </select>
                    </div> -->

                    <div>
                        <label for="birthDate" class="pt-3 pb-1" v-cloak>
                            {{ translator.datanaixement }}
                        </label>
                        <input id="birthDate"
                            type="date"
                            v-model="formAddGuest.birthDate"
                            autocomplete="off"
                            class="form-control"
                            placeholder="dd/mm/yyyyy"
                            :max="dateNow" />
                    </div>

                    <!-- País naixement (Italia i Portugal) -->
                    <div v-if="format === 'PT' || format === 'IT'">
                        <label for="countryBirth" class="pt-3 pb-1" v-cloak>
                            {{ translator.paisnaixement }}
                        </label>
                        <v-select :options="countries"
                                :clearable="false"
                                :reduce="(name) => name.alpha2Code"
                                label="name"
                                v-model="formAddGuest.selectedCountryBirth"></v-select>
                    </div>

                    <!-- Població naixement (Italia i Portugal) -->
                    <div v-if="format === 'IT' && formAddGuest.selectedCountryBirth == 'IT'">
                        <label for="cityBirth" class="pt-3 pb-1" v-cloak>
                            {{ translator.poblenaixement }}
                        </label>
                        <v-select v-if="format === 'IT'"
                                :options="itDocumentCity"
                                :reduce="(name) => name.city_id"
                                label="city_name"
                                v-model="formAddGuest.cityBirth"></v-select>
                    </div>

                    <!-- Provincia naixement (Italia) -->
                    <div v-if="format === 'IT' && formAddGuest.selectedCountryBirth == 'IT'">
                        <label for="regionBirth" class="pt-3 pb-1" v-cloak>
                            {{ translator.regionaixement }}
                        </label>
                        <v-select :options="itRegionBirth"
                                :reduce="(name) => name.region_id"
                                label="region_name"
                                v-model="formAddGuest.selectedRegionBirth"
                        ></v-select>
                    </div>

                    <div>

                        <!-- Tipus document -->
                        <label for="typeDocument" class="pt-3 pb-1" v-cloak>
                            {{ translator.tipusdocument }}
                        </label>
                        <select id="typeDocument"
                                v-model="formAddGuest.typeDocument"
                                class="form-control">
                            <option value="D">{{ translator.dni }}</option>
                            <option value="P">{{ translator.passaport }}</option>
                        </select>
                    </div>

                    <div>
                        <!-- Número document -->
                        <label for="number_document" class="pt-3 pb-1" v-cloak>
                            {{ translator.dnipassaport }}
                        </label>
                        <input id="number_document"
                            type="text"
                            v-model="formAddGuest.numberDocument"
                            autocomplete="off"
                            class="form-control" />
                    </div>

                    <!-- Data expedició (Espanya, Andorra y Emiratos Árabes ) -->
                    <div v-if="format === 'ES' || format === 'AD' || format === 'AE'">
                        <label for="expiration_document" class="pt-3 pb-1" v-cloak>
                            {{ translator.dataexpedicio }}
                        </label>
                        <input id="expiration_document"
                            type="date"
                            v-model="formAddGuest.dateDocument"
                            autocomplete="off"
                            class="form-control"
                            placeholder="dd/mm/yyyy" 
                        />
                    </div>

                    <div>
                        <!-- País / nacionalitat -->
                        <label for="country" class="pt-3 pb-1" v-cloak>
                            {{ translator.nacionalitat }}
                        </label>
                        <v-select :options="countries"
                                :clearable="false"
                                :reduce="(name) => name.alpha2Code"
                                label="name"
                                v-model="formAddGuest.selectedCountryDocument"></v-select>
                    </div>

                    <!-- Poble d'expedició (Italia) -->
                    <div v-if="format === 'IT'">
                        <label for="cityDocument" class="pt-3 pb-1" v-cloak>
                            {{ translator.llocexpedicio }}
                        </label>
                        <v-select v-if="formAddGuest.selectedCountryDocument == 'IT'"
                                :options="itDocumentCity"
                                :reduce="(name) => name.city_id"
                                label="city_name"
                                v-model="formAddGuest.cityDocument"></v-select>
                        <input v-else
                            id="cityDocument"
                            type="text"
                            v-model="formAddGuest.cityDocument"
                            autocomplete="off"
                            class="form-control" />
                    </div>

                    <div v-if="format === 'AD' || format === 'PT'">
                        <label for="countryAdress" class="pt-3 pb-1" v-cloak>
                            {{ translator.paisresidencia }}
                        </label>
                        <v-select :options="countries"
                                :clearable="false"
                                :reduce="(name) => name.alpha2Code"
                                label="name"
                                v-model="formAddGuest.selectedCountryAdress"></v-select>
                    </div>

                    <!-- Població residencia (Andorra) -->
                    <div v-if="format === 'AD'">
                        <label for="cityAdress" class="pt-3 pb-1" v-cloak>
                            {{ translator.poble }}
                        </label>
                        <input id="cityAdress"
                            type="text"
                            v-model="formAddGuest.cityAdress"
                            autocomplete="off"
                            class="form-control" />
                    </div>

                    <!-- Codi postal residencia (Andorra) -->
                    <div v-if="format === 'AD'">
                        <label for="postalAdress" class="pt-3 pb-1" v-cloak>
                            {{ translator.dip }}
                        </label>
                        <input id="postalAdress"
                            type="text"
                            v-model="formAddGuest.postalAdress"
                            autocomplete="off"
                            class="form-control" />
                    </div>

                    <div v-if="errorFormAddGuest.length > 0"
                        class="alert alert-danger mt-4"
                        role="alert">
                        <p class="mb-2" v-for="(error, index) in errorFormAddGuest" :key="index">
                            - {{ error }}
                        </p>
                    </div>

                    <button v-if="isEdit"
                            @click.prevent.self="validateFormAddGuest()"
                            class="btn btn-secondary mt-3"
                            v-cloak>
                        {{ translator.guardar }}
                    </button>
                    <button v-else
                            @click.prevent.self="validateFormAddGuest()"
                            class="btn btn-secondary mt-3"
                            v-cloak>
                        {{ translator.afegirhoste }}
                    </button>
                </div>
            </div>
            <!-- Fin ventana añadir huésped -->
        </main>

        <NavbarManager :translator="translator" />

    </div>
</template>


<script>
    var canvas, ctx;
    var mouseX, mouseY, mouseDown = 0;
    var touchX, touchY;
    var lastX, lastY = -1;

    import countries from '../../../assets/js/countries.js';
    import NavbarManager from "@/components/NavbarManager.vue";
    import { mapState } from "vuex";
    const $ = require('jquery');

    // Firma android //
    let scrollY = '';
    $(document).on('show.bs.modal', '#Modal', function () {
        if (navigator.userAgent.match(/Android/i)) {
            const is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            is_firefox ? 
                document.documentElement.mozRequestFullScreen() : 
                document.documentElement.webkitRequestFullscreen();
        }
        scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
        const body = document.body;
        body.style.position = 'fixed';
        body.style.width = '100%';
        body.style.top = `-${scrollY}`;
    });
    $(document).on('hidden.bs.modal', '#Modal', function () {
        if (navigator.userAgent.match(/Android/i)) {
            const is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            is_firefox ? 
                document.mozCancelFullScreen() : 
                document.webkitExitFullscreen();
        }
        const body = document.body;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0'));
    });
    window.addEventListener('scroll', () => {
        document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });
    // //

    export default {
        name: "ManagerBookingGuests",
        components: {
            NavbarManager,
        },
        computed: {
            ...mapState("utils", ["user", "translator"]),
            ...mapState("manager", ["booking", "bookingGuests", "itRegionBirth", "itDocumentCity"]),
        },

        watch: {
            translator() {
                this.$store.dispatch("manager/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId });
                this.getBookingGuests();
            },
            booking() {
                this.format = this.booking.format_hostes;
                this.isPreLoad = false;
                this.verifyCheckin();
            },
            bookingGuests(newQ, oldQ) {
                this.userId = "";
                this.isPreLoad = false;
                this.hasImage = this.hasImageSelfie = this.hasImageDocument = false;
                let adults = 0;
                let firms = 0;
                this.bookingGuests.forEach(guest => {
                    guest._isAdult ? adults++ : '';
                    guest._isAdult && guest._isSign ? firms++ : '';
                });
                
                // Si se ha realizado un escaneo, buscamos al nuevo huéped y guardamos la foto
                if ( this.isScan ) {
                    let allUsersId = []
                    for (let o of oldQ) {
                        allUsersId.push(o.guest_id)
                    }
                    for (let n of newQ) {
                        if (!allUsersId.includes(n.guest_id)) {
                            console.log('guest', n.guest_id)
                             this.saveUserId(n, 1)
                             this.savePhotoFrontPassport(this.image, true)
                        }
                    }
                    this.isScan = false
                }   

                firms >= adults ? this.controlAdultWithSign = true : '';

                this.isPreLoad = false;
            },
            format() {
                this.format == 'IT' ? this.getBookingGuests() : '';
            }
        },

        data() {
            return {
                isPreLoad: false,
                countries: countries,
                dateNow: '',
                isDisabled: false,
                formAddGuest: {
                    id: '',
                    name: '',
                    surname: '',
                    secondSurname: '',
                    gender: 'M',
                    typePerson: '1',
                    birthDate: '',
                    selectedCountryBirth: '',
                    cityBirth: '',
                    selectedRegionBirth: '',
                    numberDocument: '',
                    typeDocument: 'D',
                    dateDocument: '',
                    selectedCountryDocument: '',
                    cityDocument: '',
                    selectedCountryAdress: '',
                    cityAdress: '',
                    postalAdress: '',
                },
                format: '',
                onePeopleItalianAllRegister: false,
                errorFormAddGuest: [],
                isEdit: false,

                // Lectura DNI
                newScanGuest: {},

                // Anyline
                isReadAnylineSDK: true,
                isRead: false,
                anyline: null,
                image: null,
                license_prod: 'ewogICJsaWNlbnNlS2V5VmVyc2lvbiIgOiAiMy4wIiwKICAiZGVidWdSZXBvcnRpbmciIDogIm9uIiwKICAibWFqb3JWZXJzaW9uIiA6ICIzNyIsCiAgIm1heERheXNOb3RSZXBvcnRlZCIgOiA5MCwKICAiYWR2YW5jZWRCYXJjb2RlIiA6IGZhbHNlLAogICJtdWx0aUJhcmNvZGUiIDogdHJ1ZSwKICAicGxhdGZvcm0iIDogWyAiQW5kcm9pZCIsICJpT1MiLCAiV2luZG93cyIsICJXZWIiLCAiSlMiIF0sCiAgInNjb3BlIiA6IFsgIkFMTCIgXSwKICAic2hvd1dhdGVybWFyayIgOiBmYWxzZSwKICAidG9sZXJhbmNlRGF5cyIgOiAzMCwKICAidmFsaWQiIDogIjIwMjUtMDctMjMiLAogICJsaWNlbnNlSWQiIDogIkp6ZmhtdEZRVUhtRXRZVDZnQ0lUMWtFTGk5aXNjeGxEN2RSeHI4ZUlyNEkiLAogICJpb3NJZGVudGlmaWVyIiA6IFsgImxvY2FsaG9zdCIsICJhcHAuaWNuZWEubmV0IiwgInhhcHAuaWNuZWEubmV0IiBdLAogICJhbmRyb2lkSWRlbnRpZmllciIgOiBbICJsb2NhbGhvc3QiLCAiYXBwLmljbmVhLm5ldCIsICJ4YXBwLmljbmVhLm5ldCIgXSwKICAid2luZG93c0lkZW50aWZpZXIiIDogWyAibG9jYWxob3N0IiwgImFwcC5pY25lYS5uZXQiLCAieGFwcC5pY25lYS5uZXQiIF0sCiAgImpzSWRlbnRpZmllciIgOiBbICJsb2NhbGhvc3QiLCAiYXBwLmljbmVhLm5ldCIsICJ4YXBwLmljbmVhLm5ldCIgXSwKICAid2ViSWRlbnRpZmllciIgOiBbICJsb2NhbGhvc3QiLCAiYXBwLmljbmVhLm5ldCIsICJ4YXBwLmljbmVhLm5ldCIgXQp9CgpaVktGdUljSmxpaThPTE1YUy9mWkM5Q3BBWis4MmlaemF2UWJLM0JmN1VnbElmakRldmd6bk9Eak1vUHlQNWJBeGtOMFhMZUVTMjdQRjU2ZFNuRytSOHBjWnFtZXY3STM0OW5qTVJsTXVWdWp2Mkkyay9MYjRtWE5NZ3p3Y3dNWGJtRVBoWDRTSjBxOUVoRS8zd3RhakJJdndyK2hOQ1Y3aWw3T1dReDFOdkJ6bzhqdmdKNWpQRWk1M2hvOE1sNjR2amUzaFNiL3pobEg1bndrYkhzd29Ydms5cEc0dkFDakV1V0ZLSEpHL202R1FaNVU5V0dscEhrbG1FV0ZRVWVoZkhtSGFQL3JDVUUvSTlLOVBISFBtYi9idU9TZ3F1VWNSQXRQT1V5MXoxUGxxK0hpMVl0aERKQ01UN1dnS1gxcTJNc1FDZEdSNCt1cE9sWjBycDF3eVE9PQ==',
                show_sidebar: false,
                isScan: false,

                // HuéspedId para firma o fotos
                userId: "",
                photoPosition: '',
                hasImage: false,
                userControl: "",
                hasImageSelfie: false,
                hasImageDocument: false,
                //image: null,
                disabled: 0,
                controlAdultWithSign: false,
                // Canvas en blanco
                canvasClear: '',
                isCanvasClear: true,

            };
        },

        async created() {
            this.isPreLoad = true;
            //Fecha inicio para datepicker
            const formatYmd = (date) => date.toISOString().slice(0, 10);
            this.dateNow = formatYmd(new Date())

            try {

                if ( this.translator ) {
                    await this.$store.dispatch("manager/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId })
                    this.getBookingGuests();
                } else {
                    await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                }

                //Soluciona bug en la firma(teléfonos android)
                $(document).on('show.bs.modal','#Modal', function () {
                    $( "body" ).addClass( "fixed-screen" );
                });
                $(document).on('hidden.bs.modal','#Modal', function () {
                    $( "body" ).removeClass( "fixed-screen" )
                });
            } catch (error) {
                console.error(error);
            }
        },

        methods: {

            openAddEditGuest(guest) {
                this.openNav();
                if (guest != undefined) {
                    //console.log('Editar', guest);

                    // Botón añadir/editar a editar
                    this.isEdit = true;
                    console.log(this.formAddGuest)
                    this.formAddGuest.id = guest.guest_id;
                    this.formAddGuest.name = guest.guest_first_name;
                    if (this.booking.format_hostes == 'ES') {
                        this.formAddGuest.surname = guest.guest_last_name_one;
                        this.formAddGuest.secondSurname = guest.guest_last_name_two;
                    } else {
                        this.formAddGuest.surname = `${guest.guest_last_name_one} ${guest.guest_last_name_two}`;
                    }
                    this.formAddGuest.gender = guest.guest_sex;
                    this.formAddGuest.typePerson = guest.guest_type;
                    this.formAddGuest.birthDate = guest.guest_birthday_date.replace(/\//g, '-');
                    this.formAddGuest.selectedCountryBirth = guest.guest_birthday_country;
                    this.formAddGuest.cityBirth = guest.guest_birthday_place;
                    this.formAddGuest.selectedRegionBirth = guest.guest_birthday_region;
                    this.formAddGuest.numberDocument = guest.document_number;
                    this.formAddGuest.typeDocument = guest.document_type;
                    this.formAddGuest.dateDocument = guest.document_date.replace(/\//g, '-');
                    this.formAddGuest.selectedCountryDocument = guest.document_country;
                    this.formAddGuest.cityDocument = guest.document_place;
                    this.formAddGuest.selectedCountryAdress = guest.address_country;
                    this.formAddGuest.cityAdress = guest.address_place;
                    this.formAddGuest.postalAdress = guest.address_zip;
                }
            },

            validateOneGuestItalian() {
                this.bookingGuests.forEach(guest => {
                    guest._input_empty == false ? this.onePeopleItalianAllRegister = true : null;
                })
            },

            validateFormAddGuest() {
                this.errorFormAddGuest = [];

                if (this.format === "FR") {
                    !this.formAddGuest.name ? this.errorFormAddGuest.push(this.translator.faltanom) : '';
                    !this.formAddGuest.surname ? this.errorFormAddGuest.push(this.translator.faltacognom) : '';
                    !this.formAddGuest.birthDate ? this.errorFormAddGuest.push(this.translator.faltadatanaixement) : '';
                    !this.formAddGuest.numberDocument ? this.errorFormAddGuest.push(this.translator.faltadnipassaport) : '';
                    //!this.formAddGuest.dateDocument ? this.errorFormAddGuest.push(this.translator.faltadataexpedicio) : '';
                    !this.formAddGuest.selectedCountryDocument ? this.errorFormAddGuest.push(this.translator.faltanacionalitat) : '';

                } else if (this.format === "AD") {
                    !this.formAddGuest.name ? this.errorFormAddGuest.push(this.translator.faltanom) : '';
                    !this.formAddGuest.surname ? this.errorFormAddGuest.push(this.translator.faltacognom) : '';
                    !this.formAddGuest.birthDate ? this.errorFormAddGuest.push(this.translator.faltadatanaixement) : '';
                    !this.formAddGuest.numberDocument ? this.errorFormAddGuest.push(this.translator.faltadnipassaport) : '';
                    !this.formAddGuest.dateDocument ? this.errorFormAddGuest.push(this.translator.faltadataexpedicio) : '';
                    !this.formAddGuest.selectedCountryDocument ? this.errorFormAddGuest.push(this.translator.faltanacionalitat) : '';
                    !this.formAddGuest.selectedCountryAdress ? this.errorFormAddGuest.push(this.translator.faltapaisorigen) : '';
                    !this.formAddGuest.cityAdress ? this.errorFormAddGuest.push(this.translator.faltapoble) : '';
                    !this.formAddGuest.postalAdress ? this.errorFormAddGuest.push(this.translator.faltadip) : '';

                } else if (this.format === "PT") {
                    !this.formAddGuest.name ? this.errorFormAddGuest.push(this.translator.faltanom) : '';
                    !this.formAddGuest.surname ? this.errorFormAddGuest.push(this.translator.faltacognom) : '';
                    !this.formAddGuest.birthDate ? this.errorFormAddGuest.push(this.translator.faltadatanaixement) : '';
                    !this.formAddGuest.selectedCountryBirth ? this.errorFormAddGuest.push(`Falta ${String(this.translator.paisnaixement).toLowerCase()}`) : '';
                    //!this.formAddGuest.cityBirth ? this.errorFormAddGuest.push(this.translator.faltapobleorigen) : '';
                    !this.formAddGuest.numberDocument ? this.errorFormAddGuest.push(this.translator.faltadnipassaport) : '';
                    !this.formAddGuest.selectedCountryDocument ? this.errorFormAddGuest.push(this.translator.faltanacionalitat) : '';
                    !this.formAddGuest.selectedCountryAdress ? this.errorFormAddGuest.push(this.translator.faltapaisresidencia) : '';
                    //!this.formAddGuest.cityAdress ? this.errorFormAddGuest.push(this.translator.faltapoble) : '';

                } else if (this.format === "IT") {

                    //Comprobamos si tenemos un huésped con todos los campos rellenados
                    if (this.bookingGuests.length > 0) {
                        let isAllFieldsInOneGuest = false

                        this.bookingGuests.forEach(guest => {
                            let fieldOk = []
                            guest.guest_first_name != '' ? null : fieldOk.push('Nombre')
                            guest.guest_last_name_one != '' ? null : fieldOk.push('Apellido')
                            guest.guest_sex != '' ? null : fieldOk.push('Género')
                            guest.document_country != '' ? null : fieldOk.push('Pais')
                            guest.document_number != '' ? null : fieldOk.push('Número documento de identidad o pasaporte')
                            guest.guest_birthday_country != '' ? null : fieldOk.push('País de nacimiento')
                            guest.document_place != '' ? null : fieldOk.push('Lugar de expedición')

                            if (guest.guest_birthday_country == 'IT') {
                                guest.guest_birthday_place != '' ? null : fieldOk.push('Población de nacimiento')
                                guest.guest_birthday_region != '' ? null : fieldOk.push('Región de nacimiento')
                            }
                            
                            fieldOk.length === 0 ? isAllFieldsInOneGuest = true : console.log(fieldOk);
                        })
                        
                        //si tiene un huéped registrado con todos los campos....
                        if (isAllFieldsInOneGuest) {

                            !this.formAddGuest.name ? this.errorFormAddGuest.push(this.translator.faltanom) : '';
                            !this.formAddGuest.surname ? this.errorFormAddGuest.push(this.translator.faltacognom) : '';
                            !this.formAddGuest.birthDate ? this.errorFormAddGuest.push(this.translator.faltadatanaixement) : '';
                            //!this.formAddGuest.numberDocument ? this.errorFormAddGuest.push(this.translator.faltadnipassaport) : '';
                            !this.formAddGuest.selectedCountryDocument ? this.errorFormAddGuest.push(this.translator.faltanacionalitat) : '';
                            //!this.formAddGuest.cityDocument ? this.errorFormAddGuest.push(this.translator.faltapobleorigen) : '';
                            !this.formAddGuest.selectedCountryBirth ? this.errorFormAddGuest.push(this.translator.faltanacionalitat) : '';
                            if ( this.formAddGuest.selectedCountryBirth == 'IT' ) {
                                !this.formAddGuest.cityBirth ? this.errorFormAddGuest.push(this.translator.faltapoblenaixement) : '';
                                !this.formAddGuest.selectedRegionBirth ? this.errorFormAddGuest.push(this.translator.faltaregionaixement) : '';
                            }
                        } else {
                            // Si no lo tiene validación con todos los campos
                            !this.formAddGuest.name ? this.errorFormAddGuest.push(this.translator.faltanom) : '';
                            !this.formAddGuest.surname ? this.errorFormAddGuest.push(this.translator.faltacognom) : '';
                            !this.formAddGuest.birthDate ? this.errorFormAddGuest.push(this.translator.faltadatanaixement) : '';
                            !this.formAddGuest.numberDocument ? this.errorFormAddGuest.push(this.translator.faltadnipassaport) : '';
                            !this.formAddGuest.selectedCountryDocument ? this.errorFormAddGuest.push(this.translator.faltanacionalitat) : '';
                            !this.formAddGuest.cityDocument ? this.errorFormAddGuest.push(this.translator.faltapobleorigen) : '';
                            !this.formAddGuest.selectedCountryBirth ? this.errorFormAddGuest.push(this.translator.faltanacionalitat) : '';
                            if ( this.formAddGuest.selectedCountryBirth == 'IT' ) {
                                !this.formAddGuest.cityBirth ? this.errorFormAddGuest.push(this.translator.faltapoblenaixement) : '';
                                !this.formAddGuest.selectedRegionBirth ? this.errorFormAddGuest.push(this.translator.faltaregionaixement) : '';
                            }
                        }
                      
                    } else {

                        !this.formAddGuest.name ? this.errorFormAddGuest.push(this.translator.faltanom) : '';
                        !this.formAddGuest.surname ? this.errorFormAddGuest.push(this.translator.faltacognom) : '';
                        !this.formAddGuest.birthDate ? this.errorFormAddGuest.push(this.translator.faltadatanaixement) : '';
                        !this.formAddGuest.numberDocument ? this.errorFormAddGuest.push(this.translator.faltadnipassaport) : '';
                        !this.formAddGuest.selectedCountryDocument ? this.errorFormAddGuest.push(this.translator.faltanacionalitat) : '';
                        !this.formAddGuest.cityDocument ? this.errorFormAddGuest.push(this.translator.faltapobleorigen) : '';
                        !this.formAddGuest.selectedCountryBirth ? this.errorFormAddGuest.push(this.translator.faltanacionalitat) : '';
                        if ( this.formAddGuest.selectedCountryBirth == 'IT' ) {
                            !this.formAddGuest.cityBirth ? this.errorFormAddGuest.push(this.translator.faltapoblenaixement) : '';
                            !this.formAddGuest.selectedRegionBirth ? this.errorFormAddGuest.push(this.translator.faltaregionaixement) : '';
                        }
                    }
                    
                } else if (this.format === "AR") {
                    //Validación de Argentina
                    !this.formAddGuest.name ? this.errorFormAddGuest.push(this.translator.faltanom) : '';
                    !this.formAddGuest.surname ? this.errorFormAddGuest.push(this.translator.faltacognom) : '';                   
                    !this.formAddGuest.birthDate ? this.errorFormAddGuest.push(this.translator.faltadatanaixement) : '';
                    !this.formAddGuest.numberDocument ? this.errorFormAddGuest.push(this.translator.faltadnipassaport) : '';
                    !this.formAddGuest.selectedCountryDocument ? this.errorFormAddGuest.push(this.translator.faltanacionalitat) : '';
                    
                } else {
                    //Validación de española
                    !this.formAddGuest.name ? this.errorFormAddGuest.push(this.translator.faltanom) : '';
                    !this.formAddGuest.surname ? this.errorFormAddGuest.push(this.translator.faltacognom) : '';                   
                    !this.formAddGuest.birthDate ? this.errorFormAddGuest.push(this.translator.faltadatanaixement) : '';
                    !this.formAddGuest.numberDocument ? this.errorFormAddGuest.push(this.translator.faltadnipassaport) : '';
                    //!this.formAddGuest.dateDocument ? this.errorFormAddGuest.push(this.translator.faltadataexpedicio) : '';
                    !this.formAddGuest.selectedCountryDocument ? this.errorFormAddGuest.push(this.translator.faltanacionalitat) : '';
                    // if (this.formAddGuest.selectedCountryDocument == 'ES') {
                    //   !this.formAddGuest.secondSurname ? this.errorFormAddGuest.push(this.translator.faltacognom) : '';
                    // }
                }
                this.errorFormAddGuest.length == 0 ? this.addGuest() : '';

            },

            async addGuest() {

                this.isPreLoad = true;
                try {
                    let tipus = this.formAddGuest.typePerson
                    if (this.format != "IT") {
                        tipus = '0';
                    }

                    await this.$store.dispatch("manager/postAddGuest", {

                        usr: String(this.$route.query.id).replace(/[ ]/g, '+'),
                        reserva: this.$route.params.bookingId,
                        hostId: this.formAddGuest.id,
                        nom: this.formAddGuest.name,
                        cognom1: this.formAddGuest.surname,
                        cognom2: this.formAddGuest.secondSurname,
                        sexe: this.formAddGuest.gender,
                        tipus,
                        naixement_data: this.formAddGuest.birthDate,
                        naixement_pais: this.formAddGuest.selectedCountryBirth,
                        naixement_lloc: this.formAddGuest.cityBirth,
                        naixement_regio: this.formAddGuest.selectedRegionBirth,
                        document_numero: this.formAddGuest.numberDocument,
                        document_tipus: this.formAddGuest.typeDocument,
                        document_data: this.formAddGuest.dateDocument,
                        document_pais: this.formAddGuest.selectedCountryDocument,
                        document_lloc: this.formAddGuest.cityDocument,
                        adreca_pais: this.formAddGuest.selectedCountryAdress,
                        adreca_lloc: this.formAddGuest.cityAdress,
                        adreca_dip: this.formAddGuest.postalAdress,
                    });

                    // Muestro notificación
                    this.$toast.success(this.translator.guardat);
                    //Limpio y cierro formulario
                    this.cleanFormGuest();

                } catch (error) {
                    console.error(error);
                } finally {
                    this.isPreLoad = false;
                }
            },

            async getBookingGuests() {
                this.isPreload = true;
                try {
                    // Usuario Italiano pedimos ciudades y regiones.
                    if (this.format === 'IT') {
                        this.$store.dispatch("manager/getHostesRegionsIT", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId })
                        this.$store.dispatch("manager/getHostesPoblesIT", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId })
                    }
                    await this.$store.dispatch("manager/getHostes", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId });
                } catch (error) {
                    console.error(error);
                }
            },

            cleanFormGuest() {
                this.closeNav();
                // Borro formulario
                this.formAddGuest.id = '';
                this.formAddGuest.name = '';
                this.formAddGuest.surname = '';
                this.formAddGuest.secondSurname = '';
                this.formAddGuest.gender = 'M';
                this.formAddGuest.typePerson = '1';
                this.formAddGuest.birthDate = '';
                this.formAddGuest.selectedCountryBirth = '';
                this.formAddGuest.cityBirth = '';
                this.formAddGuest.selectedRegionBirth = '';
                this.formAddGuest.numberDocument = '';
                this.formAddGuest.typeDocument = 'D';
                this.formAddGuest.dateDocument = '';
                this.formAddGuest.selectedCountryDocument = '';
                this.formAddGuest.cityDocument = '';
                this.formAddGuest.selectedCountryAdress = '';
                this.formAddGuest.cityAdress = '';
                this.formAddGuest.postalAdress = '';
            },

            verifyCheckin() {
                const formatYmd = (date) => date.toISOString().slice(0, 10);
                const compare = (a, b) => a.getTime() < b.getTime();

                parseInt(this.booking.status) >= 7 || compare(new Date(formatYmd(new Date(this.booking.departure_date))), new Date(formatYmd(new Date()))) 
                    ? this.isDisabled = true
                    : this.isDisabled = false;
            },

            async deleteGuest(guest) {
                this.isPreLoad = true;
                try {
                    await this.$store.dispatch("manager/deleteHostesEliminar", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId, hostId: guest.guest_id })
                    this.$toast.success(this.translator.eliminat);
                } catch (error) {
                    console.log(error);
                } finally {
                    //this.isPreLoad = false;
                }
            },

            //// Lectura DNI ////
            controlGuest() {
                this.existingGuests = this.bookingGuests;
            },

            /// AnyLine SDK ///
            async initAnyline() {
                let TimerToChange;
                this.anyline = window.anylinejs.init({
                    preset: 'universalid_mrz',
                    //license: this.license_xapp,
                    license: this.license_prod,
                    element: document.getElementById('anyline'),
                    anylinePath: '/assets/anyline',
                    debugAnyline: true,
                    config: {
                        loadingScreen: '<div class="text-light">loading...</div>',
                    }
                })
                
                let oneTime = true
                this.anyline.onFrame = (image)=> {
                    this.image = image;
                    if (oneTime) {
                        TimerToChange = setTimeout(this.changeFormatScanAnyline, 14000);
                        oneTime = false;
                    }
                }

                this.anyline.onResult = (data) => {
                    let surname = null;
                    data['result'].forEach(d=> {

                        switch(d.identifier) {
                            case 'givenNames':
                                this.formAddGuest.name = d.text;
                            break;
                        case 'surname':
                            surname = d.text;
                            break;
                        case 'sex':
                            this.formAddGuest.gender = d.text;
                            break;
                        case 'formattedDateOfBirth':
                            this.formAddGuest.birthDate = this.dateParser(d.text);
                            break;
                        case 'documentType':
                            this.formAddGuest.typeDocument = (d.text == "ID") ? "D" : "P";
                            break;

                        case 'documentNumber': {
                            const isIDcard = data['result'].filter(df=> df.identifier == 'documentType')[0].text.trim() == "ID";
                            const personalNumber = data['result'].filter(df=> df.identifier == 'personalNumber')[0].text.trim();

                            if(isIDcard && personalNumber) {
                                this.formAddGuest.numberDocument = personalNumber;
                            } else {
                                this.formAddGuest.numberDocument = d.text.trim();
                            }
                            break;
                        }
                        case 'formattedDateOfExpiry':
                            this.formAddGuest.dateDocument = this.dateParser(d.text);
                            break;
                        case 'issuingCountryCode':
                            this.formAddGuest.selectedCountryDocument = this.countryCodeParser(d.text);
                            break;
                        }       
                    })

                    if (this.formAddGuest.selectedCountryDocument == 'ES' && surname.split(' ').length >= 2) {
                        this.formAddGuest.surname = surname.split(' ')[0];
                        this.formAddGuest.secondSurname = surname.split(' ').filter(s => s != this.formAddGuest.surname).join(' ');
                    } else {
                        this.formAddGuest.surname = surname
                    }   

                    //test mobile
                    //this.display = JSON.stringify(data.result, null, 2);
                    clearTimeout(TimerToChange);
                    this.isRead = true;
                    this.isScan = true;
                    this.addGuest();
                    this.closeAnyline();
                }
                
                await this.anyline.startScanning()
                    //.catch((e) => console.error(e.message))
                    .catch((e) => {
                        console.error(e.message)
                        //alert(e.message)
                        this.closeAnyline();
                        this.openAnylinePhoto();
                    })

            },
            async openAnyline() {
                this.isRead = false;
                this.show_sidebar = true;
                await this.initAnyline();
                
            },
            closeAnyline() {
                this.show_sidebar = false;

                setTimeout(()=> {
                    this.anyline.stopScanning();
                        this.anyline.dispose();
                        this.anyline = null;
                    //this.disposeAnyline();
                }, 500)
            },
            async disposeAnyline() {
                if ( this.anyline ) {
                    await this.anyline.stopScanning();
                    await this.anyline.dispose();
                    this.anyline = null;
                }
            },
            changeFormatScanAnyline() {
                if ( !this.isRead ) {
                    this.anyline.stopScanning();
                    this.anyline.dispose();
                    this.anyline = null;
                    this.show_sidebar = false;
                    this.$toast.open({
                        message: `<span style="text-decoration:underline;padding:10px;">${this.translator.errorcaptura}</span> <br/> <span ></span>`,
                        type: 'error',
                        position: 'top',
                        duration: '8000',
                        onClick: this.openAnylinePhoto
                    });
                }
            },

            openAnylinePhoto() {
                let btnScanPhoto = document.querySelector('#fileInput');
                btnScanPhoto.click();
            },

            dateParser(date) {
                date = date.split('.');
                return `${date[2]}-${date[1]}-${date[0]}`;
            },
            countryCodeParser(code) {
                
                switch(code) {
                case 'AFG':
                    return 'AF';
                case 'ALA':
                    return 'AX';
                case 'ALB':
                    return 'AL';
                case 'DZA':
                    return 'DZ';
                case 'ASM':
                    return 'AS';
                case 'AND':
                    return 'AD';
                case 'AGO':
                    return 'AO';
                case 'AIA':
                    return 'AI';
                case 'ATA':
                    return 'AQ';
                case 'ATG':
                    return 'AG';
                case 'ARG':
                    return 'AR';
                case 'ARM':
                    return 'AM';
                case 'ABW':
                    return 'AW';
                case 'AUS':
                    return 'AU';
                case 'AUT':
                    return 'AT';
                case 'AZE':
                    return 'AZ';
                case 'BHS':
                    return 'BS';
                case 'BHR':
                    return 'BH';
                case 'BGD':
                    return 'BD';
                case 'BRB':
                    return 'BB';
                case 'BLR':
                    return 'BY';
                case 'BEL':
                    return 'BE';
                case 'BLZ':
                    return 'BZ';
                case 'BEN':
                    return 'BJ';
                case 'BMU':
                    return 'BM';
                case 'BTN':
                    return 'BT';
                case 'BOL':
                    return 'BO';
                case 'BES':
                    return 'BQ';
                case 'BIH':
                    return 'BA';
                case 'BWA':
                    return 'BW';
                case 'BVT':
                    return 'BV';
                case 'BRA':
                    return 'BR';
                case 'IOT':
                    return 'IO';
                case 'BRN':
                    return 'BN';
                case 'BGR':
                    return 'BG';
                case 'BFA':
                    return 'BF';
                case 'BDI':
                    return 'BI';
                case 'CPV':
                    return 'CV';
                case 'KHM':
                    return 'KH';
                case 'CMR':
                    return 'CM';
                case 'CAN':
                    return 'CA';
                case 'CYM':
                    return 'KY';
                case 'CAF':
                    return 'CF';
                case 'TCD':
                    return 'TD';
                case 'CHL':
                    return 'CL';
                case 'CHN':
                    return 'CN';
                case 'CXR':
                    return 'CX';
                case 'CCK':
                    return 'CC';
                case 'COL':
                    return 'CO';
                case 'COM':
                    return 'KM';
                case 'COD':
                    return 'CD';
                case 'COG':
                    return 'CG';
                case 'COK':
                    return 'CK';
                case 'CRI':
                    return 'CR';
                case 'CIV':
                    return 'CI';
                case 'HRV':
                    return 'HR';
                case 'CUB':
                    return 'CU';
                case 'CUW':
                    return 'CW';
                case 'CYP':
                    return 'CY';
                case 'CZE':
                    return 'CZ';
                case 'DNK':
                    return 'DK';
                case 'DJI':
                    return 'DJ';
                case 'DMA':
                    return 'DM';
                case 'DOM':
                    return 'DO';
                case 'ECU':
                    return 'EC';
                case 'EGY':
                    return 'EG';
                case 'SLV':
                    return 'SV';
                case 'GNQ':
                    return 'GQ';
                case 'ERI':
                    return 'ER';
                case 'EST':
                    return 'EE';
                case 'SWZ':
                    return 'SZ';
                case 'ETH':
                    return 'ET';
                case 'FLK':
                    return 'FK';
                case 'FRO':
                    return 'FO';
                case 'FJI':
                    return 'FJ';
                case 'FIN':
                    return 'FI';
                case 'FRA':
                    return 'FR';
                case 'GUF':
                    return 'GF';
                case 'PYF':
                    return 'PF';
                case 'ATF':
                    return 'TF';
                case 'GAB':
                    return 'GA';
                case 'GMB':
                    return 'GM';
                case 'GEO':
                    return 'GE';
                case 'DEU':
                    return 'DE';
                case 'D':
                    return 'DE';
                case 'GHA':
                    return 'GH';
                case 'GIB':
                    return 'GI';
                case 'GRC':
                    return 'GR';
                case 'GRL':
                    return 'GL';
                case 'GRD':
                    return 'GD';
                case 'GLP':
                    return 'GP';
                case 'GUM':
                    return 'GU';
                case 'GTM':
                    return 'GT';
                case 'GGY':
                    return 'GG';
                case 'GIN':
                    return 'GN';
                case 'GNB':
                    return 'GW';
                case 'GUY':
                    return 'GY';
                case 'HTI':
                    return 'HT';
                case 'HMD':
                    return 'HM';
                case 'VAT':
                    return 'VA';
                case 'HND':
                    return 'HN';
                case 'HKG':
                    return 'HK';
                case 'HUN':
                    return 'HU';
                case 'ISL':
                    return 'IS';
                case 'IND':
                    return 'IN';
                case 'IDN':
                    return 'ID';
                case 'IRN':
                    return 'IR';
                case 'IRQ':
                    return 'IQ';
                case 'IRL':
                    return 'IE';
                case 'IMN':
                    return 'IM';
                case 'ISR':
                    return 'IL';
                case 'ITA':
                    return 'IT';
                case 'JAM':
                    return 'JM';
                case 'JPN':
                    return 'JP';
                case 'JEY':
                    return 'JE';
                case 'JOR':
                    return 'JO';
                case 'KAZ':
                    return 'KZ';
                case 'KEN':
                    return 'KE';
                case 'KIR':
                    return 'KI';
                case 'PRK':
                    return 'KP';
                case 'KOR':
                    return 'KR';
                case 'KWT':
                    return 'KW';
                case 'KGZ':
                    return 'KG';
                case 'LAO':
                    return 'LA';
                case 'LVA':
                    return 'LV';
                case 'LBN':
                    return 'LB';
                case 'LSO':
                    return 'LS';
                case 'LBR':
                    return 'LR';
                case 'LBY':
                    return 'LY';
                case 'LIE':
                    return 'LI';
                case 'LTU':
                    return 'LT';
                case 'LUX':
                    return 'LU';
                case 'MAC':
                    return 'MO';
                case 'MKD':
                    return 'MK';
                case 'MDG':
                    return 'MG';
                case 'MWI':
                    return 'MW';
                case 'MYS':
                    return 'MY';
                case 'MDV':
                    return 'MV';
                case 'MLI':
                    return 'ML';
                case 'MLT':
                    return 'MT';
                case 'MHL':
                    return 'MH';
                case 'MTQ':
                    return 'MQ';
                case 'MRT':
                    return 'MR';
                case 'MUS':
                    return 'MU';
                case 'MYT':
                    return 'YT';
                case 'MEX':
                    return 'MX';
                case 'FSM':
                    return 'FM';
                case 'MDA':
                    return 'MD';
                case 'MCO':
                    return 'MC';
                case 'MNG':
                    return 'MN';
                case 'MNE':
                    return 'ME';
                case 'MSR':
                    return 'MS';
                case 'MAR':
                    return 'MA';
                case 'MOZ':
                    return 'MZ';
                case 'MMR':
                    return 'MM';
                case 'NAM':
                    return 'NA';
                case 'NRU':
                    return 'NR';
                case 'NPL':
                    return 'NP';
                case 'NLD':
                    return 'NL';
                case 'NCL':
                    return 'NC';
                case 'NZL':
                    return 'NZ';
                case 'NIC':
                    return 'NI';
                case 'NER':
                    return 'NE';
                case 'NGA':
                    return 'NG';
                case 'NIU':
                    return 'NU';
                case 'NFK':
                    return 'NF';
                case 'MNP':
                    return 'MP';
                case 'NOR':
                    return 'NO';
                case 'OMN':
                    return 'OM';
                case 'PAK':
                    return 'PK';
                case 'PLW':
                    return 'PW';
                case 'PSE':
                    return 'PS';
                case 'PAN':
                    return 'PA';
                case 'PNG':
                    return 'PG';
                case 'PRY':
                    return 'PY';
                case 'PER':
                    return 'PE';
                case 'PHL':
                    return 'PH';
                case 'PCN':
                    return 'PN';
                case 'POL':
                    return 'PL';
                case 'PRT':
                    return 'PT';
                case 'PRI':
                    return 'PR';
                case 'QAT':
                    return 'QA';
                case 'REU':
                    return 'RE';
                case 'ROU':
                    return 'RO';
                case 'RUS':
                    return 'RU';
                case 'RWA':
                    return 'RW';
                case 'BLM':
                    return 'BL';
                case 'SHN':
                    return 'SH';
                case 'KNA':
                    return 'KN';
                case 'LCA':
                    return 'LC';
                case 'MAF':
                    return 'MF';
                case 'SPM':
                    return 'PM';
                case 'VCT':
                    return 'VC';
                case 'WSM':
                    return 'WS';
                case 'SMR':
                    return 'SM';
                case 'STP':
                    return 'ST';
                case 'SAU':
                    return 'SA';
                case 'SEN':
                    return 'SN';
                case 'SRB':
                    return 'RS';
                case 'SYC':
                    return 'SC';
                case 'SLE':
                    return 'SL';
                case 'SGP':
                    return 'SG';
                case 'SXM':
                    return 'SX';
                case 'SVK':
                    return 'SK';
                case 'SVN':
                    return 'SI';
                case 'SLB':
                    return 'SB';
                case 'SOM':
                    return 'SO';
                case 'ZAF':
                    return 'ZA';
                case 'SGS':
                    return 'GS';
                case 'SSD':
                    return 'SS';
                case 'ESP':
                    return 'ES';
                case 'LKA':
                    return 'LK';
                case 'SDN':
                    return 'SD';
                case 'SUR':
                    return 'SR';
                case 'SJM':
                    return 'SJ';
                case 'SWE':
                    return 'SE';
                case 'CHE':
                    return 'CH';
                case 'SYR':
                    return 'SY';
                case 'TWN':
                    return 'TW';
                case 'TJK':
                    return 'TJ';
                case 'TZA':
                    return 'TZ';
                case 'THA':
                    return 'TH';
                case 'TLS':
                    return 'TL';
                case 'TGO':
                    return 'TG';
                case 'TKL':
                    return 'TK';
                case 'TON':
                    return 'TO';
                case 'TTO':
                    return 'TT';
                case 'TUN':
                    return 'TN';
                case 'TUR':
                    return 'TR';
                case 'TKM':
                    return 'TM';
                case 'TCA':
                    return 'TC';
                case 'TUV':
                    return 'TV';
                case 'UGA':
                    return 'UG';
                case 'UKR':
                    return 'UA';
                case 'ARE':
                    return 'AE';
                case 'GBR':
                    return 'GB';
                case 'UMI':
                    return 'UM';
                case 'USA':
                    return 'US';
                case 'URY':
                    return 'UY';
                case 'UZB':
                    return 'UZ';
                case 'VUT':
                    return 'VU';
                case 'VEN':
                    return 'VE';
                case 'VNM':
                    return 'VN';
                case 'VGB':
                    return 'VG';
                case 'VIR':
                    return 'VI';
                case 'WLF':
                    return 'WF';
                case 'ESH':
                    return 'EH';
                case 'YEM':
                    return 'YE';
                case 'ZMB':
                    return 'ZM';
                case 'ZWE':
                    return 'ZW';
                
                }
            },
            sleep(ms) {
                return new Promise((res)=> {
                setTimeout(res, ms);
                })
            },
            beforeUnmount() {
            this.disposeAnyline();
            },
            /// Fin AnyLine SDK ///

            async savePhoto(foto) {
                this.isPreLoad = true;
                this.hasImage = true;
                this.image = foto;
                this.$store.dispatch("manager/postPhotoDNI", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId, hostId: '', base64: foto.dataUrl })
            },


            //// Firma DNI ////
            signModal(guest) {
                this.userId = guest.guest_id;
                $('#Modal').modal('show');
                this.initCanvas();
            },

            saveUserId(guest, position) {
                position == 1 ? this.photoPosition = 1 : this.photoPosition = 2;
                this.userId = guest.guest_id;
            },

            //// Document identidad ////
            saveDocument(photo) {
                this.isPreload = true;
                this.hasImageDocument = true;
                this.image = photo;
                this.$store.dispatch("manager/postSaveDocument", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId, hostId: this.userId, base64: photo.dataUrl })
            },
 
            //// Anverso DNI ////
            savePhotoFrontPassport(photo, scan) {
                if ( scan ) {
                    this.isPreload = true;
                    this.$store.dispatch("manager/postSavePhotoFrontPassport", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId, hostId: this.userId, posicio: this.photoPosition, base64: photo })
                } else {
                    this.isPreload = true;
                    this.hasImage = true;
                    this.image = photo;
                    this.$store.dispatch("manager/postSavePhotoFrontPassport", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId, hostId: this.userId, posicio: this.photoPosition, base64: photo.dataUrl })
                }
            },

            //// Selfie ////
            saveSelfie(photo) {
                this.isPreload = true;
                this.hasImageSelfie = true;
                this.image = photo;
                this.$store.dispatch("manager/postSaveSelfie", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId, hostId: this.userId, base64: photo.dataUrl })
            },

            //// Conversiones ////
            flipDate(value) {
                if (!value) return ''
                const backendDate = value.split('-')
                return `${backendDate[2]}/${backendDate[1]}/${backendDate[0]}`
            },

            //// Canvas para firma ////
            initCanvas() {
                canvas = document.getElementById('sketchpad');

                if (canvas.getContext)
                    ctx = canvas.getContext('2d');

                if (ctx) {
                    canvas.addEventListener('mousedown', this.sketchpad_mouseDown, false);
                    canvas.addEventListener('mousemove', this.sketchpad_mouseMove, false);
                    window.addEventListener('mouseup', this.sketchpad_mouseUp, false);

                    canvas.addEventListener('touchstart', this.sketchpad_touchStart, false);
                    canvas.addEventListener('touchend', this.sketchpad_touchEnd, false);
                    canvas.addEventListener('touchmove', this.sketchpad_touchMove, false);
                }
                this.canvasClear = document.getElementById('sketchpad').toDataURL('image/png');
                this.isCanvasClear = true;
            },
            drawLine(ctx, x, y, size) {

                if (lastX == -1) {
                    lastX = x;
                    lastY = y;
                }

                let r = 0, g = 102, b = 179, a = 255;

                ctx.strokeStyle = "rgba(" + r + "," + g + "," + b + "," + (a / 255) + ")";
                ctx.lineCap = "round";
                ctx.beginPath();
                ctx.moveTo(lastX, lastY);
                ctx.lineTo(x, y);
                ctx.lineWidth = size;
                ctx.lineCap = 'round';
                ctx.stroke();
                ctx.closePath();
                lastX = x;
                lastY = y;
                this.isCanvasClear = false;
            },
            sketchpad_mouseDown() {
                mouseDown = 1;
                this.drawLine(ctx, mouseX, mouseY, 4);
            },
            sketchpad_mouseUp() {
                mouseDown = 0;
                lastX = -1;
                lastY = -1;
            },
            sketchpad_mouseMove(e) {
                this.getMousePos(e);

                if (mouseDown == 1) {
                    this.drawLine(ctx, mouseX, mouseY, 4);
                }
            },
            getMousePos(e) {
                if (!e)
                    //var e = event;
                    console.log(e)///No borrar
                if (e.offsetX) {
                    mouseX = e.offsetX;
                    mouseY = e.offsetY;
                }
                else if (e.layerX) {
                    mouseX = e.layerX;
                    mouseY = e.layerY;
                }
            },
            sketchpad_touchStart() {
                this.getTouchPos();
                this.drawLine(ctx, touchX, touchY, 4);
                //event.preventDefault();
            },
            sketchpad_touchEnd() {
                lastX = -1;
                lastY = -1;
            },
            sketchpad_touchMove(e) {
                this.getTouchPos(e);
                this.drawLine(ctx, touchX, touchY, 4);
                //event.preventDefault();
            },
            getTouchPos(e) {
                if (!e)
                    //var e = event;
                    console.log(e)///No borrar
                if (e.touches) {
                    if (e.touches.length == 1) {
                        var touch = e.touches[0];
                        if (window.innerHeight > window.innerWidth) { // Portrait
                            if (screen.width <= 430) { //Iphone's 6/7/8, Iphones's Plus 6/7/8 y Iphone X
                                touchX = touch.pageX - touch.target.offsetLeft - 10;
                                touchY = touch.clientY - touch.target.offsetTop - 75;
                            } else if (screen.width >= 538 && screen.width <= 569) { //suferce Duo
                                touchX = touch.pageX - touch.target.offsetLeft - 10;
                                touchY = touch.clientY - touch.target.offsetTop - 75;
                            } else if (screen.width > 766 && screen.width <= 800) {// ipad
                                touchX = touch.pageX - touch.target.offsetLeft - 135;
                                touchY = touch.clientY - touch.target.offsetTop - 90;
                            }
                            else if (screen.width > 1022 && screen.width <= 1040) {// ipadPro
                                touchX = touch.pageX - touch.target.offsetLeft - 265;
                                touchY = touch.clientY - touch.target.offsetTop - 90;
                            }
                        } else { // Landscape
                            if (screen.width <= 430) { //Iphone's 6/7/8, Iphones's Plus 6/7/8 y Iphone X
                                touchX = touch.pageX - touch.target.offsetLeft - 100;
                                touchY = touch.clientY - touch.target.offsetTop - 75;
                            } else if (screen.width >= 538 && screen.width <= 569) { //suferce Duo
                                touchX = touch.pageX - touch.target.offsetLeft - 10;
                                touchY = touch.clientY - touch.target.offsetTop - 75;
                            } else if (screen.width > 766 && screen.width <= 801) { // ipad Testeado
                                touchX = touch.pageX - touch.target.offsetLeft - 240;
                                touchY = touch.clientY - touch.target.offsetTop - 90;
                            } else if (screen.width > 1022 && screen.width <= 1040) {// ipadPro
                                touchX = touch.pageX - touch.target.offsetLeft - 265;
                                touchY = touch.clientY - touch.target.offsetTop - 90;
                            }
                        }
                    }
                }
            },
            //Borrar Canvas
            clearCanvas() {
                canvas = document.getElementById('sketchpad');
                if (canvas.getContext) {
                    ctx = canvas.getContext('2d');
                }
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                this.canvasClear = document.getElementById('sketchpad').toDataURL('image/png');
                this.isCanvasClear = true;
            },
            //Guardar firmna
            async saveCanvasGuest() {
                this.isPreLoad = true;
                try {
                    const guestSign = document.getElementById('sketchpad').toDataURL('image/png');
                    if (guestSign != this.canvasClear) {
                        $('#Modal').modal('hide');
                        await this.$store.dispatch("manager/postSignGuest", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId, hostId: this.userId, base64: guestSign });
                        this.$toast.success(this.translator.guardat);
                        this.clearCanvas();
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    this.isPreLoad = false;
                }
            },
            openNav() {
                document.getElementById("newGuest").style.width = "100%";
            },
            closeNav() {
                document.getElementById("newGuest").style.width = "0";
            }
        },

        filters: {
            transformDate(value) {
                if (!value) return '';
                const backendDate = value.split('-');
                return `${backendDate[2]}-${backendDate[1]}-${backendDate[0]}`;
            },
        }
    };
</script>


<style lang="scss">
.manager-booking-guests .container-nav-top {
  height: 50px;
  margin: 1rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a, h1 {
    color: var(--azul-oscuro);
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: lighten($color: #0c2c40, $amount: 15);
    }
  }
}

.manager-booking-guests {
    background-color: transparent;
}
    .pc {
        background-color: transparent;
    }
    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        background-color: var(--white);
        overflow-x: hidden;
        transition: 0.5s;
    }

        .sidenav a {
            display: block;
        }

            .sidenav a:hover {
                color: #f1f1f1;
            }

    @media screen and (max-height: 450px) {
        .sidenav {
            padding-top: 15px;
        }

            .sidenav a {
                font-size: 18px;
            }
    }

    .fileinput {
        display: none;
    }
    .img-preview {
        max-width: 100%;
        height: auto;
    }

    input[type="file"]#camera,
    input[type="file"]#selfie,
    input[type="file"]#frontPassport {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    .vs__dropdown-toggle {
        height: 38px;
    }

    #sketchpadapp {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    #sketchpad {
        height: 200px;
        width: 300px;
        border: 2px solid #888;
        border-radius: 4px;
        position: relative;
    }

    #newGuest header, .reserva header {
        background: none;
    }

/* Anyline */
  label {
    font-weight:500;
  }
  input[type="radio"] + label {
    margin-left:5px;
    margin-right:15px;
    font-size:14px;
    font-weight:100;
  }

  .side-navbar-custom {
    position:fixed;
    top:0;
    left:100%;
    width:100%;
    height:100vh;
    background: white;
    transition:500ms;
    z-index:10;
  }
  .side-navbar-custom.open {
    left:0;
  }
  .btn-block {
    display:block !important;
    width: 100%;
  }
  .mb-6 {
    margin-bottom:6rem;
  }
  .container-fixed-bottom {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding-top:0.25rem;
    padding-bottom:1rem;
    background: white;
  }
  #anyline {
    width:100%;
    height:100vh;
  }
  .anyline-close {
    position:absolute;
    top:15px;
    right:15px;
    width:25px;
    height:25px;
    border-radius:50%;
    text-align:center;
    background-color:white;
    z-index:99999;
  }
</style>