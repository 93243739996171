<template>
    <nav class="fixed-bottom shadow nav-fill pt-2">
        <ul class="nav nav-primary">
            <li class="nav-item">
                <router-link class="nav-link"
                    :to="{ name: 'Dashboard', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }">
                    <span><i class="fas fa-home"></i></span>
                    <span>{{ translator.inici }}</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link"
                    :to="{ name: 'ManagerBookings', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }">
                    <span><i class="fas fa-list-ul"></i></span>
                    <span>{{ translator.reserves }}</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link"
                    :to="{ name: 'ManagerAvailability', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }">
                    <span><i class="fas fa-calendar-alt"></i></span>
                    <span>{{ translator.calendari }}</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link"
                    :to="{ name: 'ManagerListChats', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }">
                    <span><i class="fas fa-comment-alt-dots"></i></span>
                    <span>{{ translator.missatges }}</span>
                </router-link>
            </li>

            <li class="nav-item">
                <div id="nav-drawer">
                    <input id="nav-input" type="checkbox" class="nav-unshown">
                    <label id="nav-open" for="nav-input" class="nav-link-submenu">
                        <span>
                            <i class="fas fa-ellipsis-h"></i>
                        </span>
                        <span>{{ translator.mas }}</span>
                    </label>
                    <label class="nav-unshown" id="nav-close" for="nav-input"></label>

                    <div id="nav-content">
                        <div class="p-3 border-bottom text-left">
                            {{ translator.mesinformacio }}
                        </div>
                        <div class="submenu">
                            <ul class="nav submenu-nav">
                                <li class="submenu-nav-item p-3 border-bottom">
                                    <router-link
                                        :to="{ name: 'ManagerBookings', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }">
                                        <i class="fal fa-list-ul"></i>
                                        {{ translator.reserves }}
                                    </router-link>
                                </li>

                                <li class="submenu-nav-item p-3 border-bottom">
                                    <router-link
                                        :to="{ name: 'ManagerArrivals', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }">
                                        <i class="fal fa-sign-in"></i>
                                        {{ translator.arribades }}
                                    </router-link>
                                </li>
                                <li class="submenu-nav-item p-3 border-bottom">
                                    <router-link
                                        :to="{ name: 'ManagerDepartures', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }">
                                        <i class="fal fa-sign-out"></i>
                                        {{ translator.sortides }}
                                    </router-link>
                                </li>

                                <li class="submenu-nav-item p-3 border-bottom">
                                    <router-link
                                        :to="{ name: 'ManagerAvailability', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }">
                                        <i class="fal fa-calendar-alt"></i>
                                        {{ translator.calendari }}
                                    </router-link>
                                </li>
                                <li class="submenu-nav-item p-3 border-bottom">
                                    <router-link
                                        :to="{ name: 'ManagerListChats', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }">
                                        <i class="fal fa-comment-alt-dots"></i>
                                        {{ translator.missatges }}
                                        Mensajes
                                    </router-link>
                                </li>
                                <li class="submenu-nav-item p-3 border-bottom">
                                    <router-link
                                        :to="{ name: 'ManagerCleaning', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }">
                                        <i class="fal fa-broom"></i>
                                        {{ translator.neteja }}
                                    </router-link>
                                </li>
                                <li class="submenu-nav-item p-3 border-bottom">
                                    <router-link
                                        :to="{ name: 'ManagerService', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }">
                                        <i class="fal fa-hammer"></i>
                                        {{ translator.manteniment }}
                                    </router-link>
                                </li>
                                <!-- <li class="submenu-nav-item p-3 border-bottom">
                                    <i class="fal fa-star"></i>
                                    Reviews (en construcción)
                                </li>
                                <li class="submenu-nav-item p-3 border-bottom">
                                    <i class="fal fa-euro-sign"></i>
                                    Precios (en construcción)
                                </li>
                                <li class="submenu-nav-item p-3 border-bottom">
                                    <i class="fal fa-bell"></i>
                                    Notificaciones (en construcción)
                                </li>
                                <li class="submenu-nav-item p-3 border-bottom">
                                    <i class="fal fa-cog"></i>
                                    Ajustes (en construcción)
                                </li> -->
                            </ul>
                        </div>

                    </div>
                </div>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'NavbarManager',
    props: ['user', 'translator', 'bookingID'],
    data() {
        return {
        }
    },
    methods: {
    }
};
</script>

<style scoped lang="scss">
.nav-fill>.nav-link,
.nav-fill .nav-item {
    width: 20%;
}

.nav-link {
    display: flex;
    flex-direction: column;
    padding: 8px;

    span {
        line-height: 20px;
    }
}

.nav i {
    font-size: 16px;
}

.router-link-exact-active {
    color: var(--azul-oscuro) !important;
    font-weight: bold;
}

/* Menú desplegable */
#nav-drawer {
    position: relative;
}

.nav-unshown {
    display: none;
}

#nav-open {
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: middle;
}

#nav-close {
    display: none;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0;
    transition: .3s ease-in-out;
}

#nav-content {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 90%;
    max-width: 330px;
    height: 100%;
    background: #fff;
    transition: .3s ease-in-out;
    -webkit-transform: translateX(-105%);
    transform: translateX(-105%);
}

#nav-input:checked~#nav-close {
    display: block;
    opacity: .5;
}

#nav-input:checked~#nav-content {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    box-shadow: 6px 0 25px rgba(0, 0, 0, .15);
}


.submenu-nav {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.nav-link-submenu {
    display: flex !important;
    padding: 8px;
    font-size: 12px;
    color: var(--colores-gris-medio) !important;
    cursor: pointer;
    flex-direction: column;

    span {
        line-height: 20px;
    }
}

.submenu-nav-item {
    color: #bdbdbd;
    font-size: 15px;
}

.submenu-nav-item a {
    color: var(--colores-gris-medio) !important;
}

.submenu-nav-item a:hover {
    text-decoration: none;
}

.submenu-nav i {
    font-size: 14px;
    width: 20px;
}
</style>