<template>
    <div class="calendario-limpieza-reservas">

        <!-- Cabecera -->
        <header class="p-3">
            <div class="row align-items-center">
                <div class="col">
                    <h1 class="m-0">
                        <router-link :to="{ name: 'ManagerCleaning', query: { id: $route.query.id } }">
                            <i class="fal fa-chevron-left"></i>
                        </router-link>
                        {{ translator.calendari }}
                    </h1>
                </div>
                <div class="col">

                </div>
            </div>
        </header>
        <main class="container pc">
            <!-- Spiner -->
            <div v-if="isPreLoad"
                style="height: 70vh; display:flex; justify-content:center; align-items:center; background-color: #f6f7f9;">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
            <!-- Fin spiner -->
            <!-- Calendario Semanal -->
            <transition v-if="!isPreLoad" name="fade" mode="out-in">
                <div class="calendario-reservas">
                    <div class="navegacion-semanas">
                        <a class="p-1" href="#" @click="lastWeek">
                            <i class="fal fa-chevron-left"></i>
                        </a>
                        <div class="semana mx-3">{{ mostrarFecha }}</div>
                        <a class="p-1" href="#" @click="nextWeek">
                            <i class="fal fa-chevron-right"></i>
                        </a>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="week-days">
                                        {{ translator.propietat }}
                                    </th>
                                    <th v-for="(dia, index) in ShowDays" :key="index" @click="reloadDay(dia)"
                                        class="week-days">
                                        <div>{{ dia.dia }}</div>
                                        {{ moment(dia.fecha).isoWeekday() | convertDayWeek(translator.weekdayShort) }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="days-container">
                                <tr v-for="prop in propiedadesVisibles" :key="prop.rowId">
                                    <td class="name"><span class="limit-text">{{ prop.nombrePropiedad }}</span></td>
                                    <td v-for="dia in prop.diasPropiedad" :key="`${dia.fecha}-${prop.nombrePropiedad}`"
                                        :class="{
                                            'entrada-reserva': dia.type == 'cleaning',
                                            'salida-cleaning': dia.type == 'checkout-cleaning',
                                            'salida-entrada': dia.type == 'checkin-checkout-cleaning',
                                            'entrada-reserva': dia.type == 'booking-checkin',
                                            'booking-checkout': dia.type == 'booking-checkout',
                                            'dia-reservado': dia.type == 'booking-occupied',
                                            'dia-libre': dia.type == 'libre',
                                            'dia-reservado-limpieza': dia.type == 'booking-occupied-cleaning',
                                            'entrada-reserva-limpieza': dia.type == 'booking-checkin-cleaning',
                                            'salida-entrada1': dia.type == 'booking-checkout-checkin'
                                        }
                                            ">

                                        <!-- Limpieza: hay una limpieza en un d�a que no hay reserva -->
                                        <router-link
                                            v-if="dia.type == 'cleaning' && (dia.estado == 'pending' || dia.estado == 'in_progress' || dia.estado == 'finished')"
                                            class="entrada-reserva-i" :class="dia.estado"
                                            :to="{ name: 'ManagerCleaningDetails', params: { cleaningDate: dia.fecha, bookingId: dia.id }, query: { id: $route.query.id } }">
                                            <div>
                                                <div class="reserva-numero">
                                                    <i v-if="dia.estado == 'finished'" class="far fa-check"></i>
                                                </div>
                                            </div>
                                        </router-link>

                                        <!-- Check-in: hay un checkin -->
                                        <div v-if="dia.type == 'booking-checkin'" class="booking-checkin">
                                            <div class="reserva-numero">
                                            </div>
                                        </div>

                                        <!-- Check-out: hay un checkout -->
                                        <div v-if="dia.type == 'booking-checkout'" class="booking-checkout-i">
                                            <div class="reserva-numero">
                                            </div>
                                        </div>

                                        <!-- Limpieza en reserva -->
                                        <router-link v-if="dia.type == 'booking-occupied-cleaning'"
                                            class="entrada-reserva-i pending" :class="dia.estado"
                                            :to="{ name: 'ManagerCleaningDetails', params: { cleaningDate: dia.fecha, bookingId: dia.id }, query: { id: $route.query.id } }">
                                            <div>
                                                <div class="reserva-numero">
                                                    <i v-if="dia.estado == 'finished'" class="far fa-check"></i>
                                                </div>
                                            </div>
                                        </router-link>

                                        <!-- Limpieza + checkin: hay una limpieza el mismo d�a de check-in -->
                                        <div v-if="dia.type == 'booking-checkin-cleaning'" class="cleaning-calendar">
                                            <div class="contenedor-numero">
                                                <router-link
                                                    :to="{ name: 'ManagerCleaningDetails', params: { cleaningDate: dia.fecha, bookingId: dia.id }, query: { id: $route.query.id } }">
                                                    <div class="reserva-numero" :class="dia.estado">
                                                        <i v-if="dia.estado == 'finished'" class="far fa-check"></i>
                                                    </div>
                                                </router-link>
                                            </div>
                                        </div>

                                        <!-- Limpieza + checkout: hay una limpieza el mismo d�a de check-out -->
                                        <div v-if="dia.type == 'checkout-cleaning'" class="salida-cleaning-i">
                                            <div class="contenedor-pending">
                                                <div :class="dia.estado">
                                                    <router-link
                                                        :to="{ name: 'ManagerCleaningDetails', params: { cleaningDate: dia.fecha, bookingId: dia.id }, query: { id: $route.query.id } }"
                                                        class="link-cleaning-checkout">
                                                        <i v-if="dia.estado == 'finished'" class="far fa-check">
                                                            <div class="i-bg"></div>
                                                        </i>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Limpieza + checkin + checkout: hay una limpieza el mismo d�a de un checkin y un checkout -->
                                        <div v-if="dia.type == 'checkin-checkout-cleaning'" class="bg">
                                            <div class="salida-entrada-1"></div>
                                            <div class="salida-entrada-2">
                                                <div class="contenedor-numero">
                                                    <router-link
                                                        :to="{ name: 'ManagerCleaningDetails', params: { cleaningDate: dia.fecha, bookingId: dia.id }, query: { id: $route.query.id } }">
                                                        <div class="reserva-numero" :class="dia.estado">
                                                            <i v-if="dia.estado == 'finished'" class="far fa-check"></i>
                                                        </div>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Checkout: hay un checkout -->
                                        <div v-if="dia.estado == 'salida-bloqueada'" class="salida-cleaning-i"></div>

                                        <!-- checkout/checkin -->
                                        <div v-if="dia.type == 'booking-checkout-checkin'" class="bg">
                                            <div class="salida-entrada-1"></div>
                                            <div class="salida-entrada-2">
                                                <div class="contenedor-numero"></div>
                                            </div>
                                        </div>


                                        <!-- Reserva: �d�nde est� el dia de reserva sin checkin ni checkout? -->

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </transition>
        </main>

        <NavbarManager :translator="translator" />

    </div>
</template>

<script>
import NavbarManager from '@/components/NavbarManager.vue';
import { mapState } from "vuex";


export default {
    name: "ManagerCleaningCalendar",
    components: {
        NavbarManager
    },
    computed: {
        ...mapState("utils", ['user', 'translator']),
        ...mapState("manager", ["cleaningsCalendar"]),
    },
    watch: {
        translator() {
            this.traductorDias = this.translator.weekdayShort;
            this.getCalendars();
            //this.$store.dispatch("manager/getCleaningsCalendar", { usr: String(this.$route.query.id).replace(/[ ]/g, "+"), data: new Date(this.moment(this.moment().startOf("isoWeek"))) });
        },
        cleaningsCalendar() {
            this.isPreLoad = false;
            this.getDays();
            // sessionStorage.setItem('listado', 'ManagerCleaningCalendar');
            // this.start();
        },
        datefrom() {
            this.getCalendars();
        }
    },
    async created() {
        try {
            this.isPreLoad = true;
            if (this.translator) {
                this.traductorDias = this.translator.weekdayShort;
                this.getCalendars();
                //await this.$store.dispatch("manager/getCleaningsCalendar", { usr: String(this.$route.query.id).replace(/[ ]/g, "+"), data: new Date(this.moment(this.moment().startOf("isoWeek"))) });
            } else {
                await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            }

        } catch (error) {
            console.error(error);
        }
    },
    data() {
        return {
            isPreLoad: false,
            ShowDays: [],
            mostrarFecha: null,
            propiedadesVisibles: [],
            datefrom: this.moment(new Date()).format('yyyy-MM-DD')
        };
    },
    methods: {

        async getCalendars() {
            this.isPreLoad = true;
            this.ShowDays = [];
            this.propiedadesVisibles = [];
            await this.$store.dispatch("manager/getCleaningsCalendar",
                {
                    usr: String(this.$route.query.id).replace(/[ ]/g, "+"),
                    data: this.moment(this.datefrom).format('yyyy-MM-DD')
                }
            );
        },
        getDays() {
            for (let x = 0; x < 7; x++) {
                let fechaInicial = this.moment(this.datefrom).clone();
                let sumDay = fechaInicial.add(x, "d");
                this.ShowDays.push({ fecha: this.moment(sumDay).format("yyyy-MM-DD"), dia: this.moment(sumDay).format("D") })
            }
            this.showDates();
            this.orderWSData();
        },
        showDates() {
            this.mostrarFecha = `${this.moment(new Date(this.ShowDays[0].fecha)).format("DD-MMM")} - ${this.moment(new Date(this.ShowDays[6].fecha)).format("DD-MMM")}`
        },
        reloadDay(day) {
            if (this.moment(new Date(day.fecha)).format('yyyy-MM-DD') != this.moment(this.datefrom).format('yyyy-MM-DD')) {
                this.datefrom = this.moment(new Date(day.fecha)).format('yyyy-MM-DD');
            }
        },
        lastWeek() {
            this.datefrom = this.moment(this.datefrom).add(-7, 'd').format('yyyy-MM-DD');
        },
        nextWeek() {
            this.datefrom = this.moment(this.datefrom).add(7, 'd').format('yyyy-MM-DD');
        },
        orderWSData() {
            this.propiedadesVisibles = []
            let recorreDiaSemana = this.moment(this.datefrom).clone();

            Object.values(this.cleaningsCalendar.lodgings).forEach(row => {
                let nombrePropiedad = row.property.lodging_name
                let rowId = row.property.lodging_id
                let diasPropiedad = []
                let is_checkOut = false;
                for (let x = 1; x <= 7; x++) {

                    let task = {}
                    let is_task = false;

                    Object.values(row.cleanings).forEach(item => {

                        // Es limpieza
                        if (item.cleaning.cleaning_id != '' && item.cleaning.cleaning_date == this.moment(recorreDiaSemana).add(x - 1, 'd').format('YYYY-MM-DD')) {
                            let type = 'cleaning'
                            //Es salida/limpieza
                            if (is_checkOut && item.booking.booking_id != '' && item.booking.booking_arrival == this.moment(recorreDiaSemana).add(x - 1, 'd').format('YYYY-MM-DD')) {
                                type = 'checkin-checkout-cleaning';
                            } else if (is_checkOut) {
                                type = 'checkout-cleaning';
                            } else if (item.booking.booking_id != '' && item.booking.booking_arrival == this.moment(recorreDiaSemana).add(x - 1, 'd').format('YYYY-MM-DD')) {
                                type = 'booking-checkin-cleaning';
                            }

                            task = {
                                estado: item.cleaning.cleaning_status,
                                id: item.cleaning.cleaning_id,
                                person: item.cleaning.cleaning_person,
                                type,
                                prop_name: row.property.lodging_name,
                                prop_id: row.property.lodging_id,
                                fecha: this.moment(recorreDiaSemana).add(x - 1, 'd').format('YYYY-MM-DD')
                            }
                            is_task = true;
                        }

                        // Es entrada/salida sin limpieza
                        if (item.cleaning.cleaning_id == '' && item.booking.booking_id != '' && is_checkOut && item.booking.booking_arrival == this.moment(recorreDiaSemana).add(x - 1, 'd').format('YYYY-MM-DD')) {
                            task = {
                                estado: '',
                                id: item.booking.booking_id,
                                person: '',
                                type: 'booking-checkout-checkin',
                                prop_name: row.property.lodging_name,
                                prop_id: row.property.lodging_id,
                                fecha: this.moment(recorreDiaSemana).add(x - 1, 'd').format('YYYY-MM-DD')
                            }
                            is_task = true;
                        }

                        //si en al dia siguiente no ha sido reserva quitamos la posibilidad de salida/limpieza
                        is_checkOut ? is_checkOut = false : null;

                        // Es Checkin
                        if (!is_task && item.booking.booking_id != '' && item.booking.booking_arrival == this.moment(recorreDiaSemana).add(x - 1, 'd').format('YYYY-MM-DD')) {
                            task = {
                                estado: '',
                                id: item.booking.booking_id,
                                person: '',
                                type: 'booking-checkin',
                                prop_name: row.property.lodging_name,
                                prop_id: row.property.lodging_id,
                                fecha: this.moment(recorreDiaSemana).add(x - 1, 'd').format('YYYY-MM-DD')
                            }
                            is_task = true;
                        }

                        // Reserva
                        if (item.booking.booking_id != '' && (this.moment(item.booking.booking_departure).format('YYYY-MM-DD') > this.moment(recorreDiaSemana).add(x - 1, 'd').format('YYYY-MM-DD') && item.booking.booking_arrival < this.moment(recorreDiaSemana).add(x - 1, 'd').format('YYYY-MM-DD'))) {
                            task = {
                                estado: '',
                                id: item.booking.booking_id,
                                person: '',
                                type: 'booking-occupied',
                                prop_name: row.property.lodging_name,
                                prop_id: row.property.lodging_id,
                                fecha: this.moment(recorreDiaSemana).add(x - 1, 'd').format('YYYY-MM-DD')
                            }
                            // dia ocupado con limpieza
                            if (item.cleaning.cleaning_id != '' && item.cleaning.cleaning_date == this.moment(recorreDiaSemana).add(x - 1, 'd').format('YYYY-MM-DD')) {
                                task = {
                                    estado: item.cleaning.cleaning_status,
                                    id: item.cleaning.cleaning_id,
                                    person: '',
                                    type: 'booking-occupied-cleaning',
                                    prop_name: row.property.lodging_name,
                                    prop_id: row.property.lodging_id,
                                    fecha: this.moment(recorreDiaSemana).add(x - 1, 'd').format('YYYY-MM-DD')
                                }
                            }
                            is_task = true;
                        }

                        // Es checkout
                        if (item.booking.booking_id != '' && item.booking.booking_departure == this.moment(recorreDiaSemana).add(x - 1, 'd').format('YYYY-MM-DD')) {

                            task = {
                                estado: '',
                                id: item.booking.booking_id,
                                person: '',
                                type: 'booking-checkout',
                                prop_name: row.property.lodging_name,
                                prop_id: row.property.lodging_id,
                                fecha: this.moment(recorreDiaSemana).add(x - 1, 'd').format('YYYY-MM-DD')
                            }
                            is_task = true;
                            is_checkOut = true;
                        }
                    })

                    if (!is_task) {
                        task = {
                            estado: '',
                            id: '',
                            person: '',
                            type: 'libre',
                            prop_name: row.property.lodging_name,
                            prop_id: row.property.lodging_id,
                            fecha: this.moment(recorreDiaSemana).add(x - 1, 'd').format('YYYY-MM-DD')
                        }
                        diasPropiedad.push(task);
                    } else {
                        diasPropiedad.push(task);
                    }
                }
                this.propiedadesVisibles.push({ nombrePropiedad, rowId, diasPropiedad });
            })
        },
    },
    filters: {
        toLower(value) {
            if (!value) return "";
            value = value.toString();
            return value.toLowerCase();
        },
        convertDayWeek(date, days) {
            if (!date) return "";
            date = date == 7 ? days[0] : days[parseInt(date)];
            return date;
        },
    },
};
</script>

<style lang="scss">
.calendario-limpieza-reservas .container-nav-top {
    margin: 1rem auto;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        text-decoration: none;
        font-size: 1.5rem;
        align-self: flex-start;

        &:hover {
            color: lighten($color: #0c2c40, $amount: 15);
        }
    }

    .semana,
    a {
        color: var(--azul-oscuro);
    }
}




.calendario-reservas .table {
    width: 100% !important;
}

.calendario-reservas thead {
    background-color: #ebeff3;
}

.calendario-reservas .td-vacio {
    min-width: 110px;
}

.calendario-reservas th,
.calendario-reservas td {
    border: 1px solid #e3e3e3;
}

.calendario-reservas .entrada-reserva-i {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--colores-gris-medio);
}

.calendario-reservas .entrada-reserva-i .reserva-numero {
    position: inherit;
    left: auto;
    top: auto;
}

.calendario-reservas .entrada-reserva-i .fa-lg {
    font-size: 1.3rem;
    background-color: #FFF;
    border-radius: 500rem;
}

.calendario-reservas .entrada-reserva-i:before,
.calendario-reservas .entrada-reserva-i:after {
    border-color: #FFF;
    content: none;
}

.calendario-reservas .pending {
    background-color: var(--red);
    border-radius: 20px;
    margin-top: 3px;
    min-height: 24px;
}

.calendario-reservas .in-progress {
    background-color: var(--colores-amarillo-2);
    border-radius: 20px;
    margin-top: 3px;
}

.calendario-reservas .finished {
    background-color: var(--colores-verde-check);
    border-radius: 20px;
}

.calendario-reservas img {
    width: 6px;
    padding-bottom: 5px;
}

.calendario-reservas .dia-reservado,
.calendario-reservas .dia-reservado-limpieza {
    background-color: #a6b0a1;
    border-left: 1px solid #a6b0a1;
    border-right: 1px solid #a6b0a1;
}

.calendario-limpieza-reservas .pc {
    background: transparent;
}

.calendario-limpieza-reservas td {
    /*height: 38px;*/
}

.calendario-limpieza-reservas .entrada-reserva {
    position: relative;
    border-left: 1px solid #a6b0a1;
    border-right: 1px solid #a6b0a1;
}

.calendario-limpieza-reservas .calendario-reservas .booking-checkin {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #a6b0a1;
}

.calendario-limpieza-reservas .calendario-reservas .booking-checkin:before {
    /*top: 0;
            border-color: #9acc83 transparent transparent #a6b0a1;
            border-width: 34px 16px 0 0;*/
}

.calendario-limpieza-reservas .calendario-reservas .entrada-reserva-i:before {
    border-color: transparent;
}

/* .calendario-limpieza-reservas .calendario-reservas .booking-checkin:before,*/
.calendario-limpieza-reservas .booking-checkin:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
}

.calendario-limpieza-reservas .calendario-reservas .entrada-reserva-i:before,
.calendario-limpieza-reservas .booking-checkin:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
}

.calendario-limpieza-reservas .calendario-reservas .booking-checkin:after {
    top: 0;
    border-color: #f8f9fa transparent transparent #f8f9fa;
    border-width: 31px 20px 0 0;
}

.calendario-limpieza-reservas .booking-checkin .reserva-numero {
    position: absolute;
    left: 13px;
    top: 6px;
    font-size: 12px;
    letter-spacing: -1px;
    font-weight: 500;
    color: var(--azul-oscuro);
    z-index: 2;
}

.calendario-limpieza-reservas .calendario-reservas .salida-cleaning {
    position: relative;
    border-left: 1px solid #a6b0a1;
}

.calendario-limpieza-reservas .calendario-reservas .salida-cleaning-i {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.calendario-limpieza-reservas .calendario-reservas .salida-cleaning-i:before,
.calendario-limpieza-reservas .calendario-reservas .salida-cleaning-i:after {
    top: 0;
    border-color: #a6b0a1 transparent transparent #a6b0a1;
    border-width: 31px 20px 0 0;
}

.calendario-limpieza-reservas .calendario-reservas .salida-cleaning-i:before,
.calendario-limpieza-reservas .salida-cleaning-i:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
}

.calendario-limpieza-reservas .contenedor-pending {
    width: 100%;
    height: 100%;
    padding: 6px;
    margin: 0 auto;
}

.calendario-limpieza-reservas .link-cleaning-checkout {
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-decoration: none;
}

.calendario-limpieza-reservas .link-cleaning-checkout .fas {
    margin-top: 3px;
}

.calendario-limpieza-reservas .calendario-reservas .salida-entrada,
.calendario-limpieza-reservas .calendario-reservas .salida-entrada1 {
    position: relative;
    border-left: 1px solid #a6b0a1;
    border-right: 1px solid #a6b0a1;
}

.calendario-limpieza-reservas .calendario-reservas td {
    max-width: 31px;
    /*height: 39px;*/
}

.calendario-limpieza-reservas .calendario-reservas .salida-entrada .bg,
.calendario-limpieza-reservas .calendario-reservas .salida-entrada1 .bg {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
    right: 0;
    bottom: 0;
}

.calendario-reservas .entrada-reserva {
    /*background: #a6b0a1;*/
    background: transparent;
}

.calendario-reservas .salida-entrada-2,
.calendario-reservas .entrada-reserva-i-limpieza {
    width: 100%;
    height: 100%;
    position: relative;
}

.calendario-limpieza-reservas .calendario-reservas .salida-entrada-2 .contenedor-numero,
.calendario-limpieza-reservas .calendario-reservas .entrada-reserva-i-limpieza .contenedor-numero {
    width: 100%;
    height: 100%;
    padding: 6px;
    position: relative;
    z-index: 2;
}

.calendario-limpieza-reservas .calendario-reservas .salida-entrada-2 .contenedor-numero .reserva-numero,
.calendario-limpieza-reservas .calendario-reservas .entrada-reserva-i-limpieza .contenedor-numero .reserva-numero {
    width: 24px;
    min-height: 24px;
    margin: auto;
    border-radius: 500px;
    text-align: center;
}

.reserva-numero a i {
    color: #eee;
}

.calendario-limpieza-reservas .calendario-reservas .salida-entrada-1:after {
    top: 0;
    border-color: #a6b0a1 transparent transparent #a6b0a1;
    border-width: 31px 20px 0 0;
}

.calendario-limpieza-reservas .calendario-reservas .salida-entrada-2:before,
.calendario-limpieza-reservas .salida-entrada-2:after {
    content: '';
    position: absolute;
    display: block;
    left: unset;
    top: unset;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
}

.calendario-limpieza-reservas .calendario-reservas .salida-entrada-1:before {
    top: 0;
    border-color: #a6b0a1 transparent transparent #a6b0a1;
    border-width: 31px 20px 0 0;
}

.calendario-limpieza-reservas .calendario-reservas .salida-entrada-2:before {
    bottom: 0;
    border-color: transparent #a6b0a1 #a6b0a1 transparent;
    border-width: 31px 20px 0 0;
}

.calendario-limpieza-reservas .calendario-reservas .salida-entrada-2:after {
    bottom: 0;
    border-color: transparent #a6b0a1 #a6b0a1 transparent;
    border-width: 31px 20px 0 0;
}

.calendario-limpieza-reservas .calendario-reservas .salida-entrada-1:before,
.salida-entrada-1:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
}

.calendario-reservas .salida-entrada {
    background: transparent;
}

.calendario-limpieza-reservas .finished,
.calendario-limpieza-reservas .in_progress,
.calendario-limpieza-reservas .pending {
    position: relative;
    z-index: 2;
    width: 24px;
    height: 24px;
    margin: 0 auto;
}

.calendario-limpieza-reservas .finished {
    background-color: var(--colores-verde-check);
    color: #fff;
}

.calendario-limpieza-reservas .in_progress {
    background-color: var(--colores-amarillo-2);
    border-radius: 20px;
}

.calendario-limpieza-reservas .finished .reserva-numero i {
    color: #eee;
}

.calendario-limpieza-reservas .calendario-reservas .entrada-reserva-limpieza {
    position: relative;
    border-left: 1px solid #a6b0a1;
    border-right: 1px solid #a6b0a1;
}

.calendario-limpieza-reservas .calendario-reservas .entrada-reserva-i-limpieza {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #a6b0a1;
}

.calendario-limpieza-reservas .calendario-reservas .entrada-reserva-i-limpieza:before {
    top: 0;
    border-color: #9acc83 transparent transparent #a6b0a1;
    border-width: 31px 16px 0 0;
}

.calendario-limpieza-reservas .calendario-reservas .entrada-reserva-i-limpieza:after {
    top: 0;
    border-color: #f8f9fa transparent transparent #f8f9fa;
    border-width: 30px 15px 0 0;
}

.calendario-limpieza-reservas .calendario-reservas .entrada-reserva-i-limpieza:before,
.calendario-limpieza-reservas .entrada-reserva-i-limpieza:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
}

.calendario-limpieza-reservas .calendario-reservas .name {
    min-width: 110px;
    height: 32px;
    overflow: hidden;
    font-size: 14px;
    font-weight: normal;
}

.calendario-limpieza-reservas .calendario-reservas .week-days {
    width: 33px;
    max-width: 33px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #9ba0ab;
    line-height: 18px;
}

.calendario-limpieza-reservas .calendario-reservas .week-days div {
    font-size: 14px;
}

.calendario-limpieza-reservas .booking-checkout {
    background: #a6b0a1;
    background: linear-gradient(123deg, #a6b0a1 35%, #f6f7f9 0);
}

.limit-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: initial;
}

@media (max-width: 800px) {

    .calendario-limpieza-reservas .name,
    .calendario-limpieza-reservas .week-days {
        font-size: 12px;
    }

    .calendario-limpieza-reservas table {
        width: 97% !important;
    }
}
</style>