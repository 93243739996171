<template>
    <div>

        <!-- Cabecera -->
        <header class="p-3">
            <div class="row align-items-center">
                <div class="col-6">
                    <h1 v-cloak>{{ translator.manteniment }}</h1>
                </div>
                <div class="col-6 text-right">
                    <span class="px-2 py-1 vista active"><i class="fal fa-list-ul"></i></span>
                    <router-link :to="{ name: 'ManagerServiceCalendar', query: { id: $route.query.id }}" class="ml-2 px-2 py-1 vista"><i class="fal fa-calendar-alt"></i></router-link>
                </div>
            </div>
        </header>

        <main class="container">

                <!-- Spiner -->
                <div v-if="isPreLoad" style="height: 90vh; display:flex; justify-content:center; align-items:center;">
                    <div class="hollow-dots-spinner">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                </div>
                <!-- Fin spiner -->

                <div v-if="!isPreLoad">
                    <table class="table table-striped tabla-incidencias" style="font-size: 14px;">
                        <thead>
                            <tr>
                                <th scope="col">{{ translator.data }}/{{ translator.propietat | toLower }}</th>
                                <th scope="col">{{ translator.incidencia }}</th>
                            </tr>
                        </thead>
                        <tbody v-cloak>
                            <router-link :to="{ name: 'ManagerServiceDetail', params: { service: service.service_id }, query: { id: $route.query.id }}"
                                         v-for="(service, index) in services" :key="index" custom v-slot="{ navigate }" class="router-link">
                                <tr @click="navigate" role="link">
                                    <td>
                                        <small>
                                            {{ service.service_date | transformDate }}
                                        </small>                                        
                                        <br>
                                        {{ service.lodging_name }}
                                    </td>
                                    <td style="vertical-align:inherit;">
                                        <div style="display:flex; justify-content:space-between; align-items:center;">
                                            <span>{{ service.service_headline }}</span>
                                            <i class="fal fa-chevron-right" style="line-height: inherit;"></i>
                                        </div>
                                    </td>
                                </tr>
                            </router-link>
                        </tbody>
                    </table>
                </div>
            
        </main>

        <NavbarManager :translator="translator" />
    </div>
</template>

<script>
    import NavbarManager from '@/components/NavbarManager.vue';
    import { mapState } from "vuex";

    export default {
        name: 'ManagerService',
        components: {
            NavbarManager
        },
        computed: {
            ...mapState("utils", ["user", "translator"]),
            ...mapState("manager", ["services"]),
        },
        watch: {
            services() {
                this.isPreLoad = false;
            }
        },
        async created() {
            try {
                this.isPreLoad = true
                await this.$store.dispatch("utils/getUser", { usr: this.$route.query.id });
                await this.$store.dispatch("manager/getService", { usr: this.$route.query.id });

            } catch (error) {
                console.error(error);
            }
        },
        data() {
            return {
                isPreLoad: false,
            };
        },
        methods: {},
        filters: {
            transformDate(value) {
                if (!value) return '';
                const backendDate = value.split('-');
                return `${backendDate[2]} ${backendDate[1]} ${backendDate[0]}`;
            },
            toLower(value) {
                if (!value) return "";
                value = value.toString();
                return value.toLowerCase();
            },
        }
    }
</script>

<style lang="scss" scoped>

    .container-nav-top {
        height: 50px;
        margin: 1rem auto;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            color: var(--azul-oscuro);
            text-decoration: none;
            font-size: 1.5rem;
            align-self: flex-start;

            &:hover {
                color: lighten($color: #0c2c40, $amount: 15);
            }
        }
    }


    .vista {
        color: #fff;
    }

    .vista.active {
        border-radius: 2px;
        background: #fff;
        color: var(--azul-oscuro);
    }

    .router-link {
        transition: box-shadow .25s;
        cursor: pointer;

        &:hover {
            transition: box-shadow .2s;
            box-shadow: 0 0.125rem 0.45rem rgba(0, 0, 0, .2) !important;
        }
    }

    .table th, .table td {
        padding: 0.5rem;
    }

    th {
        cursor: pointer;
    }

    .table-striped tbody tr:nth-of-type(2n+1) {
        background-color: #fff;
    }

</style>