<template>
  <div class="manager-booking">

        <!-- Cabecera -->
        <header class="p-3">
          <div class="row align-items-center justify-content-between">
            <div class="col-auto">
              <h1 @click="routerLink" class="titular-principal">
                <i class="fal fa-chevron-left mr-3"></i>
                  {{ translator.reserva }} {{ booking.reservation }}
              </h1>
            </div>
            <div class="col-auto">
              <button class="btn btn-primary" @click="modificarReserva">{{ translator.modificarreserva }}</button>
            </div>
          </div>
        </header>


        <main class="container pc reserva">
        
          <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
              <div class="hollow-dots-spinner">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            </div>
          </div>
        
          <div v-if="booking.arrival_date">
        
            <label>
              {{ translator.propietat }}
            </label>
            {{ booking.lodging_name }}
        
            <div class="row my-3">
              <div class="col-6" v-cloak>
                <label>
                  {{ translator.arribada }}
                </label>
                <div class="data-arribada">{{ booking.arrival_date | transformDate}}</div>
                <div class="hora-arribada">{{ booking.arrival_time }} <span v-if="booking.arrival_time != ''">h</span></div>
              </div>
              <div class="col-6 border-left" v-cloak>
                <label v-cloak>
                  {{ translator.sortida }}
                </label>
                <div class="data-sortida">{{ booking.departure_date | transformDate}}</div>
                <div class="hora-sortida">{{ booking.departure_time }} <span v-if="booking.departure_time != ''">h</span></div>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label v-cloak>
                  {{ translator.nits }}
                </label>
              </div>
              <div class="col-9 nits" v-if="booking.nights == 1" v-cloak>
                {{ booking.nights }} {{ translator.nit | toLower }}
              </div>
              <div class="col-9 nits" v-else v-cloak>
                {{ booking.nights }} {{ translator.nits | toLower }}
              </div>
            </div>
            <div class="row" v-if="booking.agency_name" v-cloak>
              <div class="col-3">
                <label v-cloak>
                  {{ translator.origen }}
                </label>
              </div>
              <div class="col-9 agencia" v-cloak>
                {{ booking.agency_name }} <span v-if="booking.agency_reservation">- ID {{ booking.agency_reservation }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-3">
                <label v-cloak>
                  {{ translator.telefon }}
                </label>
              </div>
              <div class="col-9" v-cloak>
                <a :href="'tel:' + booking.customer_phone" class="telefon">{{ booking.customer_phone }}</a>
              </div>
            </div>
            <div class="mb-3">
        
              <routerLink class="input-group mb-2 mr-sm-2 router-link" :class="
                                booking.customer_address == '' ||
                                booking.customer_city == '' ||
                                booking.customer_country == '' ||
                                booking.customer_email == '' ||
                                booking.customer_first_name == '' ||
                                booking.customer_last_name == '' ||
                                booking.customer_phone == '' ||
                                booking.customer_tin == '' ||
                                booking.customer_zip == '' 
                                ? '' : 'completed' 
                                "
                :to="{ name: 'ManagerBookingDetails', params: { bookingId: booking.reservation }, query: { id: $route.query.id }}">
                <div class="input-group-prepend">
                  <div class="input-group-text" v-cloak>
                    <i v-if="!booking._registerDocument" class="fas fa-exclamation-triangle">
                    </i>
                    <i v-else class="fas fa-check-circle"></i>
        
                    {{ translator.reserva }}
                  </div>
                </div>
                <div class="input-pasos-reserva input-group-text d-flex justify-content-between" v-cloak>
                  <span>{{ translator.dadesreserva }}</span>
                  <i class="far fa-long-arrow-right"></i>
                </div>
              </routerLink>
        
              <!-- Hostes registrats -->
              <routerLink
                :to="{ name: 'ManagerBookingGuests', params: { bookingId: booking.reservation }, query: { id: $route.query.id }}"
                class="input-group mb-2 mr-sm-2 router-link"
                :class="{ completed: booking.registered_guests != 0 && booking.guestsCompleted, almost: booking.mandatory_signatures_contract == 'true' && !controlAdultWithSign && booking.guestsCompleted }">
                <div class="input-group-prepend">
                  <div class="input-group-text" v-cloak>
        
                    <i v-if="booking.mandatory_signatures_contract == 'true' && !controlAdultWithSign && booking.guestsCompleted"
                      class="fas fa-check-circle icono-completo" style="color:var(--colores-amarillo-2);"></i>
                    <i v-else-if="booking.registered_guests == '0' || !booking.guestsCompleted"
                      class="fas fa-exclamation-triangle"></i>
                    <i v-else class="fas fa-check-circle"></i>
                    {{ translator.hostes }}
                  </div>
                </div>
                <div class="input-pasos-reserva input-group-text d-flex justify-content-between" v-cloak>
                  <span>{{ booking.registered_guests }}/{{ booking.sumAdultsChildren }} {{ translator.registrats | toLower
                    }}</span>
                  <i class="far fa-long-arrow-right"></i>
                </div>
              </routerLink>
        
              <!-- Pagament -->
              <router-link
                :to="{ name: 'ManagerBookingPayment', params: { bookingId: booking.reservation }, query: { id: $route.query.id }}"
                class="input-group mb-2 mr-sm-2 router-link"
                v-bind:class="{ completed: booking.paymentCompleted && bookingPayments.deposit_paid == 'true', almost: booking.paymentCompleted && bookingPayments.deposit_paid == 'false'}">
                <div class="input-group-prepend">
                  <div class="input-group-text" v-cloak>
                    <i v-if="!booking.paymentCompleted" class="fas fa-exclamation-triangle"></i>
                    <i v-else-if="booking.paymentCompleted && bookingPayments.deposit_paid == 'false'"
                      class="fas fa-check-circle" style="color:var(--colores-amarillo-2);"></i>
                    <i v-else-if="booking.paymentCompleted && bookingPayments.deposit_paid == 'true'"
                      class="fas fa-check-circle"></i>
                    {{ translator.pagament }}
                  </div>
                </div>
                <div class="input-pasos-reserva input-group-text d-flex justify-content-between" v-cloak>
                  <span v-if="booking.paymentCompleted && bookingPayments.deposit_paid == 'true'">{{ translator.pagat }}</span>
                  <span v-else-if="booking.paymentCompleted && bookingPayments.deposit_paid == 'false'">{{ translator.diposit
                    }}</span>
                  <span v-else>{{ booking.pending_payment_status }}</span>
                  <i class="far fa-long-arrow-right"></i>
                </div>
              </router-link>
        
              <!-- contracte -->
              <router-link
                :to="{ name: 'ManagerBookingContract', params: { bookingId: booking.reservation }, query: { id: $route.query.id }}"
                class="input-group mb-2 mr-sm-2 router-link" v-bind:class="{ completed: booking.contractCompleted }">
                <div class="input-group-prepend">
                  <div class="input-group-text" v-cloak>
                    <i v-if="!booking.contractCompleted" class="fas fa-exclamation-triangle"></i>
                    <i v-if="booking.contractCompleted" class="fas fa-check-circle"></i>
                    {{ translator.contracte }}
                  </div>
                </div>
                <div class="input-pasos-reserva input-group-text d-flex justify-content-between" v-cloak>
        
                  <span v-if="booking.contractCompleted">{{ translator.signat }}</span>
                  <span v-if="!booking.contractCompleted">{{ translator.nosignat }}</span>
                  <i class="far fa-long-arrow-right"></i>
                </div>
              </router-link>
              <!-- //// -->
              <!-- \\ Ver con check-in confirmado \\ -->
              <div v-if="booking.checkinCompleted" class="input-group mb-2 mr-sm-2"
                v-bind:class="{ completed: booking.checkinCompleted }">
                <div class="input-group-prepend">
                  <div class="input-group-text" v-cloak>
                    <i class="fas fa-check-circle"></i>
                    {{ translator.checkin }}
                  </div>
                </div>
                <div class="input-pasos-reserva d-flex justify-content-between" v-cloak>
                  <span v-if="booking.signed_contract">{{ translator.completat }}</span>
                </div>
              </div>
              <!-- \\\\ -->
        
              <!-- // Ver con check-out confirmado // -->
              <div v-if="booking.status > '6'" class="input-group mb-2 mr-sm-2" v-bind:class="{ completed: booking.status > 6}">
                <div class="input-group-prepend">
                  <div class="input-group-text" v-cloak>
                    <i class="fas fa-check-circle"></i>
                    {{ translator.checkout }}
                  </div>
                </div>
                <div class="input-pasos-reserva d-flex justify-content-between" v-cloak>
                  <span>{{ translator.completat }}</span>
                </div>
              </div>
              <!-- //// -->
        
              <div v-if="errorFormBooking.length > 0" class="alert alert-danger mt-4" role="alert">
                <p>
                  {{ translator.faltacompletardadesreserva }}
                </p>
              </div>
        
              <!-- Botón ckeck-in -->
              <div v-if="!booking.checkinCompleted">
                <a v-if="
                                    booking._registerDocument &&
                                    ( booking.registered_guests >= booking.adults || booking.mandatory_guests_at_check_in == 'false' ) && 
                                    ( booking.contractCompleted || booking.mandatory_signature_at_check_in == 'false' ) &&
                                    ( booking.paymentCompleted || booking.mandatory_payment_at_check_in == 'false' )
                                    " @click.prevent.self="validateFormBooking('checkIn')" class="btn btn-primary" v-cloak>{{
                  translator.checkin }}</a>
                <a v-else class="btn btn-primary disabled" disabled v-cloak>{{ translator.checkin }}</a>
              </div>
        
              <button v-if="booking.checkinCompleted && !booking.checkOutInTime && parseInt(booking.status) <= 6"
                class="btn btn-primary" disabled>{{ translator.checkout }}</button>
              <button v-else-if="booking.checkinCompleted && booking.checkOutInTime && parseInt(booking.status) <= 6"
                @click.prevent.self="checkOut" class="btn btn-primary">{{ translator.checkout }}</button>
        
            </div>
            <div v-if="booking.arrival_remarks != ''">
              <label v-cloak>
                {{ translator.observacionsentrada }}
              </label>
              <p v-cloak>
                {{ booking.arrival_remarks }}
              </p>
            </div>
            <div v-if="booking.status >= '6' && booking.departure_remarks != ''">
              <label v-cloak>
                {{ translator.observacionssortida }}
              </label>
              <p v-cloak>
                {{ booking.departure_remarks }}
              </p>
            </div>
          </div>
        </main>


        <!-- Modal reserva-->
      <div class="modal fade" id="modalCenter1" tabindex="-1" role="dialog" aria-labelledby="modalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalLongTitle">{{ booking.lodging_name }} - {{ booking.lodging_id }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

              <!-- Reserva form -->
              <div class="row">
                <div class="col-7 mb-3">
                  <label for="selectDate" class="form-label">{{ translator.checkin}}</label>
                  <input type="date" id="selectDate" v-model="newBooking.selectDay" class="form-control">
                </div>
                <div class="col-5 mb-3">
                  <label for="numberNights" class="form-label">{{ translator.nnits }}</label>
                  <input type="number" id="numberNights" v-model="newBooking.numberNights" class="form-control">
                </div>
                <div class="col-12 mb-3">
                  <label for="name" class="form-label">{{ translator.nom }}</label>
                  <input v-model="newBooking.name" type="text" class="form-control" id="name">
                </div>
                <div class="col-12 mb-3">
                  <label for="surname" class="form-label">{{ translator.cognoms }}</label>
                  <input v-model="newBooking.surname" type="text" class="form-control" id="surname">
                </div>
                <div class="col-12 mb-3">
                  <label for="email" class="form-label">{{ translator.email }}</label>
                  <input v-model="newBooking.email" type="email" class="form-control" id="email">
                </div>
                <div class="col-12 mb-3">
                  <label for="phone" class="form-label">{{ translator.telefon }}</label>
                  <input v-model="newBooking.phone" type="tel" class="form-control" id="phone">
                </div>
                <div class="col-4 mb-3">
                  <label for="adults" class="form-label">{{ translator.adults }}</label>
                  <input v-model="newBooking.adults" type="number" class="form-control" id="adults">
                </div>
                <div class="col-4 mb-3">
                  <label for="children" class="form-label">{{ translator.nens }}</label>
                  <input v-model="newBooking.children" type="number" class="form-control" id="children">
                </div>
                <div class="col-4 mb-3">
                  <label for="babies" class="form-label">{{ translator.bebes }}</label>
                  <input v-model="newBooking.babies" type="number" class="form-control" id="babies">
                </div>
                <div class="col-12 mb-3">
                  <label for="remarks" class="form-label">{{ translator.observacions }}</label>
                  <div class="input-group">
                    <textarea v-model="newBooking.remarks" class="form-control" id="remarks"></textarea>
                  </div>
                </div>

                <div class="col-6 mb-3 hide-arrow">
                  <label for="estada" class="form-label">{{ translator.estada }}</label>
                  <div class="input-group">
                    <input v-model="newBooking.estada" type="number" class="form-control" id="estada">
                    <span class="input-group-text" style="border-right:1px solid #ced4da">{{ booking.currency }}</span>
                  </div>
                </div>
                <div class="col-6 mb-3 hide-arrow">
                  <label for="diposit" class="form-label">{{ translator.diposit }}</label>
                  <div class="input-group">
                    <input v-model="newBooking.diposit" type="number" class="form-control" id="diposit">
                    <span class="input-group-text" style="border-right:1px solid #ced4da">{{ booking.currency }}</span>
                  </div>
                </div>

                <div class="col-6 mb-3 hide-arrow" v-if="bookingPayments.cleaning_fee_visible == 'true'">
                  <label for="cleaningFee" class="form-label">{{ translator.carrecneteja }}</label>
                  <div class="input-group">
                    <input v-model="newBooking.cleaningFee" type="number" class="form-control" id="cleaningFee">
                    <span class="input-group-text" style="border-right:1px solid #ced4da">{{ booking.currency }}</span>
                  </div>
                </div>
                <div class="col-6 mb-3 hide-arrow" v-if="bookingPayments.booking_fee_visible == 'true'">
                  <label for="booking_fee" class="form-label">{{ translator.carrecgestio }}</label>
                  <div class="input-group">
                    <input v-model="newBooking.booking_fee" type="number" class="form-control" id="booking_fee">
                    <span class="input-group-text" style="border-right:1px solid #ced4da">{{ booking.currency }}</span>
                  </div>
                </div>
                
                <div class="col-6 mb-3 hide-arrow" v-if="bookingPayments.checkin_fee_visible == 'true'">
                  <label for="checkin_fee" class="form-label">{{ translator.carrecentrada }}</label>
                  <div class="input-group">
                    <input v-model="newBooking.checkin_fee" type="number" class="form-control" id="checkin_fee">
                    <span class="input-group-text" style="border-right:1px solid #ced4da">{{ booking.currency }}</span>
                  </div>
                </div>
                <div class="col-6 mb-3 hide-arrow" v-if="bookingPayments.tourist_tax_visible == 'true'">
                  <label for="tourist_tax" class="form-label">{{ translator.taxaturistica }}</label>
                  <div class="input-group">
                    <input v-model="newBooking.tourist_tax" type="number" class="form-control" id="tourist_tax">
                    <span class="input-group-text" style="border-right:1px solid #ced4da">{{ booking.currency }}</span>
                  </div>
                </div>
                
                <div class="col-6 mb-3 hide-arrow" v-if="bookingPayments.standard_discount_visible == 'true'">
                  <label for="standard_discount" class="form-label">{{ translator.descompte }}</label>
                  <div class="input-group">
                    <input v-model="newBooking.standard_discount" type="number" class="form-control" id="standard_discount">
                    <span class="input-group-text" style="border-right:1px solid #ced4da">{{ booking.currency }}</span>
                  </div>
                </div>
                <div class="col-6 mb-3 hide-arrow" v-if="bookingPayments.promotion_discount_visible == 'true'">
                  <label for="promotion_discount" class="form-label">{{ translator.descomptepromocio }}</label>
                  <div class="input-group">
                    <input v-model="newBooking.promotion_discount" type="number" class="form-control" id="promotion_discount">
                    <span class="input-group-text" style="border-right:1px solid #ced4da">{{ booking.currency }}</span>
                  </div>
                </div>

                <div class="col-6 mb-3 hide-arrow" v-if="bookingPayments.earlybooking_discount_visible == 'true'">
                  <label for="earlybooking_discount" class="form-label">{{ translator.descompteanticipat }}</label>
                  <div class="input-group">
                    <input v-model="newBooking.earlybooking_discount" type="number" class="form-control" id="earlybooking_discount">
                    <span class="input-group-text" style="border-right:1px solid #ced4da">{{ booking.currency }}</span>
                  </div>
                </div>
                <div class="col-6 mb-3 hide-arrow" v-if="bookingPayments.longstay_discount_visible == 'true'">
                  <label for="longstay_discount" class="form-label">{{ translator.descomptellargaestada }}</label>
                  <div class="input-group">
                    <input v-model="newBooking.longstay_discount" type="number" class="form-control" id="longstay_discount">
                    <span class="input-group-text" style="border-right:1px solid #ced4da">{{ booking.currency }}</span>
                  </div>
                </div>

                <div class="col-6 mb-3 hide-arrow" v-if="bookingPayments.shortstay_fee_visible == 'true'">
                  <label for="shortstay_fee" class="form-label">{{ translator.carreccurtaestada }}</label>
                  <div class="input-group">
                    <input v-model="newBooking.shortstay_fee" type="number" class="form-control" id="shortstay_fee">
                    <span class="input-group-text" style="border-right:1px solid #ced4da">{{ booking.currency }}</span>
                  </div>
                </div>
                <div class="col-6 mb-3 hide-arrow" v-if="bookingPayments.finance_fee_visible == 'true'">
                  
                  <label for="finance_fee" class="form-label">{{ translator.carrecfinancer }}</label>
                  <div class="input-group"> 
                    <input v-model="newBooking.finance_fee" type="number" class="form-control" id="finance_fee">
                    <span class="input-group-text" style="border-right:1px solid #ced4da">{{ booking.currency }}</span>
                  </div>
                </div>

              </div>

              <div v-if="error.length > 0" class="alert alert-danger" role="alert">
                <p v-for="(err, index) in error" :key="index">{{ err }}</p>
              </div>

            </div>
            <div class="modal-footer">
              <button @click="saveBooking" type="button" class="btn btn-primary">{{ translator.guardar }}</button>
            </div>
          </div>
        </div>
      </div>

        <NavbarManager :translator="translator" />

    </div>
</template>

<script>
import NavbarManager from "@/components/NavbarManager.vue";
import { mapState } from "vuex";
const $ = require("jquery");

export default {
    name: 'ManagerBooking',
    components: {
        NavbarManager,
    },
    computed: {
        ...mapState("utils", ["user", "translator"]),
        ...mapState("manager", ['booking', 'bookingGuests', 'bookingPayments']),
    },
    watch: {
        translator() {
            this.$store.dispatch( "manager/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId });
        },
        booking() {
            this.isPreLoad = false;
            this.checkStatusBooking();
            this.$store.dispatch("manager/getBookingPayments", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.booking.reservation });
            if (this.booking.mandatory_signatures_contract) {
                this.$store.dispatch("manager/getHostes",  { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.booking.reservation });
            }
        },
        bookingGuests() {
            let adults = 0;
            let firms = 0;
            this.bookingGuests.forEach(guest => {
                guest._isAdult ? adults++ : '';
                guest._isAdult && guest._isSign ? firms++ : '';
            });

            firms >= adults ? this.controlAdultWithSign = true : '';
        }
    },
    data() {
        return {
            isPreLoad: false,
            routeList: sessionStorage.getItem('listado'),
            controlAdultWithSign: false,
            errorFormBooking: [],

            //modal
            error: [],
            newBooking: {
                numberNights: '',
                name: '',
                surname: '',
                email: '',
                phone: '',
                adults: '',
                children: '',
                babies: '',
                remarks: '',
                selectDay: '',
                estada: '',
                diposit: '',
                cleaningFee: '',
                booking_fee: '',
                checkin_fee: '',
                tourist_tax: '',
                earlybooking_discount: '',
                longstay_discount: '',
                lastminute_discount: '',
                standard_discount: '',
                promotion_discount: '',
                shortstay_fee: '',
                finance_fee: ''
            },
        };
    },
    async created() {
        this.isPreLoad = true; 
        try {
            this.translator 
                ? await this.$store.dispatch( "manager/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId })
                : await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
        
        } catch (error) {
            console.error(error);
        }
    },

    methods: {
        checkStatusBooking() {
            this.booking.customer_address == '' ||
            this.booking.customer_city == '' ||
            this.booking.customer_country == '' ||
            this.booking.customer_email == '' ||
            this.booking.customer_first_name == '' ||
            this.booking.customer_last_name == '' ||
            this.booking.customer_phone == '' ||
            this.booking.customer_tin == '' ||
            this.booking.customer_zip == '' 
            ? this.booking._registerDocument = false 
            : this.booking._registerDocument = true; 
        },

        validateFormBooking(validar) {
            this.checkStatusBooking()
            this.errorFormBooking = [];
            let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

            this.booking._registerDocument ? '' : this.errorFormBooking.push(this.translator.faltacompletardadesreserva);
            regEmail.test(this.booking.customer_email) ? '' : this.errorFormBooking.push(this.translator.faltaemail);
            this.booking.adults === '' || this.booking.adults == 0 ? this.errorFormBooking.push(this.translator.faltapersones) : '';

            if (validar === 'checkIn') {
                this.errorFormBooking.length === 0 ? this.checkIn() : ''
            } else {
                this.errorFormBooking.length === 0 ? this.getBookingSave() : ''
            }
        },

        async checkIn() {
            try {
                this.isPreLoad = true;
                await this.$store.dispatch( "manager/checkIn", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId, ip: '' });
                this.$toast.success(`${this.translator.checkin} ${this.translator.completat}`);
            } catch (error) {
                console.error( error );
            } finally {
                this.isPreLoad = false;
            }
        },

        async checkOut() {
            try {
                this.isPreLoad = true;
                await this.$store.dispatch( "manager/checkOut", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId, ip: '' });
                this.$toast.success(`${this.translator.checkout} ${this.translator.completat}`);
            } catch (error) {
                console.error( error );
            } finally {
                this.isPreLoad = false;
            }
        },
        routerLink() {
            this.$router.push({ name: this.routeList, query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') }})
        },

        modificarReserva() {
            this.newBooking.numberNights = this.booking.nights;
            this.newBooking.name = this.booking.customer_first_name;
            this.newBooking.surname = this.booking.customer_last_name;
            this.newBooking.email = this.booking.customer_email;
            this.newBooking.phone = this.booking.customer_phone;
            this.newBooking.phone = this.booking.customer_phone;
            this.newBooking.estada = this.bookingPayments.stay_amount; 
            this.newBooking.cleaningFee = this.bookingPayments.cleaning_fee;
            this.newBooking.adults = this.booking.adults;
            this.newBooking.children = this.booking.children;
            this.newBooking.babies = this.booking.babies;
            this.newBooking.remarks = this.booking.customer_remarks;
            this.newBooking.selectDay = this.booking.arrival_date;
            this.newBooking.diposit = this.bookingPayments.security_deposit;
            this.newBooking.booking_fee = this.bookingPayments.booking_fee;
            this.newBooking.checkin_fee = this.bookingPayments.checkin_fee;
            this.newBooking.tourist_tax = this.bookingPayments.tourist_tax;
            this.newBooking.earlybooking_discount = this.bookingPayments.earlybooking_discount;
            this.newBooking.longstay_discount = this.bookingPayments.longstay_discount;
            this.newBooking.lastminute_discount = this.bookingPayments.lastminute_discount;
            this.newBooking.standard_discount = this.bookingPayments.standard_discount;
            this.newBooking.promotion_discount = this.bookingPayments.promotion_discount;
            this.newBooking.shortstay_fee = this.bookingPayments.shortstay_fee;
            this.newBooking.finance_fee = this.bookingPayments.finance_fee;

            $('#modalCenter1').modal('show');
        },
        async saveBooking() {
            this.error = [];
            this.newBooking.name == '' ? this.error.push(this.translator.faltanom) : null;
            this.newBooking.selectDay == '' ? this.error.push(this.translator.faltadata) : null;
            this.newBooking.numberNights == '' ? this.error.push(this.translator.faltanumnits) : null;
            this.newBooking.estada == '' || this.newBooking.estada <= 0 ? this.error.push(this.translator.faltaimport) : null;

            if ( this.error.length == 0 ) {

                await this.$store.dispatch( "manager/postReservaActualitzar", {

                    vm: this,
                    trad: '-updated',

                    usr: String(this.$route.query.id).replace(/[ ]/g, '+'), //String
                    reserva: this.booking.reservation,
                    nom: this.newBooking.name, //String
                    cognom: this.newBooking.surname, //String
                    telefon: this.newBooking.phone, //String
                    email: this.newBooking.email, //String
                    adults: this.newBooking.adults === '' ? 0 : this.newBooking.adults, //Int
                    joves: this.newBooking.children === '' ? 0 : this.newBooking.children, //Int
                    bebes: this.newBooking.babies  === '' ? 0 : this.newBooking.babies, //Int
                    observacions: this.newBooking.remarks, //String
                    lodgingId: this.booking.lodging_id, //Int
                    entrada: this.newBooking.selectDay, //Date
                    nits: parseInt(this.newBooking.numberNights), //Int
                    estada: this.newBooking.estada == '' ? 0 : this.newBooking.estada, //Decimal
                    cleaning_fee: this.newBooking.cleaningFee == '' ? 0 : this.newBooking.cleaningFee, //Decimal
                    earlybooking_discount: this.newBooking.earlybooking_discount == '' ? 0 : this.newBooking.earlybooking_discount, //Decimal
                    longstay_discount: this.newBooking.longstay_discount == '' ? 0 : this.newBooking.longstay_discount, //Decimal
                    lastminute_discount: this.newBooking.lastminute_discount == '' ? 0 : this.newBooking.lastminute_discount, //Decimal
                    standard_discount: this.newBooking.standard_discount == '' ? 0 : this.newBooking.standard_discount, //Decimal
                    promotion_discount: this.newBooking.promotion_discount == '' ? 0 : this.newBooking.promotion_discount, //Decimal
                    booking_fee: this.newBooking.booking_fee == '' ? 0 : this.newBooking.booking_fee, //Decimal
                    checkin_fee: this.newBooking.checkin_fee == '' ? 0 : this.newBooking.checkin_fee, //Decimal
                    tourist_tax: this.newBooking.tourist_tax == '' ? 0 : this.newBooking.tourist_tax, //Decimal
                    shortstay_fee: this.newBooking.shortstay_fee == '' ? 0 : this.newBooking.shortstay_fee, //Decimal
                    finance_fee: this.newBooking.finance_fee == '' ? 0 : this.newBooking.finance_fee, //Decimal
                    diposit: this.newBooking.diposit == '' ? 0 : this.newBooking.diposit, //Decimal

                });
            
                this.newBooking.selectDay = this.newBooking.name = this.newBooking.surname = this.newBooking.email = this.newBooking.phone = this.newBooking.adults = this.newBooking.children = this.newBooking.babies = this.newBooking.remarks = this.newBooking.estada = this.newBooking.numberNights = '';
                $('#modalCenter1').modal('hide');
                
            }
        },
    },
    
    filters: {
    toLower(value) {
        if (!value) return "";
        value = value.toString();
        return value.toLowerCase();
    },
    transformDate(value) {
      if (!value) return '';
      const backendDate = value.split('-');
      return `${backendDate[2]}-${backendDate[1]}-${backendDate[0]}`;
    },
  },
}
</script>

<style lang="scss" scoped>
.container-nav-top {
  height: 50px;
  margin: 1rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a, h1 {
    color: var(--azul-oscuro);
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: lighten($color: #0c2c40, $amount: 15);
    }
  }
}
.manager-booking .titular-principal {
  cursor: pointer;
  &:hover {
    color: lighten($color: #0c2c40, $amount: 15);
    text-decoration: underline;
  }
}
.router-link {
    background-color: #FFF;
    text-decoration: none;
    color: var(--azul-oscuro);
    transition: box-shadow .25s;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    border-color: transparent;
    

    &:hover {
        transition: box-shadow .2s;
        box-shadow: 0 0.125rem 0.45rem rgba(0,0,0,.2) !important;
    }
}
.manager-booking, .manager-booking .pc {
    background: transparent;
    font-size: 0.9rem;
}


/* Chrome, Safari, Edge, Opera */
.hide-arrow input::-webkit-outer-spin-button,
.hide-arrow input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hide-arrow input[type=number] {
  -moz-appearance: textfield;
}

</style>